import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  Select,
  Form,
  Row,
  Col,
  InputNumber,
} from "antd";
import ParameterService from "../../../../domain/Parameter/ParameterService";
import { useParams, useNavigate } from "react-router-dom";
import { apiController } from "../../../../services/api";
import { getModelFiles } from "../../../../services/model";
import Icon, { CheckCircleOutlined } from "@ant-design/icons";
import InpService from "../../../../domain/ModelFile/InpService";
import SelectInpFile from "../../../../basicComponents/Selects/SelectInpFile";
import ParameterSourceService from "../../../../domain/ParameterSource/ParameterSourceService";
import InfraestructureContext from "../../../../views/Infrastructures/Parameters/context";
import SelectMetereological from "../../../../basicComponents/Selects/SelectMetereological";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const parameterService = new ParameterService();
const inpService = new InpService();
const parameterSourceService = new ParameterSourceService();

export interface ISourcesDetailProps {
  infrastructure: Infrastructure | null;
  onClose: Function;
}

export default function Forecast({
  infrastructure,
  onClose,
}: ISourcesDetailProps) {
  const security = localStorage.getItem("security");
  const organizationId = JSON.parse(security || "{}").organizationId;
  const { value: context, setValue: setContext } = useContext(
    InfraestructureContext
  );
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");
  const [form] = Form.useForm();

  const [inpId, setInpId] = useState<any>(
    context.selectedParam?.inpId != undefined &&
      context.selectedParam?.inpId > 0
      ? context.selectedParam?.inpId
      : null
  );
  const [inpObjectId, setInpObjectId] = useState<any>(
    context.selectedParam ? context.selectedParam?.inpObjectId : null
  );
  const [inpObjectType, setInpObjectType] = useState<any>(
    context.selectedParam?.inpObjectType != undefined &&
      context.selectedParam?.inpObjectType > 0
      ? context.selectedParam?.inpObjectType
      : null
  );
  const [modelInfraIdList, setModelInfraIdList] = useState<any[]>([]);
  const [showSuccesModal, setShowSuccessModal] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });
  const [modelFiles, setModelFiles] = useState<any>([]);

  const handleGetModelInfraId = async () => {
    if (form.getFieldValue("inpId") && form.getFieldValue("inpObjectType")) {
      const response = await inpService.getModelInfrastructureId({
        inpId: form.getFieldValue("inpId"),
        typeId: form.getFieldValue("inpObjectType"),
      });
      setModelInfraIdList(response.data);
    }
  };

  const navigate = useNavigate();

  const timerControl = () => {
    setShowSuccessModal(true);
    const timer = setTimeout(() => {
      setShowSuccessModal(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const uploadForecastData = async (values: any) => {
    const forecast = {
      inpId: parseInt(values.inpId),
      inpObjectId: values.inpObjectId,
      inpObjectType: parseInt(values.inpObjectType),
    };

    if (context.selectedParam?.id) {
      const resp = await parameterService.postParameterForecast(
        context.selectedParam?.id,
        forecast
      );

      if (resp) {
        onClose();
        timerControl();
      }
    }
  };

  const handleUpdateModelFiles = async () => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getModelFiles(organizationId, {
      page: pagination.page,
      size: pagination.pageSize,
      locationId: infrastructure?.locationId,
    })
      .then((response) => {
        setPagination({
          ...pagination,
          totalRows: response.totalRecords,
        });
        setModelFiles(response.getAllResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = async (values: any) => {
    await uploadForecastData(values);
  };

  const handleSaveSources = async (sourceListArray: any) => {
    if (context.selectedParam && infrastructure) {
      return await parameterSourceService.saveSourcesByInfrastructure(
        infrastructure,
        context.selectedParam,
        sourceListArray
      );
    }
  };

  useEffect(() => {
    if (context.selectedParam?.id) {
      form.setFieldsValue({
        inpId: context.selectedParam.inpId || null,
        inpObjectType: context.selectedParam.inpObjectType || null,
        inpObjectId: context.selectedParam.inpObjectId || null,
      });
      handleGetModelInfraId();
    }
  }, [context.selectedParam]);

  useEffect(() => {
    handleUpdateModelFiles();
    return () => {
      apiController.abort();
    };
  }, [pagination.page]);

  return infrastructure?.infraTypeId != 8 ? (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      size="small"
      onFinish={onFinish}
      onFinishFailed={() => {}}
    >
      <div className="flex column mt-2">
        <Form.Item
          label="Model File"
          name="inpId"
          rules={[
            { required: true, message: "Please select an option" },
            {
              validator: (_, value) =>
                value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please select an option")),
            },
          ]}
        >
          <SelectInpFile
            defaultValue={inpId}
            emitObject
            onSelect={(val: any) => {
              form.setFieldValue("inpObjectId", null);
              form.setFieldValue("inpId", val.inpId);
              if (
                form.getFieldValue("inpId") &&
                form.getFieldValue("inpObjectType")
              ) {
                handleGetModelInfraId();
              }
            }}
            organizationId={organizationId}
            onlyCompleted
          />
        </Form.Item>

        <Form.Item
          label="Object Type"
          name="inpObjectType"
          rules={[
            { required: true, message: "Please select a Object Type" },
            {
              validator: (_, value) =>
                value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please select an option")),
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            defaultValue={inpObjectType}
            placeholder="Select an option"
            onChange={() => {
              form.setFieldValue("inpObjectId", null);
              if (
                form.getFieldValue("inpId") &&
                form.getFieldValue("inpObjectType")
              ) {
                handleGetModelInfraId();
              }
            }}
            options={[
              { value: 1, label: "Nodes" },
              { value: 2, label: "Links" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Model Infrastructure ID"
          name="inpObjectId"
          rules={[
            {
              required: true,
              message: "Please enter a Model Infrastructure ID",
            },
            {
              validator: (_, value) =>
                value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please select an option")),
            },
          ]}
        >
          <Select
            defaultValue={inpObjectId}
            className="mt-1"
            placeholder="Select an option"
            options={modelInfraIdList?.map((modelid: any) => {
              return {
                value: modelid,
                label: modelid,
              };
            })}
          />
        </Form.Item>
        <div className="flex pt-3 justify-between">
          <Button
            style={{ width: "85px", height: "40px" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            htmlType="submit"
          >
            Save
          </Button>
        </div>
        <Modal
          open={showSuccesModal}
          footer={[]}
          closable={false}
          style={{ maxWidth: "180px" }}
        >
          <div className="flex column items-center">
            <span
              className="mt-2"
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: 15,
                lineHeight: "14px",
                display: "flex",
                color: "#34C38F",
              }}
            >
              <CheckCircleOutlined
                className="mr-1"
                style={{ fontSize: 25 }}
                rev={undefined}
              />
              All files have <br></br> been saved
            </span>
          </div>
        </Modal>
      </div>
    </Form>
  ) : context.selectedParam && context.selectedParam.sources.length > 0 ? (
    <div className="mt-2 flex column">
      <span className="mb-1">Metereological Service</span>
      <SelectMetereological
        mode="forecast"
        defaultValue={
          context.selectedParam && context.selectedParam.sources?.length > 0
            ? context.selectedParam?.sources[0].forecastMetService
            : null
        }
        onSelect={(value: number | null | undefined) => {
          const sourcesCopy = context.selectedParam?.sources
            ? [...context.selectedParam.sources]
            : [];
          sourcesCopy.forEach((source) => {
            source.forecastMetService = value;
          });
          handleSaveSources(sourcesCopy);
        }}
      />
    </div>
  ) : (
    <div className="mt-2">
      <span>To select the service you must create at least one source</span>
    </div>
  );
}
