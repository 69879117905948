import NrwRepository from "./NrwRepository";

export default class NrwService {
  private nrwRepository: NrwRepository = new NrwRepository();

  async getMROReport(locationId: any): Promise<any> {
    console.log('locationId',locationId)
    return this.nrwRepository.getNrwReport(locationId);
  }
}
