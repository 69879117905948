import React, { FC, useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { setRestartLoader } from "../../redux/loaderDuck"; 
import "./index.scss";

const ErrorView: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRestartLoader() as unknown as AnyAction);
  }, [dispatch]);

  return (
    <div className="error-container">
      <div className="error-content">
        <div className="error-text">
          <h1 className="error-title">Error</h1>
          <p className="error-message">Access Denied.</p>
          <p className="error-description">
            To protect the requested service, this request has been blocked or
            was unable to complete for other reasons.
          </p>
          <div className="error-details">
            <div className="details-row">
              <p className="details-label">Your IP Address:</p>
              <p className="details-value">2a01:cb00:f3d:a100:d599:397a:6fbb:e5ab</p>
            </div>
            <div className="details-row">
              <p className="details-label">Request ID:</p>
              <p className="details-value">6f0dfcf656bfedcf</p>
            </div>
          </div>
        </div>
        <div className="error-icon">
          <WarningOutlined
            style={{
              fontSize: "180px", 
              fontWeight: "bolder",
              color: "grey",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
