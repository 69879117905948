import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { Popup } from "react-map-gl";
import PopupComponent from "./Popup";
import "./styles.scss";
import { notification, Tooltip } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import InpService from "../../domain/ModelFile/InpService";
import HydraulicSimulatorService from "../../domain/HydraulicSimulator/HydraulicSimulatorService";
import TreeFiles from "./TreeFiles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mmsEventsService } from "./../../events/mms.subject";

const hydraulicSimulatorService = new HydraulicSimulatorService();
const inpService = new InpService();

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "50%",
};
// Viewport settings
const initialViewState = {
  longitude: -74,
  latitude: 40,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

// Función para validar coordenadas
const isValidCoordinate = (lng, lat) => {
  return (
    typeof lng === "number" &&
    typeof lat === "number" &&
    lng >= -180 &&
    lng <= 180 &&
    lat >= -90 &&
    lat <= 90
  );
};

const MmsView = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isViewMode, setIsViewMode] = useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const [viewState, setViewState] = useState(initialViewState);
  const [showLateralMenu, setShowLateralMenu] = useState(false);
  const [mmsId, setMmsId] = useState(null);

  const [nodes, setNodes] = useState([]);
  const [lines, setLines] = useState([]);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState(initialViewState);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [hoveredLine, setHoveredLine] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [fileName, setFileName] = useState(null);
  const refTreeData = useRef(null);
  const subscriptionMMS$ = mmsEventsService.getSubject();

  const getModelData = async (mmsId, filename) => {
    const response = await hydraulicSimulatorService.getAssetsFromMmsid(mmsId);

    await setAssetsData(response);
    await parseMapData(response);
    const midPoint = findMidpoint(response.assets);
    setViewport({
      ...viewState,
      longitude: midPoint[0],
      latitude: midPoint[1],
    });
    setMmsId(mmsId);
    setFileName(filename);
  };

  const parseMapData = (resData) => {
    const data = resData.assets;
    setNodes(() => {
      return data
        .filter((asset) => {
          return asset.assetType == "NODE";
        })
        .map((asset) => {
          return {
            ...asset,
            id: asset.assetId,
            longitude: asset.coordinates[0][0],
            latitude: asset.coordinates[0][1],
            label: asset.assetId,
          };
        });
    });
    setLines(() => {
      return data
        .filter((asset) => {
          return asset.assetType == "LINK";
        })
        .map((asset) => {
          return {
            ...asset,
            id: asset.assetId,
            sourceNodeId: asset.node1,
            targetNodeId: asset.node2,
            color: "#888",
            thickness: 2,
          };
        });
    });
  };

  function getNodeShape(node) {
    // Define styles for each type of icon
    const styles = {
      outfall: {
        width: "10px",
        height: "10px",
        backgroundColor: "#8B0000", // Dark red
        borderRadius: "50%",
        display: "inline-block",
      },
      rainGauges: {
        width: "18px",
        height: "18px",
        border: "10px solid #321a97", // Blue border
        borderRadius: "50%",
        backgroundColor: "transparent",
        display: "inline-block",
      },
      storage: {
        width: "10px",
        height: "10px",
        backgroundColor: "#321a97", // Blue
        borderRadius: "50%",
        display: "inline-block",
      },
    };
    switch (node.type.toUpperCase()) {
      case "OUTFALL":
        return <div style={styles.outfall}></div>;
      case "RAIN GAUGES":
        return <div style={styles.rainGauges}></div>;
      case "STORAGE":
      default:
        return <div style={styles.storage}></div>;
    }
  }

  function getLinkColorFromType(type, invisible, edited) {
    if (invisible) return "#f05775";
    if (edited) return "#79f865";
    switch (type.toUpperCase()) {
      case "PIPE":
        return "#1e96be";

      case "PUMP":
        return "#ff991f";

      case "ORIFICE":
        return "#720c9d";

      case "WEIR":
        return "#3d7a3e";

      default:
        return "#1e96be";
    }
  }

  function tooltipToshow(object) {
    const element = (
      <div style={{ backgroundColor: "#29323c" }}>
        <>
          <div>
            <span style={{ textAlign: "center" }}>Name: {object.assetId}</span>
            {object.type && <div>Type: {`${object.type}`}</div>}
          </div>
        </>
      </div>
    );
    return renderToString(element);
  }

  function findMidpoint(data) {
    // Variables para mantener la suma total de las coordenadas x e y
    let totalX = 0;
    let totalY = 0;
    let nodeCount = 0;

    // Iterar sobre cada elemento en los datos
    data.forEach((item) => {
      if (
        item.assetType === "NODE" &&
        item.coordinates &&
        item.coordinates.length > 0
      ) {
        // Sumar las coordenadas del nodo actual
        const [x, y] = item.coordinates[0]; // Tomar la primera coordenada
        totalX += x;
        totalY += y;
        nodeCount++;
      }
    });

    // Calcular el punto medio (promedio de coordenadas)
    if (nodeCount > 0) {
      const averageX = totalX / nodeCount;
      const averageY = totalY / nodeCount;
      return [averageX, averageY];
    } else {
      // En caso de no encontrar nodos válidos
      return null;
    }
  }
  async function saveChanges() {
    const dataToSend = assetsData.assets
      .filter((asset) => {
        return asset.edited || asset.invisible;
      })
      .map((asset) => {
        return {
          assetId: asset.assetId,
          action: asset.edited ? "EDIT" : asset.invisible ? "DELETE" : "",
          type: asset.type,
          coordinates: {
            x: asset.coordinates[0][1],
            y: asset.coordinates[0][0],
          },
          attributeDTOList: asset.attributes.map((at) => {
            return { name: at.name, value: at.value };
          }),
        };
      });
    const response = await hydraulicSimulatorService.postSaveChangesModelFile(
      mmsId,
      dataToSend
    );
    setAssetsData((prev) => {
      const newData = {
        ...prev,
        assets: prev.assets
          .map((asset) => {
            return { ...asset, edited: false };
          })
          .filter((asset) => !asset.invisible),
      };
      parseMapData(newData);

      return newData;
    });
    setSelectedAsset(null);
    if (response) {
      notification.success({
        message: "Successful",
        description: "Model File Edited successfully",
      });
      mmsEventsService.setSubject({ type: "handleGetTreeData" });
      // setMmsId(response);
      // setSearchParams({
      //   mmsId: response,
      // });
      // navigate(`/upload?selectedTab=MODEL`);
    }
  }

  async function saveModel() {
    // const dataToSend = { ...modelFile.data, mmsId };
    // const oui = JSON.parse(
    //   localStorage.getItem("security") || ""
    // ).organizationId;
    // const userId = localStorage.getItem("userId") || "";
    // const response = await inpService.uploadInps(userId, oui, dataToSend);
    // notification.success({
    //   message: "Successful",
    //   description: "Model File Saved successfully",
    // });
    navigate(`/upload/modelfiles?mmsId=${mmsId}&fileName=${fileName}`);
  }

  const onMarkerDragEnd = (event, nodeId) => {
    const { lngLat } = event;
    if (!isValidCoordinate(lngLat[0], lngLat[1])) {
      console.error(`Invalid coordinates: [${lngLat[0]}, ${lngLat[1]}]`);
      return;
    }

    setAssetsData((prev) => {
      let copy = { ...prev };
      const index = copy.assets.findIndex((asset) => {
        return asset.assetId == nodeId;
      });
      copy.assets[index].coordinates = [[lngLat[0], lngLat[1]]];
      copy.assets[index].edited = true;
      parseMapData(copy);
      // setPopupInfo(null);
      // setSelectedAsset(null);
      return copy;
    });

    // Actualizar la posición del nodo
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, longitude: lngLat[0], latitude: lngLat[1] }
          : node
      )
    );

    // Actualizar las líneas relacionadas con este nodo
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.sourceNodeId === nodeId) {
          const targetNode = nodes.find(
            (node) => node.id === line.targetNodeId
          );
          return {
            ...line,
            coordinates: [
              [lngLat[0], lngLat[1]],
              [targetNode.longitude, targetNode.latitude],
            ],
          };
        } else if (line.targetNodeId === nodeId) {
          const sourceNode = nodes.find(
            (node) => node.id === line.sourceNodeId
          );
          return {
            ...line,
            coordinates: [
              [sourceNode.longitude, sourceNode.latitude],
              [lngLat[0], lngLat[1]],
            ],
          };
        }
        return line;
      })
    );
  };

  //   useEffect(() => {
  //     if (!modelFile) {
  //       if (query.get("mmsId")) {
  //         setIsViewMode(true);
  //         getModelData(query.get("mmsId"));
  //       } else {
  //         navigate(-1);
  //         navigate("upload/modelfiles");
  //       }
  //     } else {
  //       getMmsId().then((mmsId) => {
  //         getModelData(mmsId);
  //       });
  //     }
  //   }, []);

  return (
    <div
      className="MapContainer"
      style={{
        backgroundColor: "#ebebeb",
      }}
    >
      <Map
        ref={mapRef}
        {...viewport}
        mapboxApiAccessToken={mapboxApiAccessToken}
        mapStyle={mapStyle}
        style={initialStyle}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        onClick={(e) => {
          const map = mapRef.current.getMap(); // Obtener la instancia del mapa
          const features = map.queryRenderedFeatures(e.point, {
            layers: ["lines"], // Especifica la capa de las líneas
          });
          if (features.length > 0) {
            const clickedFeature = features[0];
            setSelectedAsset(
              lines.find((line) => {
                return line.id == clickedFeature.properties.id;
              })
            );
            setPopupInfo({
              longitude: e.lngLat[0],
              latitude: e.lngLat[1],
              info: clickedFeature.properties,
            });
          } else {
            setPopupInfo(null);
            setSelectedAsset(null);
          }
        }}
        onMouseMove={(e) => {
          const map = mapRef.current.getMap(); // Obtener la instancia del mapa
          const features = map.queryRenderedFeatures(e.point, {
            layers: ["lines"], // Especifica la capa interactiva
          });
        
          if (features.length > 0) {
            const hoveredFeature = features[0];
            const hoveredLineData = lines.find((line) => line.id === hoveredFeature.properties.id);
        
            setHoveredLine({ ...hoveredFeature, coordinates: e.lngLat, assetType: hoveredLineData?.assetType , type: hoveredLineData?.type  });
          } else {
            setHoveredLine(null);
          }
        }}
      >
        {nodes.map((node) => {
          if (!isValidCoordinate(node.longitude, node.latitude)) {
            console.warn(
              `Invalid coordinates for node ${node.id}: [${node.longitude}, ${node.latitude}]`
            );
            return null;
          }
          if (!node) {
            return null;
          }

          return (
            <div
              onMouseEnter={() => setActiveTooltip(node.id)}
              onMouseLeave={() => {
                setActiveTooltip(null);
              }}
              onClick={() => {
                setSelectedAsset(node);
                setPopupInfo({
                  info: node,
                  longitude: node.longitude,
                  latitude: node.latitude,
                });
              }}
            >
              <Marker
                key={node.id}
                longitude={node.longitude}
                latitude={node.latitude}
                draggable
                onDragEnd={(event) => onMarkerDragEnd(event, node.id)}
                anchor="center"
                offsetLeft={-5} // Ajustar el offset horizontal
                offsetTop={-8} // Ajustar el offset vertical
              >
                {getNodeShape(node)}
                {activeTooltip == node.id && (
                  <div
                    style={{
                      backgroundColor: "#5700ba",
                      borderRadius: "10px",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    <span>Name: {node.label}</span>
                    <br />
                    <span>Type: {node.type}</span>
                  </div>
                )}
              </Marker>
            </div>
          );
        })}

        <Source
          id="edges"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: lines.map((line) => {
              const sourceNode = nodes.find(
                (node) => node.id === line.sourceNodeId
              );
              const targetNode = nodes.find(
                (node) => node.id === line.targetNodeId
              );

              if (sourceNode && targetNode) {
                return {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: [
                      [sourceNode.longitude, sourceNode.latitude],
                      [targetNode.longitude, targetNode.latitude],
                    ],
                  },
                  properties: {
                    color: getLinkColorFromType(
                      line.type,
                      line.invisible,
                      line.edited
                    ),
                    thickness: 4,
                    id: line.id,
                  },
                  state: {
                    asset: line,
                  },
                };
              }
            }),
          }}
        >
          <Layer
            id="lines"
            type="line"
            paint={{
              "line-color": ["get", "color"],
              "line-width": ["get", "thickness"],
            }}
          />
        </Source>
        {hoveredLine && (
          <Popup
            longitude={hoveredLine.coordinates[0]}
            latitude={hoveredLine.coordinates[1]}
            closeButton={false}
            closeOnClick={false}
            anchor="top"
          >
            <div
              style={{
                maxWidth: "200px",
                backgroundColor: "#5700ba",
                borderRadius: "10px",
                color: "white",
                padding: "10px",
              }}
            >
              <span>Name: {hoveredLine.properties.id}</span>
              <br />
              <span>Type: {hoveredLine.type}</span>
            </div>
          </Popup>
        )}
        {popupInfo && selectedAsset && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeButton={true}
            closeOnClick={false}
            onClose={() => {
              setPopupInfo(null);
              setSelectedAsset(null);
            }}
            anchor="top"
          >
            <PopupComponent
              mmsId={assetsData.mmsId}
              object={selectedAsset}
              setAttributesValue={(response) => {
                setAssetsData((prev) => {
                  let copy = { ...prev };
                  const index = copy.assets.findIndex((asset) => {
                    return asset.assetId == selectedAsset.assetId;
                  });
                  copy.assets[index].attributes = response;
                  // copy.assets[index].edited = false;
                  copy.assets[index].invisible = false;
                  setSelectedAsset(copy.assets[index]);
                  parseMapData(copy);
                  return copy;
                });
              }}
              onEdit={(el) => {
                setShowLateralMenu(true);
              }}
              onDelete={() => {
                setAssetsData((prev) => {
                  let copy = { ...prev };
                  const index = copy.assets.findIndex((asset) => {
                    return asset.assetId == selectedAsset.assetId;
                  });
                  copy.assets[index].invisible = true;
                  parseMapData(copy);
                  setPopupInfo(null);
                  setSelectedAsset(null);
                  return copy;
                });
              }}
              onRestore={() => {
                setAssetsData((prev) => {
                  let copy = { ...prev };
                  const index = copy.assets.findIndex((asset) => {
                    return asset.assetId == selectedAsset.assetId;
                  });
                  copy.assets[index].invisible = false;
                  parseMapData(copy);
                  setPopupInfo(null);
                  setSelectedAsset(null);
                  return copy;
                });
              }}
              onUpdateAttributeValue={(key, newValue) => {
                setAssetsData((prev) => {
                  let copy = { ...prev };
                  const index = copy.assets.findIndex((asset) => {
                    return asset.assetId == selectedAsset.assetId;
                  });
                  const attributeIndex = copy.assets[
                    index
                  ].attributes.findIndex((attr) => {
                    return attr.name == key;
                  });
                  copy.assets[index].attributes[attributeIndex].value =
                    newValue;
                  copy.assets[index].edited = true;
                  setSelectedAsset(copy.assets[index]);
                  parseMapData(copy);
                  return copy;
                });
              }}
            />
          </Popup>
        )}
      </Map>
      {!isViewMode && (
        <>
          <Tooltip title="Save Changes">
            <div
              className="text-center"
              style={{
                borderRadius: "100%",
                borderColor: "white",
                border: "solid 3px white",
                backgroundColor: "#29323c",
                height: 30,
                width: 30,
                padding: 1,
                cursor: "pointer",
                position: "absolute",
                top: 80,
                right: 25,
              }}
              onClick={() => {
                saveChanges();
              }}
            >
              <SaveOutlined style={{ cursor: "pointer", color: "white" }} />
            </div>
          </Tooltip>
          <Tooltip title="Upload model">
            <div
              className="text-center"
              style={{
                borderRadius: "100%",
                borderColor: "white",
                border: "solid 3px white",
                backgroundColor: "#29323c",
                height: 30,
                width: 30,
                padding: 1,
                cursor: "pointer",
                position: "absolute",
                top: 120,
                right: 25,
              }}
              onClick={() => {
                // saveChanges();
                saveModel();
              }}
            >
              <UploadOutlined style={{ cursor: "pointer", color: "white" }} />
            </div>
          </Tooltip>
        </>
      )}
      <TreeFiles ref={refTreeData} getModelData={getModelData} />
    </div>
  );
};

export default MmsView;
