let SHOW_LOADING = "SHOW_LOADING";
let SHOW_EXPIRED_SESSION = "SHOW_EXPIRED_SESSION";

const initialState = {
  options: {
    show: false,
    title: null,
    message: null,
    type: "success",
  },
  showExpiredSessionModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING:
      return { ...state, options: action.payload };
    case SHOW_EXPIRED_SESSION:
      return { ...state, showExpiredSessionModal: action.payload };
    default:
      return state;
  }
}

export const showSucessModal =
  (title = null, message = null) =>
  (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
      payload: { show: true, type: "success", title, message },
    });
  };

export const showErrorModal =
  (title = null, message = null) =>
  (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
      payload: { show: true, type: "error", title, message },
    });
  };

export const showWarningModal =
  (title = null, message = null) =>
  (dispatch) => {
    console.log("Show warning2");
    dispatch({
      type: SHOW_LOADING,
      payload: { show: true, type: "warning", title, message },
    });
  };

export const showExpiredSessionModal = () => (dispatch) => {
  dispatch({
    type: SHOW_EXPIRED_SESSION,
    payload: true,
  });
};

export const hideModal = () => (dispatch, getState) => {
  dispatch({
    type: SHOW_LOADING,
    payload: { ...getState().modalAlert.options, show: false },
  });
  setTimeout(() => {
    dispatch({
      type: SHOW_LOADING,
      payload: initialState,
    });
  }, 1000);
};
