import { Dropdown, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import ListParametersTree from "../../Parameters/Infrastructure/listParametersTree";
import DiagramBlockContext from "../../../views/DiagramBlock/context";
import { getResumeFromDevice } from "../../../services/measurement";
import "./ParameterNode.scss";
import WaveSvg from "../../../assets/svg/WaveSvg";
import Icon, { PlusOutlined } from "@ant-design/icons";
import ThreeDotsSVG from "../../../assets/svg/ThreeDotsSVG";
import type { MenuProps } from "antd";
import HistorySvg from "../../../assets/svg/HistorySvg";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const ParameterNode: React.FC = ({ data, id }: any) => {
  const { value: context, setValue: setContext } =
    useContext(DiagramBlockContext);
  const [showModal, setShowModal] = useState(false);
  const [resumeInfo, setResumeInfo] = useState<any>(null);
  const globalDate = localStorage.getItem("globalDate");
  const [dateFrom, dataTo] = globalDate ? globalDate.split("***") : [];

  const items: MenuProps["items"] = [
    {
      label: (
        <div
          className="flex"
          onClick={() => {
            data.addNode(id);
          }}
        >
          <PlusOutlined className="mr-2" rev={undefined} />
          <span>Create new node</span>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <span
          style={{ color: "#F46A6A", cursor: "pointer" }}
          onClick={() => {
            data.removeNode(id);
          }}
        >
          Delete
        </span>
      ),
      key: "1",
    },
  ];

  async function handleGetReading() {
    const resume = await getResumeFromDevice(
      data.value.infrastructureId,
      data.value.paremeterId,
      null,
      dateFrom,
      dataTo
    );
    setResumeInfo(resume);
  }

  const ProgressBar = ({
    criticalLlow,
    criticalLow,
    criticalHigh,
    criticalHhigh,
    currentValue,
  }: any) => {
    const min = criticalLlow;
    const max = criticalHhigh;
    const range = max - min;

    // Calcula el porcentaje de la barra basado en currentValue
    const currentPercent = ((currentValue - min) / range) * 100;

    const currentValueStyle = {
      width: `${currentPercent > 0 ? currentPercent : 0}%`,
      height: "100%",
      background: "linear-gradient(to right, lightblue, #000095)",
    };

    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "20px",
            border: "solid",
            position: "relative",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <div style={currentValueStyle}></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <span>{criticalLlow}</span>
          <span>{criticalLow}</span>
          <span>{criticalHigh}</span>
          <span>{criticalHhigh}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (data.value.infrastructureId && data.value.serialNumberSource) {
      handleGetReading();
    }
  }, [data.value]);

  return (
    <>
      <div className="parameter-node py-2">
        {resumeInfo ? (
          <>
            <div className="flex items-center content-container justify-center">
              <Icon
                component={WaveSvg}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                className="mr-2"
              />
              <span
                className="mr-2"
                style={{
                  fontSize: "12px",
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {resumeInfo.infrastructureName}
              </span>
              {data.editable && (
                <Dropdown menu={{ items }}>
                  <Icon
                    component={ThreeDotsSVG}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    rev={undefined}
                  />
                </Dropdown>
              )}
            </div>
            <div
              className="flex column"
              style={{ padding: "10px", height: "220px" }}
            >
              <span style={{ color: "#888888", alignSelf: "center" }}>
                {resumeInfo.infrastructureType}
              </span>
              <div className="separator my-2" style={{ height: "1px" }} />
              <span style={{ fontSize: "20px" }}>
                {resumeInfo.parameterName}
              </span>
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {resumeInfo.currentValue} {resumeInfo.unit}
              </span>
              {ProgressBar(resumeInfo)}
              {/* {ProgressBar({
              criticalLlow: 5,
              criticalLow: 20,
              criticalHigh: 100,
              criticalHhigh: 150,
              currentValue: 56,
            })} */}
              <div className="flex items-center">
                <Icon component={HistorySvg} className="mr-2" rev={undefined} />
                <span style={{ fontSize: "10px" }}>
                  Updated {`${dayjs(resumeInfo.lastCollected).fromNow()}`}
                </span>
              </div>
              <div className="pl-4">
                <span style={{ fontSize: "10px" }}>
                  {resumeInfo.originSourceName}{" "}
                  {!!resumeInfo.virtual && <span>(VIRTUAL)</span>}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center content-container justify-center">
              <Icon
                component={WaveSvg}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                className="mr-2"
              />
              <span
                className="mr-2"
                style={{
                  fontSize: "12px",
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.label}
              </span>
              {data.editable && (
                <Dropdown menu={{ items }}>
                  <Icon
                    component={ThreeDotsSVG}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    rev={undefined}
                  />
                </Dropdown>
              )}
            </div>
            <div
              className="flex column justify-center"
              style={{ padding: "10px", height: "220px" }}
            >
              <span
                style={{
                  fontSize: "20px",
                  maxWidth: "210px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                {data.value.paremeterId
                  ? "Loading data..."
                  : "You need to link the Node to a parameter"}
              </span>
            </div>
          </>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
      <Modal
        title="Select a parameter"
        open={showModal}
        footer={[]}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <ListParametersTree
          locationId={context.locationId}
          onSelect={(val: string) => {
            data.onChange(JSON.parse(val), id);
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ParameterNode;
