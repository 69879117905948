import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlockService from "../../domain/BlockDiagram/BlockService";
import {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  Handle,
  Node,
  Position,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { Button, notification } from "antd";
import ParameterNode from "../../components/DiagramBlock/Nodes/ParameterNode";
import "reactflow/dist/style.css";
import DiagramBlockContext from "./context";
import { Sidebar } from "../../components/DiagramBlock/Sidebar";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let id = 0;
const getId = () => `${id++}`;

const security = JSON.parse(localStorage.getItem("security") || "{}");
const isAdmin = security.role === "Administrator";

const blockService = new BlockService();

const initialContext = {
  locationId: "",
  infrastructures: [],
};

const nodeTypes = { parameter: ParameterNode };

const DiagramBlock: React.FC = () => {
  const query = useQuery();
  const isEdit = query.get("isEdit");
  const aditionalProperties = useMemo(() => {
    return {
      ...(isEdit
        ? {}
        : {
            draggable: false,
            nodesConnectable: false,
            nodesDraggable: false,
            nodesFocusable: false,
            edgesFocusable: false,
          }),
    };
  }, [isEdit]);

  const [contextValue, setContextValue] = useState<any>(initialContext);
  const navigate = useNavigate();
  const { locationId, blockId } = useParams();
  const reactFlowWrapper = useRef<any>(null);
  const [nodes, setNodes] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [nodeSelect, setNodeSelect] = useState<any>(null);

  const onNodesChange = useCallback(
    (changes: any) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  function onChange(event: any, id: any) {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id !== id) {
          return node;
        }

        const label = event.target.value;

        return {
          ...node,
          data: {
            ...node.data,
            label,
          },
        };
      })
    );
  }

  function onChangeParamNode(data: any, id: any) {
    console.log("🚀 ~ file: index.tsx:189 ~ onChangeParamNode ~ event:", data);
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id !== id) {
          return node;
        }

        const label = data.paremeterName;
        const value = data;

        return {
          ...node,
          data: {
            ...node.data,
            label,
            value,
          },
        };
      })
    );
  }

  function onAddNode(id: string) {
    const newID = getId();
    setNodes((prev) => {
      const curentNode = prev.find((node) => {
        return node.id == id;
      });

      const newNode = {
        id: newID,
        type: "parameter",
        position: {
          x: (curentNode?.position.x || 0) + 250,
          y: curentNode?.position.y,
        },
        data: {
          label: `Parameter node`,
          value: {},
          onChange: onChangeParamNode,
          removeNode: removeNode,
          addNode: addNode,
        },
        style: {
          background: getColor("parameter"),
        },
      } as Node;
      return [...prev, newNode];
    });
    const newEdge = {
      id: `reactflow__edge-${id}-${newID}`,
      source: id,
      target: newID,
      type: "smoothstep",
    };
    console.log("🚀 ~ file: index.tsx:179 ~ addNode ~ newEdge:", newEdge);
    setEdges((prev) => {
      return [...prev, newEdge];
    });
  }

  const onConnect = useCallback(
    (params: Edge | Connection) =>
      setEdges((eds) => addEdge({ ...params, type: "smoothstep" }, eds)),
    []
  );

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const removeNode = (id: string) => {
    setNodes((prev) => {
      return prev.filter((e) => {
        return e.id != id;
      });
    });
  };

  const addNode = (id: string) => {
    onAddNode(id);
  };

  const getColor = (type: any) => {
    switch (type) {
      case "default": {
        return "green";
      }
      case "input": {
        return "blue";
      }
      case "output": {
        return "red";
      }
      default: {
        return "gray";
      }
    }
  };

  const onDrop = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { getData: (arg0: string) => any };
      clientX: number;
      clientY: number;
    }) => {
      event.preventDefault();

      const reactFlowBounds =
        reactFlowWrapper?.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance?.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      let color = "";
      color = getColor(type);
      let newNode: Node;
      switch (type) {
        case "parameter":
          newNode = {
            id: getId(),
            type,
            position,
            data: {
              label: `${type} node`,
              value: {},
              editable: isEdit,
              onChange: onChangeParamNode,
              removeNode: removeNode,
              addNode: addNode,
            },
            style: {
              background: color,
            },
          } as Node;
          break;

        default:
          newNode = {
            id: getId(),
            type,
            position,
            data: { label: `${type} node`, value: {}, onChange: onChange },
            style: {
              background: color,
            },
          } as Node;
          break;
      }
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const getBlockDiagram = async () => {
    if (!blockId) {
      navigate(`/`);
      return;
    }
    try {
      setNodes([]);
      setEdges([]);
      const res = await blockService.getBlockDiagram(parseInt(blockId));
      setNodes(
        res.nodes.map((node) => {
          const color = getColor(node.type);
          let newNode: Node;
          switch (node.type) {
            case "parameter":
              newNode = {
                id: `${node.id}`,
                type: node.type,
                position: node.position,
                data: {
                  ...node.data,
                  editable: isEdit,
                  onChange: onChangeParamNode,
                  removeNode: removeNode,
                  addNode: addNode,
                },
                style: {
                  background: color,
                },
              } as Node;
              break;

            default:
              newNode = {
                id: node.id,
                type: node.type,
                position: node.position,
                data: { ...node.data, onChange: onChange },
                style: {
                  background: color,
                },
              } as Node;
              break;
          }
          console.log("🚀 ~ res.nodes.map ~ newNode:", newNode);
          return newNode as Node;
        })
      );
      setEdges(
        res.edges.map((edge) => {
          return {
            id: `${edge.id}`,
            source: edge.source,
            target: edge.target,
            type: "smoothstep",
            ...(edge.sourceHandle ? { sourceHandle: edge.sourceHandle } : {}),
            ...(edge.targetHandle ? { targetHandle: edge.targetHandle } : {}),
          };
        })
      );
      setTimeout(() => {
        if(reactFlowInstance) reactFlowInstance.fitView();
      }, 200);
      id =
        res.nodes.length > 0
          ? parseInt(res.nodes[res.nodes.length - 1].id) + 1
          : 1;

    } catch (error) {
      console.error(error);
      navigate(`/`);
    }
  };

  const saveBlockDiagram = async () => {
    const security = localStorage.getItem("security");
    if (!security) return;
    const organizationId = JSON.parse(security).organizationId;
    const block = {
      id: parseInt(blockId || "0"),
      name: "Test",
      nodes: nodes.map((node) => {
        return {
          id: node.id,
          type: node.type,
          position: { x: node.position.x, y: node.position.y },
          data: node.data,
        } as BlockNode;
      }),
      edges: edges.map((edge) => {
        return {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          ...(edge.sourceHandle ? { sourceHandle: edge.sourceHandle } : {}),
          ...(edge.targetHandle ? { targetHandle: edge.targetHandle } : {}),
        } as BlockEdge;
      }),
    } as BlockDiagram;
    const response = await blockService.saveBlockDiagram(
      organizationId,
      locationId,
      block
    );
    if (response) {
      notification.success({
        message: "Changes saved",
        description: "Block diagram edited successfully",
      });
    }
  };

  useEffect(() => {
    if (!locationId || !blockId) return;
    setContextValue({ ...contextValue, locationId: locationId });
    getBlockDiagram();
    if(reactFlowInstance) reactFlowInstance.fitView();
  }, [blockId, locationId]);

  useEffect(() => {
    // console.log("🚀 ~ file: index.tsx:377 ~ nodes:", nodes);
  }, [nodes]);

  return (
    <DiagramBlockContext.Provider
      value={{
        value: contextValue,
        setValue: setContextValue,
      }}
    >
      <div className="containerDiagramBlock">
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={(edgeChanges) => {
                  if (edgeChanges[0].type === "select") {
                    const node: any = edgeChanges.find(
                      (ec: any) => ec.selected
                    );
                    if (node) {
                      const ns = nodes.find((n: any) => n.id === node.id);
                      setNodeSelect(ns);
                      setIsEditMode(true);
                      //showEditMode
                    } else {
                      setNodeSelect(null);
                      setIsEditMode(false);
                    }
                  }
                  onNodesChange(edgeChanges);
                }}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                fitView
                {...aditionalProperties}
              >
                {/* <Controls /> */}
                <Background
                  color="#81818a"
                  gap={16}
                  variant={BackgroundVariant.Dots}
                />
              </ReactFlow>
            </div>
            {isEdit && (
              <Sidebar
                onSave={saveBlockDiagram}
                isEditMode={isEditMode}
                nodeSelect={nodeSelect}
                locationId={locationId}
                onChangeParamNode={onChangeParamNode}
                closeEditMode={() => {
                  setIsEditMode(false);
                }}
              />
            )}
          </ReactFlowProvider>
        </div>
      </div>
    </DiagramBlockContext.Provider>
  );
};

export default DiagramBlock;
