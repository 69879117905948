import { Button, Input, Modal, Select, InputNumber } from "antd";
import React, { useContext, useEffect, useState } from "react";
import StateMachineContext from "../context";
import AceEditor from "react-ace";
import Icon, { PlusCircleFilled, MinusCircleOutlined } from "@ant-design/icons";
interface propertyTypes {
  selectedNode: any;
  onCancel: Function;
}

export default function ActionModal({
  selectedNode,
  onCancel,
}: propertyTypes): React.JSX.Element {
  const { value: context, setValue: setContext } =
    useContext(StateMachineContext);
  const [action, setAction] = useState<any>({
    name: null,
    type: null,
    input: null,
    output: null,
    constant: null,
    value: null,
    statement: null,
    tableRows: [],
  });
  const onDeleteRow = (index: number) => {
    const updatedRows = action.tableRows.filter((_: any, rowIndex: number) => rowIndex !== index);
    setAction({ ...action, tableRows: updatedRows });
  };
  const [newRowInput, setNewRowInput] = useState<number | null>(null);
  const [newRowOutput, setNewRowOutput] = useState<number | null>(null);

  const filterOptions = (input: any, option: any) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  useEffect(() => {
    console.log("Context:", context); 
    setAction({
      ...selectedNode.data.value,
      tableRows: selectedNode.data.value?.tableRows || [],
    });
  }, [selectedNode, context]);
  return (
    <Modal
      title="Link Action"
      open={true}
      footer={[]}
      onCancel={() => {
        onCancel();
      }}
    >
      <div className="flex column">
        <Input
          type="text"
          placeholder="Action name"
          onChange={(event) => {
            setAction({ ...action, name: event.target.value });
          }}
          className="mb-1"
          value={action.name}
        />
        <Select
          style={{ width: "100%" }}
          placeholder="Select an option"
          onChange={(val) => {
            setAction({ ...action, type: val });
          }}
          options={[
            { value: "FUNCTION", label: "Function" },
            { value: "CONSTANT", label: "Assignment" },
            { value: "PID", label: "PID Controller" },
            { value: "TABLE", label: "Table" }
          ]}
          value={action.type}
          className="mb-1"
        />
        {action.type == "FUNCTION" && (
          <>
            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Input</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, input: val });
                }}
                onSearch={() => { }}
                filterOption={filterOptions}
                options={context.inputsList.map((input: any) => {
                  return {
                    label: input.label,
                    value: input.label,
                  };
                })}
                value={action.input}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Output</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, output: val });
                }}
                onSearch={() => { }}
                filterOption={filterOptions}
                options={context.outputsList.map((output: any) => {
                  return {
                    label: output.label,
                    value: output.label,
                  };
                })}
                value={action.output}
              />
            </div>
            <div className="flex  mb-1">
              <AceEditor
                className="mt-1"
                mode="python"
                theme="github"
                name="editor"
                onChange={(val) => {
                  setAction({ ...action, statement: val });
                }}
                value={action.statement}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                // value={virtualSource.code}
                setOptions={{
                  useWorker: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                placeholder="Type your code here"
                style={{ maxHeight: "120px" }}
              />
            </div>
          </>
        )}
        {action.type == "CONSTANT" && (
          <>
            <div className="flex mb-1">
              <Select
                value={action.constant}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, constant: val });
                  if (action.value && val) {
                    setAction({
                      ...action,
                      statement: `${val}=${action.value}`,
                    });
                  }
                }}
                onSearch={() => { }}
                filterOption={filterOptions}
                options={context.outputsList.map((output) => {
                  return {
                    label: output.label,
                    value: output.label,
                  };
                })}
              />
              <span className="mx-2">=</span>
              <Input
                value={action.value}
                placeholder="Value"
                onChange={(event) => {
                  setAction({ ...action, value: event.target.value });
                  if (action.constant && event.target.value) {
                    setAction({
                      ...action,
                      statement: `${action.constant}=${event.target.value}`,
                      value: event.target.value,
                    });
                  }
                }}
              />
            </div>
          </>
        )}
        {action.type == "PID" && (
          <>
            <div className="flex mb-1">
              <span style={{ width: "12%" }}>Input</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, input: val });
                }}
                filterOption={filterOptions}
                options={context.inputsList.map((input) => ({
                  label: input.label,
                  value: input.label,
                }))}
                value={action.input}
              />
            </div>

            <div className="flex mb-1">
              <span style={{ width: "12%" }}>Setpoint</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, setpoint: val });
                }}
                filterOption={filterOptions}
                options={context.constantsList.map((input) => ({
                  label: input.label,
                  value: input.label,
                }))}
                value={action.setpoint}
              />
            </div>

            <div className="flex mb-1">
              <span style={{ width: "12%" }}>Output</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, output: val });
                }}
                filterOption={filterOptions}
                options={context.outputsList.map((output) => ({
                  label: output.label,
                  value: output.label,
                }))}
                value={action.output}
              />
            </div>

            <div className="flex mb-1">
              <span style={{ width: "50%" }}>Kp(Proportional Const.) </span>
              <InputNumber
                style={{ width: "80%" }}
                value={action.kp}
                placeholder="Kp"
                onChange={(val) => setAction({ ...action, kp: val })}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "50%" }}>Ki(Integral Const.)</span>
              <InputNumber
                style={{ width: "80%" }}
                value={action.ki}
                placeholder="Ki"
                onChange={(val) => setAction({ ...action, ki: val })}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "50%" }}>Kd (Derivate Const)</span>
              <InputNumber
                style={{ width: "80%" }}
                value={action.kd}
                placeholder="Kd"
                onChange={(val) => setAction({ ...action, kd: val })}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "50%" }}>Min Output Value</span>
              <InputNumber
                style={{ width: "80%" }}
                value={action.min}
                placeholder="Min"
                onChange={(val) => setAction({ ...action, min: val })}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "50%" }}>Max Output Value</span>
              <InputNumber
                style={{ width: "80%" }}
                value={action.max}
                placeholder="Max"
                onChange={(val) => setAction({ ...action, max: val })}
              />
            </div>
          </>
        )}
        {action.type === "TABLE" && (
          <>
            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Input</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => setAction({ ...action, input: val })}
                filterOption={filterOptions}
                options={context.inputsList.map((input) => ({
                  label: input.label,
                  value: input.label,
                }))}
                value={action.input}
              />
            </div>

            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Output</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => setAction({ ...action, output: val })}
                filterOption={filterOptions}
                options={context.outputsList.map((output) => ({
                  label: output.label,
                  value: output.label,
                }))}
                value={action.output}
              />
            </div>

            <div className="mt-2">


              <div className="flex mb-1">
                <div style={{ width: "45%", marginRight: "2px" }}>
                  <span style={{ width: "60%" }}>Input</span>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Input"
                    value={newRowInput}
                    onChange={setNewRowInput}
                  />
                </div>
                <div style={{ width: "45%" }}>
                  <span style={{ width: "60%" }}>Output</span>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Output"
                    value={newRowOutput}
                    onChange={setNewRowOutput}
                  />
                </div>
                <div style={{ width: "10%" }}>
                  <Button
                    style={{ width: "10%", border: "none" }}
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                      if (newRowInput !== null && newRowOutput !== null) {
                        const updatedRows = [
                          ...action.tableRows,
                          { input: newRowInput, output: newRowOutput },
                        ];
                        setAction({ ...action, tableRows: updatedRows });
                        setNewRowInput(null);
                        setNewRowOutput(null);
                      }
                    }}
                    className=" mt-3 ml-2"
                  />
                </div>
              </div>
              {action.tableRows?.map((row: any, index: any) => (
                <div key={index} className="flex ">
                  <div style={{ width: "45%", marginRight: "2px" }}>
                    <InputNumber
                     style={{ width: "100%" }}
                      value={row.input}
                      readOnly
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <InputNumber
                     style={{ width: "100%" }}
                      value={row.output}
                      readOnly
                    />
                  </div>
                  <div style={{ width: "10%" }}>
                    <Button className=" ml-2"
                      style={{  width: "10%", border: "none", color: "red" }}
                      icon={<MinusCircleOutlined />}
                      onClick={() => onDeleteRow(index)}
                    />
                  </div>
                  
                </div>
              ))}
            </div>
          </>
        )}



        <div className="flex justify-center">
          <Button
            style={{
              backgroundColor: "#00203C",
              color: "white",
              height: "40px",
            }}
            onClick={() => {
              selectedNode.data.onChange(action, selectedNode.id);
              onCancel();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
