import { connect } from "react-redux";

import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import DeckGL, { IconLayer, FlyToInterpolator } from "deck.gl";
import { PathLayer, BitmapLayer } from "@deck.gl/layers";
import { _MapContext as MapContext, StaticMap, Popup } from "react-map-gl";
import PopupComponent from "./Popup";
import "./styles.scss";
import LateralMenu from "./LateralMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { notification, Tooltip } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import HydraulicSimulatorService from "../../../../domain/HydraulicSimulator/HydraulicSimulatorService";
import InpService from "../../../../domain/ModelFile/InpService";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const hydraulicSimulatorService = new HydraulicSimulatorService();
const inpService = new InpService();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "50%",
};
// Viewport settings
const initialViewState = {
  longitude: -74,
  latitude: 40,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

const initialNodes = [
  { id: "1", longitude: -74.5, latitude: 40, label: "Node 1" },
  { id: "2", longitude: -74.3, latitude: 40.1, label: "Node 2" },
  { id: "3", longitude: -74.8, latitude: 40.4, label: "Node 3" },
];

const initialLines = [
  {
    id: "1",
    sourceNodeId: "1",
    targetNodeId: "3",
    color: "#888",
    thickness: 2,
  },
  {
    id: "2",
    sourceNodeId: "2",
    targetNodeId: "3",
    color: "#888",
    thickness: 2,
  },
];

// Función para validar coordenadas
const isValidCoordinate = (lng, lat) => {
  return (
    typeof lng === "number" &&
    typeof lat === "number" &&
    lng >= -180 &&
    lng <= 180 &&
    lat >= -90 &&
    lat <= 90
  );
};

const EditModelFileView = ({ modelFile }) => {
  const deckRef = useRef(null);
  const navigate = useNavigate();
  const query = useQuery();
  const [isViewMode, setIsViewMode] = useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const [linksLayer, setLinksLayer] = useState([]);
  const [pointsLayer, setPointsLayer] = useState([]);
  const [viewState, setViewState] = useState(initialViewState);
  const [showPopup, setShowPopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showLateralMenu, setShowLateralMenu] = useState(false);
  const [mmsId, setMmsId] = useState(null);

  const [nodes, setNodes] = useState(initialNodes);
  const [lines, setLines] = useState(initialLines);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState(initialViewState);

  const getModelData = async (mmsId) => {
    // const response = {
    //   mmsId: "9d169e42-2d06-4beb-b698-9c860861e9c8",
    //   assets: [
    //     {
    //       assetId: "TANK_001",
    //       assetType: "NODE",
    //       type: "STORAGE",
    //       coordinates: [[-73.68087908267932, -9.2591640614865]],
    //     },
    //     {
    //       assetId: "CONDUIT_001",
    //       assetType: "LINK",
    //       type: "CONDUIT",
    //       coordinates: [
    //         [-73.68087908267932, -9.2591640614865],
    //         [-73.68038156703334, -9.25796325580228],
    //         [-73.67994951085182, -9.25681504014803],
    //         [-73.679078843370384, -9.25446601880957],
    //       ],
    //     },
    //     {
    //       assetId: "TANK_002",
    //       assetType: "NODE",
    //       type: "STORAGE",
    //       coordinates: [[-73.679078843370384, -9.25446601880957]],
    //     },
    //     {
    //       assetId: "Link Name",
    //       assetType: "LINK",
    //       type: "conduits",
    //       coordinates: [
    //         [-73.679078843370384, -9.25446601880957],
    //         [-73.67946508077451, -9.2544309587896],
    //         [-73.679956057179226, -9.25446601880957],
    //         [-73.68043393728247, -9.25448354881955],
    //       ],
    //     },
    //     {
    //       assetId: "TANK_003",
    //       assetType: "NODE",
    //       type: "STORAGE",
    //       coordinates: [[-73.68043393728247, -9.25448354881955]],
    //     },
    //   ],
    // };

    const response = await hydraulicSimulatorService.getAssetsFromMmsid(mmsId);

    setAssetsData(response);
    parseMapData(response);
    setMmsId(mmsId);
  };

  const getMmsId = async () => {
    const response = await hydraulicSimulatorService.postInpAssets(
      modelFile.data.inpdata,
      modelFile.data.inpName,
      { locationId: modelFile.data.locationId, city_center_coordinates: "" }
    );
    return response.mmsId;
  };

  const parseMapData = (data) => {
    setLinksLayer(() => {
      const links = data.assets.filter((asset) => {
        return asset.assetType == "LINK";
      });

      return [
        new PathLayer({
          id: "Nodes",
          data: links,
          pickable: true,
          getPath: (d) => d.coordinates.map((cord) => [cord[0], cord[1]]),
          getColor: (d) => getLinkColorFromType(d.type, d.invisible, d.edited),
          opacity: 1,
          widthMinPixels: 7,
        }),
      ];
    });
    setPointsLayer(() => {
      const nodes = data.assets.filter((asset) => {
        return asset.assetType == "NODE";
      });
      const iconLayerData = nodes.map((node) => {
        return {
          ...node,
          image: node.invisible
            ? `/img/editModelFile/Deleted.png`
            : node.edited
            ? `/img/editModelFile/Edited.png`
            : getImageFromType(node.type)[0],
          size: getImageFromType(node.type)[1],
        };
      });
      return [
        new IconLayer({
          id: "icon-layer",
          data: iconLayerData,
          pickable: true,
          getPosition: (d) => [d.coordinates[0][0], d.coordinates[0][1]],
          getIcon: (d) => ({
            url: d.image,
            width: 100,
            height: 100,
          }),
          // getIcon: (d) => d.icon,
          sizeScale: 1,
          getSize: (d) => d.size,
          onHover: ({ object }) => {},
        }),
      ];
    });
    const midPoint = findMidpoint(data.assets);
    console.log("🚀 ~ parseMapData ~ data.assets:", data.assets);
    console.log("🚀 ~ parseMapData ~ midPoint:", midPoint);
    setViewState({
      ...viewState,
      longitude: midPoint[0],
      latitude: midPoint[1],
    });
  };

  function getImageFromType(type) {
    switch (type.toUpperCase()) {
      case "OUTFALL":
        return [`/img/editModelFile/Outfalls.png`, 10];

      case "RAIN GAUGES":
        return [`/img/editModelFile/Raingauges.png`, 10];

      case "STORAGE":
        return [`/img/editModelFile/Storage.png`, 10];

      default:
        return [`/img/editModelFile/Storage.png`, 10];
    }
  }

  function getLinkColorFromType(type, invisible, edited) {
    if (invisible) return [255, 0, 0];
    if (edited) return [9, 211, 90];
    switch (type) {
      case "PIPE":
        return [30, 150, 190];

      case "PUMP":
        return [255, 153, 31];

      case "ORIFICE":
        return [114, 12, 157];

      case "WEIR":
        return [61, 122, 62];

      default:
        return [30, 150, 190];
    }
  }

  function tooltipToshow(object) {
    const element = (
      <div style={{ backgroundColor: "#29323c" }}>
        <>
          <div>
            <span style={{ textAlign: "center" }}>Name: {object.assetId}</span>
            {object.type && <div>Type: {`${object.type}`}</div>}
          </div>
        </>
      </div>
    );
    return renderToString(element);
  }

  function handleClickMap({ x, y, coordinate, object }) {
    console.log("🚀 ~ handleClickMap ~ coordinate:", coordinate);
    console.log("🚀 ~ handleClickMap ~ object:", object);
    if (object) {
      setPopupCoordinates(coordinate);
      setSelectedAsset(object);
    } else {
      // clicked off an object
      setSelectedAsset(null);
    }
  }

  function findMidpoint(data) {
    // Variables para mantener la suma total de las coordenadas x e y
    let totalX = 0;
    let totalY = 0;
    let nodeCount = 0;

    // Iterar sobre cada elemento en los datos
    data.forEach((item) => {
      if (
        item.assetType === "NODE" &&
        item.coordinates &&
        item.coordinates.length > 0
      ) {
        // Sumar las coordenadas del nodo actual
        const [x, y] = item.coordinates[0]; // Tomar la primera coordenada
        totalX += x;
        totalY += y;
        nodeCount++;
      }
    });

    // Calcular el punto medio (promedio de coordenadas)
    if (nodeCount > 0) {
      const averageX = totalX / nodeCount;
      const averageY = totalY / nodeCount;
      return [averageX, averageY];
    } else {
      // En caso de no encontrar nodos válidos
      return null;
    }
  }

  function showPopUpDevice(object, coordinates) {
    setPopupCoordinates(coordinates);
    setSelectedAsset(object);
  }

  async function saveChanges() {
    const dataToSend = assetsData.assets
      .filter((asset) => {
        return asset.edited || asset.invisible;
      })
      .map((asset) => {
        return {
          assetId: asset.assetId,
          action: asset.edited ? "EDIT" : asset.invisible ? "DELETE" : "",
          type: asset.type,
          // coordinates: asset.coordinates,
          attributeDTOList: asset.attributes.map((at) => {
            return { name: at.name, value: at.value };
          }),
        };
      });
    const response = await hydraulicSimulatorService.postSaveChangesModelFile(
      mmsId,
      dataToSend
    );
    setAssetsData((prev) => {
      const newData = {
        ...prev,
        assets: prev.assets
          .map((asset) => {
            return { ...asset, edited: false };
          })
          .filter((asset) => !asset.invisible),
      };
      parseMapData(newData);

      return newData;
    });
    setSelectedAsset(null);
    console.log("🚀 ~ saveChanges ~ response:", response);

    if (response) {
      notification.success({
        message: "Successful",
        description: "Model File Edited successfully",
      });
      setMmsId(response);
      // navigate(`/upload?selectedTab=MODEL`);
    }
  }

  async function saveModel() {
    const dataToSend = { ...modelFile.data, mmsId };
    const oui = JSON.parse(
      localStorage.getItem("security") || ""
    ).organizationId;
    const userId = localStorage.getItem("userId") || "";
    const response = await inpService.uploadInps(userId, oui, dataToSend);
    notification.success({
      message: "Successful",
      description: "Model File Saved successfully",
    });
    navigate(`/upload?selectedTab=MODEL`);
  }

  const onMarkerDragEnd = (event, nodeId) => {
    const { lngLat } = event;
    if (!isValidCoordinate(lngLat[0], lngLat[1])) {
      console.error(`Invalid coordinates: [${lngLat[0]}, ${lngLat[1]}]`);
      return;
    }

    // Actualizar la posición del nodo
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, longitude: lngLat[0], latitude: lngLat[1] }
          : node
      )
    );

    // Actualizar las líneas relacionadas con este nodo
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.sourceNodeId === nodeId) {
          const targetNode = nodes.find(
            (node) => node.id === line.targetNodeId
          );
          return {
            ...line,
            coordinates: [
              [lngLat[0], lngLat[1]],
              [targetNode.longitude, targetNode.latitude],
            ],
          };
        } else if (line.targetNodeId === nodeId) {
          const sourceNode = nodes.find(
            (node) => node.id === line.sourceNodeId
          );
          return {
            ...line,
            coordinates: [
              [sourceNode.longitude, sourceNode.latitude],
              [lngLat[0], lngLat[1]],
            ],
          };
        }
        return line;
      })
    );
  };

  useEffect(() => {
    if (!modelFile) {
      if (query.get("mmsId")) {
        setIsViewMode(true);
        getModelData(query.get("mmsId"));
      } else {
        navigate(-1);
        navigate("upload/modelfiles");
      }
    } else {
      getMmsId().then((mmsId) => {
        getModelData(mmsId);
      });
    }
  }, []);

  return (
    <div
      className="MapContainer"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#ebebeb",
      }}
    >
      <DeckGL
        ref={deckRef}
        controller
        ContextProvider={MapContext.Provider}
        viewState={viewState}
        onViewStateChange={(nextViewState) => {
          setViewState(nextViewState.viewState);
        }}
        layers={[...linksLayer, ...pointsLayer]}
        style={initialStyle}
        onClick={handleClickMap}
        getTooltip={({ object }) =>
          object && {
            html: tooltipToshow(object),
            style: {
              backgroundColor: "#29323c",
            },
          }
        }
      >
        <>
          <StaticMap
            mapboxApiAccessToken={mapboxApiAccessToken}
            mapStyle={mapStyle}
          />
          {selectedAsset && (
            <Popup
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              anchor="top"
              closeButton={false}
              onClose={() => setShowPopup(false)}
            >
              <PopupComponent
                mmsId={assetsData.mmsId}
                object={selectedAsset}
                setAttributesValue={(response) => {
                  setAssetsData((prev) => {
                    let copy = { ...prev };
                    const index = copy.assets.findIndex((asset) => {
                      return asset.assetId == selectedAsset.assetId;
                    });
                    copy.assets[index].attributes = response.attributes;
                    copy.assets[index].edited = false;
                    copy.assets[index].invisible = false;
                    setSelectedAsset(copy.assets[index]);
                    parseMapData(copy);
                    console.log("🚀 ~ setAssetsData ~ copy:", copy);
                    return copy;
                  });
                }}
                onEdit={(el) => {
                  setShowLateralMenu(true);
                }}
                onDelete={() => {
                  setAssetsData((prev) => {
                    let copy = { ...prev };
                    const index = copy.assets.findIndex((asset) => {
                      return asset.assetId == selectedAsset.assetId;
                    });
                    copy.assets[index].invisible = true;
                    parseMapData(copy);
                    return copy;
                  });
                }}
                onRestore={() => {
                  setAssetsData((prev) => {
                    let copy = { ...prev };
                    const index = copy.assets.findIndex((asset) => {
                      return asset.assetId == selectedAsset.assetId;
                    });
                    copy.assets[index].invisible = false;
                    parseMapData(copy);
                    return copy;
                  });
                }}
                onUpdateAttributeValue={(key, newValue) => {
                  setAssetsData((prev) => {
                    let copy = { ...prev };
                    const index = copy.assets.findIndex((asset) => {
                      return asset.assetId == selectedAsset.assetId;
                    });
                    const attributeIndex = copy.assets[
                      index
                    ].attributes.findIndex((attr) => {
                      return attr.name == key;
                    });
                    copy.assets[index].attributes[attributeIndex].value =
                      newValue;
                    copy.assets[index].edited = true;
                    return copy;
                  });
                }}
              />
            </Popup>
          )}
        </>
      </DeckGL>

      {!isViewMode && (
        <>
          <Tooltip title="Save Changes">
            <div
              className="text-center"
              style={{
                borderRadius: "100%",
                borderColor: "white",
                border: "solid 3px white",
                backgroundColor: "#29323c",
                height: 30,
                width: 30,
                padding: 1,
                cursor: "pointer",
                position: "absolute",
                top: 80,
                right: 25,
              }}
              onClick={() => {
                saveChanges();
              }}
            >
              <SaveOutlined style={{ cursor: "pointer", color: "white" }} />
            </div>
          </Tooltip>
          <Tooltip title="Upload model">
            <div
              className="text-center"
              style={{
                borderRadius: "100%",
                borderColor: "white",
                border: "solid 3px white",
                backgroundColor: "#29323c",
                height: 30,
                width: 30,
                padding: 1,
                cursor: "pointer",
                position: "absolute",
                top: 120,
                right: 25,
              }}
              onClick={() => {
                // saveChanges();
                saveModel();
              }}
            >
              <UploadOutlined style={{ cursor: "pointer", color: "white" }} />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

function mapState(state) {
  return {
    modelFile: state.modelFiles,
  };
}

export default connect(mapState)(EditModelFileView);
