import { api } from "../../services/api";

export default class InpRepository {
  async getModelFilesByOrganization({
    organizationId,
    page,
    size,
    name,
    locationId,
    onlyCompleted,
  }: {
    organizationId: number;
    page: number;
    size: number;
    name?: string | null;
    locationId?: number | null;
    onlyCompleted?: boolean | null;
  }) {
    const response = await api.get(
      `/inps/uploads/organizations/${organizationId}`,
      { params: { page, size, name, locationId, onlyCompleted } }
    );
    return response.data;
  }

  async uploadInps(
    userId: string,
    organizationId: string,
    data: any
  ): Promise<boolean> {
    const locations = await api.post(
      `/inps/uploads/users/${userId}/organizations/${organizationId}`,
      data
    );
    return locations.data;
  }
  async changeStatusInp(inpId: any, status: boolean): Promise<boolean> {
    const response = await api.post(`/inps/${inpId}/status`, {
      status,
    });
    return response.data;
  }

  async getModelInfrastructureId(
    inpId: any,
    typeId: any,
    name: string | null
  ): Promise<any> {
    const response = await api.get(`inps/${inpId}/assets`, {
      params: {
        page: 1,
        size: 10000,
        typeId,
        name,
      },
    });
    return response.data;
  }

  async enableInp(inpId: string | number, enabled: boolean): Promise<boolean> {
    const response = await api.put(`inps/${inpId}?enabled=${enabled}`);
    return response.data;
  }

  async disableInp(inpId: string | number, enabled: boolean): Promise<boolean> {
    const response = await api.put(`inps/${inpId}?enabled=${enabled}`);
    return response.data;
  }
}
