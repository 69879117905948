// activitiesReducer.tsx
import { Dispatch } from "redux";
import NotificationRepository, { Notification } from "./NotificationRepository";

// Define initial state type
interface ActivitiesState {
  fetching: boolean;
  array: Notification[];
  current: Notification | {};
  error?: string;
}

// Initial state
const initialData: ActivitiesState = {
  fetching: false,
  array: [],
  current: {},
};

// Action types
const GET_NOTIFICATION = "GET_NOTIFICATION";
const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

// Action interfaces for TypeScript
interface GetNotificationAction {
  type: typeof GET_NOTIFICATION;
}

interface GetNotificationSuccessAction {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: Notification[];
}

interface GetNotificationErrorAction {
  type: typeof GET_NOTIFICATION_ERROR;
  payload: string;
}

// Union of actions for the reducer
type ActivitiesActionTypes =
  | GetNotificationAction
  | GetNotificationSuccessAction
  | GetNotificationErrorAction;

// Reducer
export default function activitiesReducer(
  state = initialData,
  action: ActivitiesActionTypes
): ActivitiesState {
  switch (action.type) {
    case GET_NOTIFICATION:
      return { ...state, fetching: true };
    case GET_NOTIFICATION_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_NOTIFICATION_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}

// Thunk action to get recent notifications
export const getActivitiesAction = () => async (dispatch: Dispatch) => {
  const repository = new NotificationRepository();
  dispatch({ type: GET_NOTIFICATION });
  try {
    const notifications = await repository.getRecentNotifications();
    dispatch({
      type: GET_NOTIFICATION_SUCCESS,
      payload: notifications,
    });
  } catch (error: any) {
    console.error(error);
    dispatch({
      type: GET_NOTIFICATION_ERROR,
      payload: error.response ? error.response.message : "Error fetching notifications",
    });
  }
};
