import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import InpService from "../../domain/ModelFile/InpService";
import {
  PlusCircleOutlined,
  PlusCircleFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import StateMachineContext from "./context";

const inpService = new InpService();

interface SideBarProps {
  onSave: Function;
  isEditMode: boolean;
  nodeSelect: any;
  onChangeNode: Function;
  closeEditMode: Function;
  stateMachineDetails: any;
}

interface strategyList {
  name: string;
  value: string;
  label: string;
};

export const StateMachineSidebar: React.FC<SideBarProps> = ({
  onSave,
  isEditMode,
  nodeSelect,
  onChangeNode,
  closeEditMode,
  stateMachineDetails,
}: SideBarProps) => {
  const navigate = useNavigate();
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string
  ) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  const { value: context, setValue: setContext } =
    useContext(StateMachineContext);
  const [modelInfraIdList, setModelInfraIdList] = useState<any>([]);
  const [inputsList, setInputsList] = useState<any[]>([]);
  const [outputsList, setOutputsList] = useState<any[]>([]);
  const [constantsList, setConstantsList] = useState<any[]>([]);
  const [constantsStratList, setConstantsStratList] = useState<any[]>([]);
  const [strategyList, setStrategyList] = useState<any[]>([]);
  const [formInput] = Form.useForm();
  const [formOutput] = Form.useForm();
  const [formConstants] = Form.useForm();
  const [formStrategies] = Form.useForm();
  const [formConstantStrategies] = Form.useForm();


  // async function handleGetInfrastructures(search: any) {
  //   if (!locationId) return;
  //   const response = await infrastructureService.getInfraestructureInUse(
  //     locationId,
  //     1,
  //     100,
  //     "",
  //     null,
  //     0
  //   );
  //   setInfraestructures(response.data);
  // }

  const handleGetModelInfraId = async (formType:any) => {
    const typeId = formType.getFieldValue("inpObjectType");
  
    const response = await inpService.getModelInfrastructureId({
      inpId: stateMachineDetails.inpId,
      typeId: typeId,
    });
  
    setModelInfraIdList(response.data);
  };
  

  useEffect(() => {
    // handleGetInfrastructures("");
  }, []);

  return (
    <aside className="flex column" style={{ overflow: "auto" }}>
      <>
        <span>Flow nodes</span>
        <div className="description">
          You can drag these nodes to the pane on the left.
        </div>
        <div
          className="dndnode parameter"
          onDragStart={(event) => onDragStart(event, "node")}
          draggable
        >
          New State
        </div>
        <span style={{ fontWeight: 700 }} className="mb-2">
          *Below create your own variable and use it to create actions and
          transitions
        </span>
        <div className="flex column">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Input</span>
          <Form
            form={formInput}
            layout="vertical"
            onFinish={(values) => {
              const label = `Measurements.${values.inpObjectType == 1 ? "nodes" : "links"
                }.${values.inpObjectId}.${values.inpUnit}`;
              if (
                context.inputsList.findIndex((inp) => {
                  return inp.label == label;
                }) >= 0
              ) {
                return;
              }
              setContext((prev) => {
                return {
                  ...prev,
                  inputsList: [
                    ...prev.inputsList,
                    {
                      ...values,
                      assetType: values.inpObjectType == 1 ? "nodes" : "links",
                      assetId: values.inpObjectId,
                      attribute: values.inpUnit,
                      label,
                    },
                  ],
                };
              });
              formInput.resetFields();
            }}
            onFinishFailed={() => { }}
            autoComplete="off"
            size="small"
          >
            <div
              className="flex mt-1"
              style={{ width: "100%", alignItems: "end" }}
            >
              <Form.Item
                label="Object Type"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpObjectType"
                rules={[
                  { required: true, message: "Please select a Object Type" },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select an option"
                  onChange={() => {
                    formInput.setFieldValue("inputObjectId", null);
                    // sigueinte input
                    handleGetModelInfraId(formInput);
                  }}
                  options={[
                    { value: 1, label: "Nodes" },
                    { value: 2, label: "Links" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Object ID"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpObjectId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Model Infrastructure ID",
                  },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  placeholder="Select an option"
                  options={modelInfraIdList?.map((modelid: any) => {
                    return {
                      value: modelid,
                      label: modelid,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                label="Attributes"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpUnit"
                rules={[
                  { required: true, message: "Please select a unit type" },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select an option"
                  options={[
                    { value: "depth", label: "Depth" },
                    { value: "flow", label: "Flow" },
                  ]}
                />
              </Form.Item>
              <Button
                style={{ width: "10%", border: "none" }}
                icon={<PlusCircleFilled />}
                htmlType="submit"
              />
            </div>
          </Form>
          <div className="flex column mt-1">
            {context.inputsList.map((input) => {
              return (
                <div
                  className="flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{input.label}</span>
                  <Button
                    style={{ width: "10%", border: "none" }}
                    icon={<MinusCircleOutlined />}
                    htmlType="submit"
                    onClick={() => {
                      setContext((prev) => {
                        return {
                          ...prev,
                          inputsList: prev.inputsList.filter((val) => {
                            return val.label != input.label;
                          }),
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex column mt-2">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Output</span>
          <Form
            form={formOutput}
            layout="vertical"
            onFinish={(values) => {
              const label = `Outputs.${values.inpObjectType == 1 ? "nodes" : "links"
                }.${values.inpObjectId}.${values.inpUnit}`;
              if (
                context.outputsList.findIndex((out) => {
                  return out.label == label;
                }) >= 0
              ) {
                return;
              }
              setContext((prev) => {
                return {
                  ...prev,
                  outputsList: [
                    ...prev.outputsList,
                    {
                      ...values,
                      assetType: values.inpObjectType == 1 ? "nodes" : "links",
                      assetId: values.inpObjectId,
                      attribute: values.inpUnit,
                      label,
                    },
                  ],
                };
              });
              formOutput.resetFields();
            }}
            onFinishFailed={() => { }}
            autoComplete="off"
            size="small"
          >
            <div
              className="flex mt-1"
              style={{ width: "100%", alignItems: "end" }}
            >
              <Form.Item
                label="Object Type"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpObjectType"
                rules={[
                  { required: true, message: "Please select a Object Type" },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select an option"
                  onChange={() => {
                    formOutput.setFieldValue("inputObjectId", null);
                    // sigueinte input
                    handleGetModelInfraId(formOutput);
                  }}
                  options={[
                    { value: 1, label: "Nodes" },
                    { value: 2, label: "Links" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Object ID"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpObjectId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Model Infrastructure ID",
                  },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  placeholder="Select an option"
                  options={modelInfraIdList?.map((modelid: any) => {
                    return {
                      value: modelid,
                      label: modelid,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                label="Attributes"
                style={{ width: "30%", margin: "0px", paddingRight: "5px" }}
                name="inpUnit"
                rules={[
                  { required: true, message: "Please select a unit type" },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select an option"
                  options={[
                    { value: "target_setting", label: "Target Setting" },
                  ]}
                />
              </Form.Item>
              <Button
                style={{ width: "10%", border: "none" }}
                icon={<PlusCircleFilled />}
                htmlType="submit"
              />
            </div>
          </Form>
          <div className="flex column mt-1">
            {context.outputsList.map((output) => {
              return (
                <div
                  className="flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>{output.label}</span>
                  <Button
                    style={{ width: "10%", border: "none" }}
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      setContext((prev) => {
                        return {
                          ...prev,
                          outputsList: prev.outputsList.filter((val) => {
                            return val.label != output.label;
                          }),
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>


        <div className="flex column mt-2">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Strategies</span>
          <Form
            form={formStrategies}
            layout="inline"
            onFinish={(values) => {
              const strategyList = context.strategyList || [];
              if (strategyList.find((strategy) => strategy.name === values.name)) {
                return;
              }

              const newStrategy = { name: values.name };
              const updatedStrategyList = [...strategyList, newStrategy];
              const updatedConstantsList = context.constantsList.map((constant) => ({
                ...constant,
                strategies: [...constant.strategies, { strategyName: values.name, strategyValue: 0 }],
              }));

              setContext((prev) => ({
                ...prev,
                strategyList: updatedStrategyList,
                constantsList: updatedConstantsList,
              }));

              formStrategies.resetFields();
            }}
            autoComplete="off"
            size="small"
            style={{ width: "100%" }}
          >
            <div className="flex mt-1" style={{ width: "100%" }}>
              <Form.Item
                style={{ width: "45%" }}
                name="name"
                rules={[{ required: true, message: "Please enter a strategy" }]}
              >
                <Input placeholder="Strategy name" />
              </Form.Item>
              <Button
                style={{ width: "10%", border: "none" }}
                icon={<PlusCircleFilled />}
                htmlType="submit"
              />
            </div>
          </Form>

          <div className="flex column mt-1">
            {context.strategyList?.map((strategy, index) => (
              <div
                key={index}
                className="flex"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>{strategy.name}</span>
                <Button
                  style={{ width: "10%", border: "none" }}
                  icon={<MinusCircleOutlined />}
                  shape="circle"
                  onClick={() => {
                    const updatedStrategyList = context.strategyList.filter((val) => val.name !== strategy.name);
                    const updatedConstantsList = context.constantsList.map((constant) => ({
                      ...constant,
                      strategies: constant.strategies.filter((s: any) => s.strategyName !== strategy.name),
                    }));

                    setContext((prev) => ({
                      ...prev,
                      strategyList: updatedStrategyList,
                      constantsList: updatedConstantsList,
                    }));
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex column mt-2">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Constants</span>
          <Form
            form={formConstants}
            layout="inline"
            onFinish={(values) => {
              if (context.constantsList?.find((constant) => constant.name === values.name)) {
                return;
              }

              const newConstant = {
                name: values.name,
                value: values.value,
                strategies: (context.strategyList || []).map((strategy) => ({
                  strategyName: strategy.name,
                  strategyValue: values.value,
                })),
                label: `Constants.${values.name}=${values.value}`,
              };

              setContext((prev) => ({
                ...prev,
                constantsList: [...prev.constantsList, newConstant],
              }));

              formConstants.resetFields();
            }}
            autoComplete="off"
            size="small"
            style={{ width: "100%" }}
          >
            <div className="flex mt-1" style={{ width: "100%" }}>
              <Form.Item
                style={{ width: "45%" }}
                name="name"
                rules={[{ required: true, message: "Please enter a constant name" }]}
              >
                <Input placeholder="Constant name" />
              </Form.Item>
              <Form.Item
                style={{ width: "45%" }}
                name="value"
                rules={[{ required: true, message: "Please enter a constant value" }]}
              >
                <InputNumber placeholder="Constant value" style={{ width: "100%" }} />
              </Form.Item>
              <Button
                style={{ width: "10%", border: "none" }}
                icon={<PlusCircleFilled />}
                htmlType="submit"
              />
            </div>
          </Form>
          <div className="flex column mt-1">
            {context.constantsList?.map((constant, index) => (
              <div
                key={index}
                className="flex column"
                style={{ marginBottom: "8px" }}
              >
                <div className="flex" style={{ justifyContent: "space-between" }}>
                  <span>{`Constants.${constant.name} = ${constant.value}`}</span>
                  <Button
                    style={{ width: "10%", border: "none" }}
                    icon={<MinusCircleOutlined />}
                    shape="circle"
                    onClick={() => {
                      setContext((prev) => ({
                        ...prev,
                        constantsList: prev.constantsList.filter((val) => val.name !== constant.name),
                      }));
                    }}
                  />
                </div>
                <span style={{ marginTop: "4px",marginLeft: "16px" }}>Strategies:</span>
                <div className="flex column" style={{ marginLeft: "32px" }}>
                  {constant.strategies.map((strategy: any, strategyIndex: number) => (
                    <div
                      key={`${constant.name}-${strategy.strategyName}-${strategyIndex}`}
                      className="flex"
                      
                    >
                      <Form
                        form={formConstantStrategies}
                        layout="inline"
                        onFinish={(x) => {
                          const newConstantStrat = {
                            strategies: (context.constantsStratList || []).map((x) => ({
                              strategyName: x.name,
                              strategyValue: x.value,
                            })),
                          };
                          setContext((prev) => ({
                            ...prev,
                            constantsStratList: [...prev.constantsStratList, newConstantStrat],
                          }));

                          formConstants.resetFields();
                        }}
                        autoComplete="off"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <div className="flex mt-1" style={{ width: "100%" }}>
                          <span style={{ width: "100%" }}>{strategy.strategyName}</span>
                          <Form.Item
                            style={{ width: "45%" }}
                            name={`strategy-${constant.name}-${strategy.strategyName}`}
                            initialValue={strategy.strategyValue}
                            rules={[{ required: true, message: "Please enter a value" }]}
                          >
                            <InputNumber
                              placeholder="Value"
                              style={{ width: "100%" }}
                              onChange={(value) => {
                                const updatedConstantsList = context.constantsList.map((c) => {
                                  if (c.name === constant.name) {
                                    return {
                                      ...c,
                                      strategies: c.strategies.map((s: any) =>
                                        s.strategyName === strategy.strategyName
                                          ? { ...s, strategyValue: value }
                                          : s
                                      ),
                                    };
                                  }
                                  return c;
                                });

                                setContext((prev) => ({
                                  ...prev,
                                  constantsList: updatedConstantsList,
                                }));
                              }}
                            />
                          </Form.Item>
                        </div>

                      </Form>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        </div>



        <div className="flex justify-content-around mt-auto">
          <Button
            style={{
              backgroundColor: "#00203C",
              color: "white",
              height: "40px",
            }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </Button>
          <Button
            style={{
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    </aside >
  );
};