import React, { useEffect, useState } from "react";
import ParameterService from "../domain/Parameter/ParameterService";

const parameterService = new ParameterService();

export default function useParameter({
  infrastructureId,
}: {
  infrastructureId: number | null;
}) {
  const [parameters, setParameters] = useState<Parameter[]>([]);

  async function handleGetParameters() {
    if (infrastructureId) {
      const res = await parameterService.getParametersListByInfrastructure(
        infrastructureId
      );
      setParameters(res);
    }
    else{
      setParameters([])
    }
  }

  useEffect(() => {
    handleGetParameters();
  }, [infrastructureId]);

  return { parameters };
}
