export const infraTypes = {
  STORAGE: 1,
  JUNCTION: 2,
  OUTFALL: 3,
  CONDUIT: 4,
  PUMP: 5,
  ORIFICE: 6,
  WEIR: 7,
  PLUVIOMETER: 8,
  TANK: 9,
  RESERVOIRS: 10,
  PIPE: 11,
  VALVE: 12,
};

export const infraTypesById = {
  1:	"STORAGE",
  2:	"JUNCTION",
  3:	"OUTFALL",
  4:	"CONDUIT",
  5:	"PUMP",
  6:	"ORIFICE",
  7:	"WEIR",
  8:	"RAIN GAUGE",
  9:	"TANK",
  10:	"RESERVOIRS",
  11:	"PIPE",
  12:	"VALVE"
}


export const infraTypesByIdForIcons = {
  1:	"STORAGETANK",//Storage
  2:	"JUNCTION",
  3:	"OUTFALL",
  4:	"CONDUIT",
  5:	"PUMP",
  6:	"ORIFICE",
  7:	"WEIR",
  8:	"RAINGAURGE",
  9:	"STORAGETANK",//Tank
  10:	"RESERVOIR",
  11:	"CONDUIT",//PIPE
  12:	"VALVE"
}