import React, { useEffect, useState } from "react";
import InfrastructureService from "../domain/Infrastructure/InfraestructureService";

const infrastructureService = new InfrastructureService();

export default function useInfrastructure({
  location,
  organizationId,
  page = 1,
  size = 20,
  name = null,
  infraId = null,
  infraTypeId = null,
  mode = "ByLocation",
}: {
  location: number | null;
  organizationId: number | null;
  page?: number;
  size?: number;
  name?: string | null;
  infraId?: number | null;
  infraTypeId?: number | null;
  mode: "ByLocation" | "ByOrganization";
}) {
  const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([]);
  const [pagination, setPagination] = useState({ page, size, totalRows: 0 });
  const [params, setParams] = useState<any>({
    name,
    location,
    infraId,
    infraTypeId,
    organizationId,
  });

  async function handleGetInfrastructures() {
    if (mode === "ByLocation" && location) {
      const response = await infrastructureService.getInfraestructureInUseV2(
        params.location,
        pagination.page,
        pagination.size,
        params.name,
        params.infraId,
        params.infraTypeId
      );
      setInfrastructures(response.infrastructures);
      setPagination({ ...pagination, totalRows: response.totalRecords });
    }
    // else{
    //   setInfrastructures([])
    //   setPagination({ ...pagination, totalRows: 0, page:1 })
    // }
    //TODO: Necesito que añadan el filtro por nombre
    if (mode === "ByOrganization" && organizationId) {
      const response =
        await infrastructureService.getAllInfrastructuresByOrganization({
          organizationId: params.organizationId,
          page: pagination.page,
          size: pagination.size,
        });
      setInfrastructures(response.infrastructures);
      setPagination({ ...pagination, totalRows: response.totalRecords });
    }
    // else{
    //   setInfrastructures([])
    //   setPagination({ ...pagination, totalRows: 0, page:1 })
    // }
  }

  useEffect(() => {
    handleGetInfrastructures();
  }, [pagination.page, pagination.size, params]);

  return { infrastructures, pagination, params, setPagination, setParams };
}
