import React, { FC, useState, useEffect, useRef } from "react";
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  notification,
  Select,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUnitsAction } from "../../redux/unitsDuck";
import SelectInfrastructure from "../../basicComponents/Selects/SelectInfrastructure";
import SelectLocation from "../../basicComponents/Selects/SelectLocation"
import LocationService from "../../domain/Location/LocationService";
import NonRevenueWaterService from "../../domain/NonRevenueWater/NonRevenueWaterService";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SelectParameter from "../../basicComponents/Selects/SelectParameter";
import moment, { Moment } from "moment";
import { useSearchParams } from "react-router-dom";
const locationService = new LocationService();
const NonRevenueWaterView: React.FC = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [entries, setEntries] = useState<any[]>([]);
  const [nrwId, setNrwId] = useState<number | null>(null);
  const [nrwInvoiceId, setnrwInvoiceId] = useState<number | null>(null);
  const units = useSelector((state: any) => state.units.array);
  const userId = localStorage.getItem("userId") || "";
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [newRule, setNewRule] = useState<any>({
    selectedInfrastructure: null,
    selectedParam: null,
    criticalLow: null,
    critialHigh: null,
  });
  const { RangePicker } = DatePicker;
  const selectInfrastructureRef = useRef<any>(null);
  const selectParameterRef = useRef<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [location, setLocation] = useState<any>(null);
  const [locations, setLocations] = useState<any>(null);
  const [formConfig] = Form.useForm();
  const [formEntry] = Form.useForm();
  const dispatch: any = useDispatch();
  const selectLocationRef = useRef<any>(null);
  const nonRevenueWaterService = new NonRevenueWaterService();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);


  useEffect(() => {
    if (searchParams.get("locationId") && selectLocationRef.current) {
      const locationParam = searchParams.get("locationId");
      selectLocationRef.current.setValue(locationParam);
    }
  }, [searchParams]);
  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }, []);
  useEffect(() => {
    dispatch(getUnitsAction());
  }, [dispatch]);

  useEffect(() => {
    console.log("Units retrieved:", units);
  }, [units]);

  const clearFormAndState = () => {
    formConfig.setFieldsValue({
       measurementTypeId: null,
    });
    console.log('hola',selectParameterRef)
    selectInfrastructureRef.current?.setValue(null);
    selectParameterRef.current?.setValue(null);
    
  
    setEntries([]);
    setNewRule({
      selectedInfrastructure: null,
      selectedParam: null,
      unit: null,
    });
    setNrwId(null);
  };
  
  const handleGetNonRevenueWater = async (locationId: number | null) => {
    clearFormAndState();
  
    if (!locationId) {
      console.warn("No location ID provided");
      return;
    }
  
    try {
      const response = await nonRevenueWaterService.getConfiguration(locationId);
  
      if (response.nrwId === null) {
        setNrwId(null);
        setIsVisible(true);
        setIsVisible2(false);
        setIsVisible3(true);
      } else {
        const formattedInvoices = response.invoice.map((entry: any) => ({
          ...entry,
          startDate: moment(entry.startDate).format("YYYY-MM-DD"),
          endDate: moment(entry.endDate).format("YYYY-MM-DD"),
        }));
  
        setNrwId(response.nrwId);
        setIsVisible(true);
        setIsVisible2(true);
        setIsVisible3(false);
  
        formConfig.setFieldsValue({
          locationId: response.locationId,
          measurementTypeId: response.measurementTypeId,
        });
  
        selectInfrastructureRef.current?.setValue(response.infrastructure);
        selectParameterRef.current?.setValue(String(response.parameterId));
  
        setNewRule((prev: any) => ({
          ...prev,
          selectedInfrastructure: { value: response.infrastructure },
          selectedParam: { value: String(response.parameterId) },
          unit: response.measurementTypeId,
        }));
  
        setEntries(formattedInvoices);
      }
    } catch (error) {
      console.error("Error fetching Non-Revenue Water data:", error);
    }
  };
  
  const handleViewLocations = async () => {
    try {
      const response = await nonRevenueWaterService.createOrUpdateConfiguration({
        nrwId: nrwId ?? null,
        locationId: location ?? 0,
        infrastructure: newRule.selectedInfrastructure?.value,
        parameterId: Number(newRule.selectedParam?.value),
        measurementTypeId: newRule.unit,
      });
  
      if (response.success !== 0) {
        notification.success({
          message: "Successful",
          description: "Configuration saved successfully",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error saving configuration",
        });
      }
      await handleGetNonRevenueWater(location);
  
    } catch (error) {
      console.error("Error saving configuration:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while saving the configuration",
      });
    }
  };
 
  const handleAddEntry = async () => {
    try {
      const values = formEntry.getFieldsValue();
      const [startDate, endDate] = values.dateRange || [null, null];

      const formattedStartDateForService = startDate
        ? startDate.format("YYYY-MM-DD HH:mm:ss")
        : null;
      const formattedEndDateForService = endDate
        ? endDate.format("YYYY-MM-DD HH:mm:ss")
        : null;

      const formattedStartDateForEntry = startDate
        ? startDate.format("YYYY-MM-DD")
        : null;
      const formattedEndDateForEntry = endDate
        ? endDate.format("YYYY-MM-DD")
        : null;
        
      const defaultNwrId = nrwId ?? 0;

      const response = await nonRevenueWaterService.addInvoiceToConfiguration({
        nrwId: defaultNwrId,
        startDate: formattedStartDateForService,
        endDate: formattedEndDateForService,
        invoice: values.value,
      });

      if (response.nrwInvoiceId) {
        const newEntry = {
          nrwInvoiceId: response.nrwInvoiceId,
          startDate: formattedStartDateForEntry,
          endDate: formattedEndDateForEntry,
          value: values.value,
        };

        setEntries([...entries, newEntry]);
        formEntry.resetFields();
        setIsAddButtonDisabled(true);

        console.log("Entry added:", newEntry);
        notification.success({
          message: "Successful",
          description: "Invoice added successfully",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error adding the invoice",
        });
        console.error("Failed to add entry:", response.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error adding the invoice:", error);
    }
  };

  const handleFormChange = () => {
    const values = formEntry.getFieldsValue();
    const [startDate, endDate] = values.dateRange || [null, null];
    const value = values.value;
    setIsAddButtonDisabled(!startDate || !endDate || value === undefined || value === null);
  };


  const handleDeleteEntry = async () => {
    if (!nrwInvoiceId) return;

    try {
      const response =
        await nonRevenueWaterService.deleteInvoiceFromConfiguration(
          nrwInvoiceId
        );

      if (response.success) {
        setEntries(
          entries.filter((entry) => entry.nrwInvoiceId !== nrwInvoiceId)
        );
        setShowConfirmDelete(false);
        notification.success({
          message: "Successful",
          description: "Inovice deleted Successfully",
        });
        console.log("invoice deleted:", nrwInvoiceId);
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete invoice",
        });
        console.error("Failed to delete invoice:", response.message);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  };

  const handleChange = (value: number) => {
    console.log(`Selected unit: ${value}`);
    setNewRule((rl: any) => ({
      ...rl,
      unit: value,
    }));
  };

  const handleGenerateInvoice = async (invoiceValue: number) => {
    try {
      const response = await nonRevenueWaterService.calculateInvoice({
        invoiceValue,
      });
      notification.success({
        message: "Successful",
        description: "Inovice calculated Successfully",
      });
      console.log("Invoice added Successfully:", response);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error calculating invoice",
      });
      console.error("Error generating invoice:", error);
    }
  };

  const columnsTable: ColumnsType<any> = [
    {
      title: "Date Range",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <>
          <div>
            <span>{`${record.startDate} - ${record.endDate}`}</span>
          </div>
        </>
      ),
    },
    {
      title: "Invoiced Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <div>
            <Tooltip title="Delete">
              <DeleteOutlined
                style={{ fontSize: "1.5rem" }}
                onClick={() => {
                  setShowConfirmDelete(true);
                  setnrwInvoiceId(record.nrwInvoiceId);
                }}
              />

            </Tooltip>
            <a
              style={{ marginLeft: 16, cursor: "pointer", color: "#1890ff" }}
              onClick={() => handleGenerateInvoice(record.nrwInvoiceId)}
            >
              Generate
            </a>
          </div>
        </>
      ),
    },
  ];

  const dataSource = entries;

 


  return (
    <div className="flex column align-items-star" style={{ overflow: "auto", width: "100%", height: '100vh' }}>
      <div className="text-title-page">Non Revenue Water</div>
      <div className="flex column pl-3 pr-3">
        <Form
          form={formConfig}
          onFinish={handleViewLocations}
          initialValues={{ status: true }}
          layout="horizontal"
        >
          <div
            className="flex row align-start"
            style={{ marginBottom: "10px" }}
          >{!isVisible && (
            <label
              style={{ width: "100%", textAlign: "left", paddingRight: "10px" }}
            >
              Select a location to get information about invoice configuration
            </label>
          )}
          </div>
          <div
            className="flex row align-start"
            style={{ marginBottom: "10px" }}
          >
            <label
              style={{ width: "20%", textAlign: "left", paddingRight: "10px" }}
            >
              Location
            </label>
            <Form.Item
              name="locationId"
              style={{ width: "30%", margin: "0" }}
              rules={[{ required: true }]}
            >
              <SelectLocation
                ref={selectLocationRef}
                userId={userId}
                onSelect={(value: any) => {
                  setLocation(value);
                  handleGetNonRevenueWater(value);
                  if (value) {
                    setSearchParams({ locationId: value });
                  } else {
                    setSearchParams({});
                  }
                }}
              />
            </Form.Item>
          </div>
          {isVisible && (
            <div>
              <div
                className="flex row align-start"
                style={{ marginBottom: "10px" }}
              >
                <label
                  style={{
                    width: "20%",
                    textAlign: "left",
                    paddingRight: "10px",
                  }}
                >
                  Measurement Node
                </label>
                <Form.Item
                  name="infrastructure"
                  style={{ width: "30%", margin: "0" }}
                  rules={[
                    { required: true, message: "Please select a Infrastructure" },
                  ]}
                >
                  <SelectInfrastructure
                    ref={selectInfrastructureRef}
                    mode="ByLocation"
                    location={location}
                    labelInValue
                    onSelect={(val: object) => {
                      setNewRule((rl: any) => ({
                        ...rl,
                        selectedInfrastructure: val,
                        selectedParam: null,
                      }));
                      selectParameterRef.current.cleanValue();
                    }}
                  />
                </Form.Item>
              </div>
              <div
                className="flex row align-start"
                style={{ marginBottom: "10px" }}
              >
                <label
                  style={{
                    width: "20%",
                    textAlign: "left",
                    paddingRight: "10px",
                  }}
                ></label>
                <Form.Item
                  name="parameterId"
                  style={{ width: "30%", margin: "0" }}
                  rules={[
                    { required: true, message: "Please select a Parameter" },
                  ]}
                >
                  <SelectParameter
                    ref={selectParameterRef}
                    infrastructureId={newRule.selectedInfrastructure?.value}
                    onSelect={(val: any) => {
                      setNewRule((rl: any) => ({
                        ...rl,
                        selectedParam: typeof val === 'object' && val !== null
                          ? { value: val.id }
                          : { value: val },
                      }));
                    }}
                  />

                </Form.Item>
              </div>
              <div
                className="flex row align-start"
                style={{ marginBottom: "10px" }}
              >
                <label
                  style={{
                    width: "20%",
                    textAlign: "left",
                    paddingRight: "10px",
                  }}
                >
                  Unit Measurement
                </label>
                <Form.Item
                  name="measurementTypeId"
                  style={{ width: "30%", margin: "0" }}
                  rules={[
                    { required: true, message: "Please select a Unit Measurement" },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Unit"
                    optionFilterProp="children"
                    onChange={handleChange}
                  >
                    {units.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
          {isVisible3 && (<div className="flex row justify-content-center" style={{ marginTop: "20px", width: "70%" }}>
            <Button
              type="primary"
              shape="round"
              ghost
              style={{ width: "14%", margin: "auto" }}
              onClick={() => handleViewLocations()}
            >
              Save
            </Button>

          </div>)}
        </Form>
      </div>
      {isVisible2 && (<div className="flex column pl-3 pr-3 mt-4">
        <Form
          form={formEntry}
          onFieldsChange={handleFormChange}
          onFinish={handleAddEntry}
          initialValues={{ status: true }}
          layout="horizontal"
        >
          <div
            className="flex row align-start"
            style={{ marginBottom: "10px" }}
          >
            <label
              style={{
                width: "100%",
                textAlign: "left",
                paddingRight: "10px",
              }}
            >
              Invoiced Values
            </label>
            <Form.Item className="pl-2"
              name="dateRange"
              style={{ width: "40%", margin: "0 25px 0 0" }}
              rules={[
                { required: true, message: "Please select a date range" },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                onChange={(dates, dateStrings) => {
                  console.log("Selected dates:", dates, dateStrings);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Invoiced Value"
              name="value"
              style={{ width: "25%", margin: "0", textAlign: "right" }}
              rules={[
                {
                  required: true,
                  message: "Enter an invoice value",
                },
              ]}
            >
              <InputNumber
                placeholder="Invoice"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Button
              style={{ width: "10%", border: "none" }}
              icon={<PlusCircleFilled />}
              onClick={handleAddEntry}
              disabled={isAddButtonDisabled}
            />
          </div>
        </Form>

        <Table style={{ maxHeight: "650px", overflowX: "auto", width: "70%" }}
          columns={columnsTable}
          dataSource={dataSource}
          pagination={false}
          rowKey={(record) => record.nrwInvoiceId}
        />
      </div>)}
      {isVisible2 && (<div className="flex row justify-content-center" style={{ margin: "20px 0", width: "70%" }}>
        <Button
          type="primary"
          shape="round"
          ghost
          style={{ width: "14%" }}
          onClick={() => handleViewLocations()}
        >
          Save
        </Button>


        <ConfirmationModal
          title="Delete Invoice?"
          message="Are you sure you want to delete this Invoice?"
          showModal={showConfirmDelete}
          onAccept={handleDeleteEntry}
          oncancel={() => {
            setShowConfirmDelete(false);
          }}
        />
      </div>)}
    </div>
  );
};

export default NonRevenueWaterView;
