import React, { FC, useContext, useEffect, useState } from "react";
import { Avatar, Button, Card, Skeleton, Switch, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import UpdateIconSvg from "../../assets/svg/UpdateIconSvg";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Col, Row, Slider } from "antd";
import PinSvg from "../../assets/svg/PinSvg";
import KpiService from "../../domain/Kpi/KpiService";
import Kpi from "../../components/Kpi";
import { useNavigate } from "react-router-dom";
import DashboardContext from "../../layout/DashboardLayout/context";
import { PinOffSvg } from "../../assets/svg/PinOffSvg";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import dayjs from "dayjs";
import RefreshIconSvg from "../../assets/svg/RefreshIconSvg";
import KpiCard from "./../../components/Dashboard/KpiCard";

const kpiService = new KpiService();

interface KpiWithShow extends Kpi {
  show: boolean;
}

interface IProps {}

/**
 * @author
 * @function @KpiDashBoardView
 **/

const { Meta } = Card;

var y = 300;

const KpiDashBoardView: FC<IProps> = (props) => {
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const [loading, setLoading] = useState(false);
  const [kpiEdit, setKpiEdit] = useState(0);
  const [kpis, setKpis] = useState<any[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [pinKpi, setPinKpi] = useState<any[]>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const oui = JSON.parse(localStorage.getItem("security") || "").organizationId;
  const userId = localStorage.getItem("userId") || "";
  const navigate = useNavigate();

  const onChange = (checked: boolean) => {
    setLoading(!checked);
  };

  const cardStyle = (index: number) => ({
    background: selectedCardIndex === index ? "#f0f0f0" : "#fff",
    // color: selectedCardIndex === index ? "#fff" : "#000",
    // borderColor: selectedCardIndex === index ? "fff" : "00203C",
  });

  const pinStyle = (value: any) => {
    if (value) {
      return PinOffSvg;
    } else {
      return PinSvg;
    }
  };

  const handleCardClick = (index: number) => {
    setSelectedCardIndex(index);
  };

  const handlePinKpi = async (value: any) => {
    // console.log("value", value);
    let resp;
    if (value.show) {
      resp = await kpiService.postPinKpiInDashboard(value.id, false);
      if (resp.success) {
        setPinKpi([...pinKpi, value]);
        handleGetKpi();
      }
    } else {
      resp = await kpiService.postPinKpiInDashboard(value.id, true);
      if (resp.success) {
        setPinKpi([...pinKpi, value]);
        handleGetKpi();
      }
    }
  };

  const getLastKeyValue = (data: any) => {
    const keys = Object.keys(data).sort();
    const lastKey = keys[keys.length - 1];
    const date = new Date(parseInt(lastKey)).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const value = data[lastKey];
    return lastKey ? { date, value } : null;
  };

  const orderData = (data: Record<string, any>) => {
    const entries = Object.entries(data);
    entries.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return Object.fromEntries(entries);
  };

  const objectToArray = (values: any, first?: boolean, last?: boolean) => {
    // console.log("---------");
    // console.log("objectToArray", values);
    let value: any;

    if (Object.entries(values).length !== 0) {
      const arregloOrdenado: any = Object.entries(values).sort(
        (a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()
      );

      if (first) {
        // console.log("primer valor", arregloOrdenado[0][0]);

        if (arregloOrdenado[0][0] !== "") {
          value = dayjs(arregloOrdenado[0][0]).format("h:mm a");
        } else {
          value = "no data available";
        }
      }
      if (last) {
        // console.log("ultimoValor", arregloOrdenado[0][1]);

        if (arregloOrdenado[0][1] > 0) {
          value = arregloOrdenado[0][1];
        } else {
          value = "0";
        }
      }

      // console.log("ultimoValor", value);
      // console.log("------------");
    } else {
      if (first) {
        value = "no data available";
      }
      if (last) {
        value = "0";
      }
    }

    return value;
  };

  const handleGetKpi = async () => {
    const getKpi: any = await kpiService.getKpiByOrganization(oui);
    const getUserKpi: any = await kpiService.getKpisByUser(userId);

    const kpiWithShowArray: KpiWithShow[] = getKpi.data?.map((kpi: any) => {
      const kpiShow = getUserKpi.find(
        (kpiShows: any) => kpiShows.kpiId === kpi.id
      );
      // console.log("kpi.values", kpi.values);
      return {
        ...kpi,
        show: kpiShow ? kpiShow.show : false,
      };
    });

    setKpis(kpiWithShowArray);
  };

  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const getOptions = (kpi: any) => ({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      width: 100,
      height: 70,
    },
    xAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    legend: { enabled: false },
    series: [
      {
        data: Object.values(kpi.values),
      },
    ],
    plotOptions: { line: { marker: { enabled: false } } },
    tooltip: {
      formatter: function (): any {
        const vm: any = this;
        const keys = Object.keys(kpi.values);

        let text = `<span style="font-size: 10px;">${dayjs(
          parseInt(keys[vm.points[0].x]) + localOffset
        ).format("YYYY-MM-DD hh:mm:ss A")}</span><br/>`;
        vm.points.forEach((point: any) => {
          text += `<b style="font-size: 8px;">${point.y} ${kpi.unit}</b>`;
        });
        return [text];
      },
      positioner: function (
        labelWidth: any,
        labelHeight: any,
        point: any
      ): any {
        const vm: any = this;
        var chart = vm.chart,
          position;

        // Mueve el tooltip a la derecha del gráfico
        position = {
          x: chart.plotLeft + chart.plotWidth + 140, // 10 píxeles a la derecha del gráfico
          y: y - 40, // Centra verticalmente con el punto
        };

        return position;
      },
      style: {
        zIndex: 1000,
      },
      outside: true,
      valueDecimals: 2,
      shared: true,
      xDateFormat: "%Y-%m-%d %H:%M:%S",
    },
  });

  function updateMousePosition(event: any): any {
    // var x = event.clientX;
    y = event.clientY;
  }

  useEffect(() => {
    handleGetKpi();
  }, [context.selectedLocation]);

  useEffect(() => {
    handleGetKpi();
    document.addEventListener("mousemove", updateMousePosition);
  }, []);

  useEffect(() => {
    setContext({ ...context, kpi: pinKpi });
  }, [pinKpi]);

  // console.log("kpis", kpis);

  return (
    <div className="kpi-tab-container">
      <div className="warning ml-2 mb-1">
        <Icon component={UpdateIconSvg} rev={undefined} /> Updated last 12 hours
      </div>
      <div
        className="ml-2 mr-2"
        style={{ overflow: "auto" }}
      >
        {/* <Switch checked={!loading} onChange={onChange} /> */}

        {kpis?.map((kpi: KpiDashboard) => (
          <KpiCard
            kpi={kpi}
            handleGetKpis={handleGetKpi}
            onPinKpi={(value: any) => {
              setPinKpi([...pinKpi, value]);
            }}
          />
        ))}
      </div>
      <div className="flex ml-3 mt-3">
        <Button
          onClick={() => {
            navigate(`/kpi`);
          }}
          className="btn-kpi"
        >
          Add new KPI
        </Button>
        <Button
          onClick={() => {
            navigate(`/kpi?kpiId=${selectedCardIndex}`);
          }}
          disabled={selectedCardIndex > -1 ? false : true}
          className="btn-kpi ml-3"
        >
          Edit KPI
        </Button>
      </div>
    </div>
  );
};
export default KpiDashBoardView;
