import { Button, Form, Input, InputNumber, Modal, Switch, Tag } from "antd";
import React, { useState, useEffect } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import Pin from "./pin";
import { Select } from "antd";
import "./index.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "50%",
};
// Viewport settings
const initialViewState = {
  longitude: -122,
  latitude: 37,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

export default function CreateLocationModal({
  isModalOpen,
  selectLocation,
  onAcept,
  onCancel,
  isEdit,
}) {
  const [form] = Form.useForm();
  const [viewport, setViewport] = useState(initialViewState);
  const [marker, setMarker] = useState({
    latitude: 37,
    longitude: -122,
  });
  const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
  const sewerIcon = require("../../../assets/img/sewerIcon.png");
  const waterIcon = require("../../../assets/img//waterIcon.png");
  const options = [
    {
      value: "SEWER",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Sewer 
          <img style={{ width: "18px", margin: "0 0 1px 5px" }} src={sewerIcon} alt="sewerIcon" />

        </div>
      ),
    },
    {
      value: "WATER",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Water 
          <img style={{ width: "18px", margin: "0 1px 0 5px" }} src={waterIcon} alt="waterIcon" />

        </div>
      ),
    },
  ];
  const onFinish = (values) => {
    const { name, longitude, latitude, zoom, visible, networkType } = values;

    const locationData = {
      name,
      longitude,
      latitude,
      zoom: Math.round(zoom),
      visible,
      ...(isEdit && selectLocation ? { id: selectLocation.id } : {}),
      networkType: networkType === "" ? null : networkType,
    };
    console.log("Data being sent to the service:", locationData);

    onAcept(locationData, selectLocation);
    initForm();
  };

  useEffect(() => {
    initForm();
  }, [selectLocation]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  function initForm() {
    if (selectLocation && selectLocation.value) {
      const location = JSON.parse(selectLocation?.value.toString());
      form.setFieldsValue({
        name: isEdit ? location.name : "",
        latitude: location.latitude,
        longitude: location.longitude,
        zoom: location.zoom,
        visible: location.visible,
        networkType: location.networkType,
      });
      setViewport({
        ...viewport,
        longitude: parseFloat(location.longitude),
        latitude: parseFloat(location.latitude),
        zoom: parseFloat(location.zoom),
      });
      setMarker({
        longitude: parseFloat(location.longitude),
        latitude: parseFloat(location.latitude),
      });
      setCoordinates({
        longitude: parseFloat(location.longitude),
        latitude: parseFloat(location.latitude),
      });
    } else {
      form.setFieldsValue({
        name: "",
        latitude: 0,
        longitude: 0,
        zoom: 11,
        visible: true,
        networkType: "",
      });
      setViewport({
        ...viewport,
        longitude: 0,
        latitude: 0,
        zoom: 11,
      });
      setMarker({
        longitude: 0,
        latitude: 0,
      });
      setCoordinates({
        longitude: 0,
        latitude: 0,
      });
    }
  }

  // useEffect(() => {
  //   form.setFieldsValue({
  //     latitude: 0,
  //     longitude: 0,
  //     zoom: 11,
  //     visible: true,
  //   });
  // }, []);

  useEffect(() => {
    form.setFieldsValue({
      zoom: viewport.zoom,
    });
  }, [viewport]);

  return (
    <Modal
      open={isModalOpen}
      footer={[]}
      closable={false}
      style={{ minWidth: "800px" }}
    >
      <div className="grid" style={{ gridTemplateColumns: "40% 60%" }}>
        <div className="flex column pl-3 pr-3">
          <span
            className="text-title-page pt-2 pb-2"
            style={{ fontSize: "1rem" }}
          >
            {isEdit ? "EDIT LOCATION" : "ADD LOCATION"}
          </span>
          <div className="separator" />
          <span className="pt-2 pb-2">
            Drag the map and position your location
          </span>

          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              status: true,
            }}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Location name"
              rules={[
                {
                  required: true,
                  message: "Please input the location name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="longitude"
              label="Longitude"
              rules={[
                { required: true, message: "Please input the longitude" },
              ]}
            >
              <Tag
                style={{ marginBottom: "5px" }}
                icon={<InfoCircleOutlined style={{ fontWeight: 800 }} />}
                color="processing"
              >
                <span style={{ fontWeight: 700 }}>
                  Only values ​​between -180 and 80
                </span>
              </Tag>
              <InputNumber
                value={coordinates.longitude}
                style={{ width: "100%" }}
                min={-180}
                max={80}
                onChange={(val) => {
                  setCoordinates({ ...coordinates, longitude: val || 0 });
                  form.setFieldValue("longitude", val || 0);
                }}
                onBlur={() => {
                  try {
                    const long = coordinates.longitude;
                    const lat = coordinates.latitude;
                    if (long && lat) {
                      setViewport({
                        ...viewport,
                        longitude: coordinates.longitude,
                      });
                      setMarker({
                        ...marker,
                        longitude: coordinates.longitude,
                      });
                    }
                  } catch (error) {
                    console.error("131", error);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="latitude"
              label="Latitude"
              rules={[{ required: true, message: "Please input the latitude" }]}
            >
              <Tag
                style={{ marginBottom: "5px" }}
                icon={<InfoCircleOutlined style={{ fontWeight: 800 }} />}
                color="processing"
              >
                <span style={{ fontWeight: 700 }}>
                  Only values ​​between -90 and 90
                </span>
              </Tag>
              <InputNumber
                value={coordinates.latitude}
                style={{ width: "100%" }}
                min={-90}
                max={90}
                onChange={(val) => {
                  setCoordinates({ ...coordinates, latitude: val || 0 });
                  form.setFieldValue("latitude", val || 0);
                }}
                onBlur={() => {
                  try {
                    const long = coordinates.longitude;
                    const lat = coordinates.latitude;
                    if (long && lat) {
                      setViewport({
                        ...viewport,
                        latitude: lat,
                      });
                      setMarker({
                        ...marker,
                        latitude: lat,
                      });
                    }
                  } catch (error) {
                    console.error("150", error);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="zoom"
              label="Zoom level"
              rules={[{ required: true, message: "Please input the zoom" }]}
            >
              <InputNumber
                min={1}
                max={20}
                formatter={(value) => {
                  if (value !== undefined) {
                    return `${Math.round(value)}`;
                  }
                  return "";
                }}
                parser={(value) => {
                  const intValue = parseInt(value || "");
                  if (isNaN(intValue)) {
                    return 0;
                  }
                  return intValue;
                }}
                style={{ width: "100%" }}
                onBlur={() => {
                  setViewport({
                    ...viewport,
                    zoom: form.getFieldValue("zoom"),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              name="visible"
              label="Active"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Switch defaultChecked />
            </Form.Item>
            <Form.Item
              name="networkType"
              label="Network Type"
              initialValue={null}
              rules={[{ required: false }]}
            >
              <Select
                style={{ width: 160 }}
                placeholder="Select a Network"
                allowClear
                options={options}
              />
            </Form.Item>

            <div className="separator" />
            <div className="flex pt-3 justify-between">
              <Button
                style={{ width: "85px", height: "40px" }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "85px",
                  height: "40px",
                  backgroundColor: "#00203C",
                  color: "white",
                }}
                htmlType="submit"
              >
                Accept
              </Button>
            </div>
          </Form>
        </div>
        <div className="modal-create-location__map-container flex items-center">
          <>
            <Map
              {...viewport}
              style={{ ...initialStyle }}
              mapboxApiAccessToken={mapboxApiAccessToken}
              mapStyle={mapStyle}
              width="100%"
              height="50%"
              onViewportChange={setViewport}
              onClick={(e) => {
                setMarker({
                  latitude: e.lngLat[1],
                  longitude: e.lngLat[0],
                });
                form.setFieldsValue({
                  latitude: e.lngLat[1],
                  longitude: e.lngLat[0],
                });
                setCoordinates({
                  latitude: e.lngLat[1],
                  longitude: e.lngLat[0],
                });
                console.log(e);
              }}
            >
              {marker && (
                <Marker
                  latitude={marker.latitude}
                  longitude={marker.longitude}
                  offsetLeft={-20}
                  offsetTop={-10}
                  draggable={true}
                  onDragEnd={(e) => {
                    console.log(e);
                    setMarker({
                      latitude: e.lngLat[1],
                      longitude: e.lngLat[0],
                    });
                    form.setFieldsValue({
                      latitude: e.lngLat[1],
                      longitude: e.lngLat[0],
                    });
                    setCoordinates({
                      latitude: e.lngLat[1],
                      longitude: e.lngLat[0],
                    });
                  }}
                >
                  <Pin size={30} />
                </Marker>
              )}
              <NavigationControl />
            </Map>
          </>
        </div>
      </div>
    </Modal>
  );
}
