import NonRevenueWaterRepository from "./NonRevenueWaterRepository";

export default class NonRevenueWaterService {
    private nrwRepository: NonRevenueWaterRepository = new NonRevenueWaterRepository();
  
    async createOrUpdateConfiguration({
      nrwId,
      locationId,
      infrastructure,
      parameterId,
      measurementTypeId,
    }: {
      nrwId: number | null;
      locationId: number;
      infrastructure: number;
      parameterId: number;
      measurementTypeId: number;
    }): Promise<any> {
      return await this.nrwRepository.createOrUpdateConfiguration({
        nrwId,
        locationId,
        infrastructure,
        parameterId,
        measurementTypeId,
      });
    }
  
    async addInvoiceToConfiguration({
      nrwId,
      startDate,
      endDate,
      invoice,
    }: {
      nrwId: number;
      startDate: string;
      endDate: string;
      invoice: number;
    }): Promise<any> {
      return await this.nrwRepository.addInvoiceToConfiguration({
        nrwId,
        startDate,
        endDate,
        invoice,
      });
    }
  
    async deleteInvoiceFromConfiguration(nrwId: number | string): Promise<any> {
      return await this.nrwRepository.deleteInvoiceFromConfiguration(nrwId);
    }
  
    async getConfiguration(nrwId: number): Promise<any> {
      return await this.nrwRepository.getConfiguration(nrwId);
    }
    async calculateInvoice({
      invoiceValue,
    }: {
      invoiceValue: number;
    }): Promise<any> {
      return await this.nrwRepository.calculateInvoice({invoiceValue})
    }

  }
  