import React from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu, Tooltip } from 'antd';
interface ModuleMenuProps {
  handleSelectModule: (moduleId: number) => void; // Handle module selection
}

const ModuleMenu: React.FC<ModuleMenuProps> = ({ handleSelectModule }) => {
  const security = localStorage.getItem("security");
  const userData = security ? JSON.parse(security) : null;

  // Extract modules from userData
  const modules = userData?.modules || [];

  const items: MenuProps['items'] = [
    {
      key: 'sub1',
      icon: <AppstoreOutlined />,
      label: '',
      children: modules.map((module: { id: any; name: any; }) => ({
        key: `module-${module.id}`,
        label: module.name,
      })),
    },
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    const clickedKey = e.key;
    const moduleId = parseInt(clickedKey.split('-')[1], 10);

    handleSelectModule(moduleId);
  };

  return (
    <Tooltip placement="left" title="Applications list">
      <Menu onClick={onClick} items={items} />
    </Tooltip>
  );
};

export default ModuleMenu;
