import React, { useContext, useEffect, useState } from "react";
import MmsService from "./../../domain/Mms/MmsService";
import { Button, message, Tooltip, Upload } from "antd";
import { UploadOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import HydraulicSimulatorService from "../../domain/HydraulicSimulator/HydraulicSimulatorService";
import DashboardContext from "../../layout/DashboardLayout/context";
import { PathLayer, BitmapLayer } from "@deck.gl/layers";
import DeckGL, { IconLayer, FlyToInterpolator } from "deck.gl";
import { useSearchParams } from "react-router-dom";
import { mmsEventsService } from "../../events/mms.subject";

const mmsService = new MmsService();
const hydraulicSimulatorService = new HydraulicSimulatorService();
export default function TreeFiles({ getModelData }) {
  const { value: context } = useContext(DashboardContext);
  const subscriptionMMS$ = mmsEventsService.getSubject();
  const [files, setFiles] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFile, setSelectedFile] = useState(null);
  // const [files, setFiles] = useState([
  //   {
  //     model_id: 1,
  //     alias: "Southbend-WaterSystem-SectorB.inp",
  //     parent: null,
  //   },
  //   {
  //     model_id: 2,
  //     alias: "Southbend-WaterSystem-SectorB-v1.1.inp",
  //     parent: 1,
  //   },
  //   {
  //     model_id: 3,
  //     alias: "Southbend-WaterSystem-SectorB-v1.2.inp",
  //     parent: 1,
  //   },
  //   {
  //     model_id: 4,
  //     alias: "Southbend-WaterSystem-SectorB-v1.2.1.inp",
  //     parent: 3,
  //   },
  //   {
  //     model_id: 5,
  //     alias: "Southbend-WaterSystem-SectorC.inp",
  //     parent: null,
  //   },
  //   {
  //     model_id: 6,
  //     alias: "Southbend-WaterSystem-SectorC-v1.1.inp",
  //     parent: 5,
  //   },
  //   {
  //     model_id: 7,
  //     alias: "Southbend-WaterSystem-SectorC-v1.2.inp",
  //     parent: 5,
  //   },
  //   {
  //     model_id: 8,
  //     alias: "Southbend-WaterSystem-SectorC-v1.2.1.inp",
  //     parent: 7,
  //   },
  // ]);
  //const [viewState, setViewState] = useState(initialViewState);

  const getTreeData = async () => {
    const response = await mmsService.getModelVersions();
    if (response) {
      setFiles(response.model_version_network);
    }
  };

  const renderTree = (parentId) => {
    return files
      .filter((file) => file.parent === parentId)
      .map((file) => (
        <li
          key={file.model_id}
          style={{
            listStyle: "none",
            marginTop: "8px",
            marginBottom: parentId ? "8px" : "30px",
          }}
        >
          <details style={{ cursor: "pointer", width: "fit-content" }}>
            <summary
              style={{
                fontWeight: "normal",
                color: "#2c3e50",
                width: "fit-content",
                padding: "5px",
                ...(file.model_id == selectedFile
                  ? { backgroundColor: "#b9dfea" }
                  : {}),
              }}
            >
              <span
                style={{
                  width: "150px",
                  display: "-webkit-inline-box",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {file.alias}
              </span>
              <Tooltip title="Show model">
                <EyeOutlined
                  className="ml-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearchParams({
                      mmsId: file.model_id,
                      fileName: file.alias,
                    });
                    setSelectedFile(file.model_id);
                    // getModelData(file.model_id, file.alias);
                  }}
                />
              </Tooltip>
              {/* <div className="flex">
                <Tooltip title="Show model">
                  <EyeOutlined
                    className="mr-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      getModelData(file.model_id, file.alias);
                    }}
                  />
                </Tooltip>
              </div> */}
            </summary>
            <ul style={{ paddingLeft: "20px", borderLeft: "1px solid #ccc" }}>
              {renderTree(file.model_id)}
            </ul>
          </details>
        </li>
      ));
  };

  const getMmsId = async (file) => {
    const response = await hydraulicSimulatorService.postInpAssets(
      file.inpdata,
      file.inpName,
      { locationId: context.selectedLocation, city_center_coordinates: "" }
    );
    return response.mmsId;
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".inp",
    showUploadList: false,
    beforeUpload: async (inpFile) => {
      const data = {
        inpName: inpFile.name,
        inpdata: await blobToBase64(inpFile),
      };
      getMmsId(data).then((mmsId) => {
        setSearchParams({
          mmsId,
          fileName: inpFile.name,
        });
        setSelectedFile(mmsId);
        getTreeData();
        // getModelData(mmsId, inpFile.name);
      });
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const blobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result;
          resolve(result.split(",")[1]);
        } else {
          resolve("");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    getTreeData();
    subscriptionMMS$.subscribe((event) => {
      if (event.type == "handleGetTreeData") {
        getTreeData();
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (searchParams.get("mmsId") && searchParams.get("fileName")) {
      getModelData(searchParams.get("mmsId"), searchParams.get("fileName"));
      setSelectedFile(searchParams.get("mmsId"));
    }
  }, [searchParams.get("mmsId"), searchParams.get("fileName")]);

  return (
    <div
      style={{
        width: "250px",
        height: "calc(100vh - 80px)",
        backgroundColor: "white",
        top: "0px",
        left: "0px",
        padding: "10px",
        overflow: "auto",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4 style={{ marginBottom: "10px", textAlign: "right" }}>
        Model Managment System
      </h4>
      <div style={{ textAlign: "right", marginBottom: "30px" }}>
        <Upload {...uploadProps} style={{ textAlign: "right" }}>
          <Button type="primary" icon={<UploadOutlined />}>
            Add new Model
          </Button>
        </Upload>
      </div>
      <div>{renderTree(null)}</div>
    </div>
  );
}
