import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import Simulations from "../../components/Simulations/Simulations";
import InteractiveSimulations from "../../components/Simulations/InteractiveSimulations";

const SimulationsView: React.FC = () => {
  return (
    <div>
      <div className="text-title-page mb-3">Simulation Status</div>
      <div className="text-subtitle-page">Check the status of the forecast simulation engine</div>
      <Tabs
        className="mt-3 ml-4"
        defaultActiveKey="1"
        items={[
          {
            label: <span className="titulo-tabs">INTERACTIVE</span>,
            key: "1",
            children: <InteractiveSimulations />,
          },
          {
            label: <span className="titulo-tabs">SCHEDULED</span>,
            key: "2",
            children: <Simulations />,
          },
        ]}
      />
    </div>
  );
};

export default SimulationsView;
