import React, { useEffect, useState } from "react";
import { TreeSelect } from 'antd';
import LocationService from "../../domain/Location/LocationService";
const sewerIcon = require("../../assets/img/sewerIcon.png");
const waterIcon = require("../../assets/img/waterIcon.png"); 
const locationService = new LocationService();

export default function SelectLocationsAntd({
  aditionalStyle,
  handleClickSelect,
  size = 'middle',
}) {
  const [locations, setLocations] = useState([]);
  const [dataSelect, setDataSelect] = useState(null);
  const [dataPicker, setDataPicker] = useState([]);
  const [itemsDisabled, setItemsDisabled] = useState([]);

  function handleGetLocations() {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }

  function mapLocations(locations) {
    return locations.map((location) => {
      let icon = '';
      if (location.networkType === 'SEWER') {
        console.log(sewerIcon);
        icon = <img style={{ width: "18px", marginBottom: "3px" }} src={sewerIcon} alt="sewerIcon" />;
      } else if (location.networkType === 'WATER') {
        console.log(waterIcon);
        icon = <img style={{ width: "18px", marginBottom: "3px" }} src={waterIcon} alt="waterIcon" />;
      }
  
      if (!location.canShowLocation) {
        setItemsDisabled((prev) => [...prev, location.locationId]);
      }
  
      return {
        label: (
          <span>
            {icon} {location.locationName}
          </span>
        ),
        icon: location.networkType,  //prueba
        value: location.locationId,
        locationName: location.locationName,
        disabled: !location.canShowLocation,
        ...(location.hasChild ? { children: mapLocations(location.childLocations) } : {}),
      };
    });
  }
  

  useEffect(() => {
    handleGetLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      const datas = mapLocations(locations);
      console.log("Data picker generated:", datas);
      setDataPicker(datas);

      const defaultLocation = locations.find(location => location.defaultSelected);
      if (defaultLocation) {
        setDataSelect(defaultLocation.locationId);
        if (handleClickSelect) {
          handleClickSelect(defaultLocation.locationId);
        }
      }
    }
  }, [locations]);

  const handleChange = (value) => {
    console.log("Selected value:", value);
    setDataSelect(value);
    if (handleClickSelect) {
      handleClickSelect(value);
    }
  };

  const onPopupScroll = (e) => {
    console.log('onPopupScroll', e);
  };

  const filterTreeNode = (inputValue, treeNode) => {
    return treeNode.locationName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <>
      {locations.length > 0 ? (
        <TreeSelect
          showSearch
          style={{ width: 200 }}
          value={dataSelect}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', ...aditionalStyle }}
          placeholder="Search a Location"
          allowClear
          treeDefaultExpandAll
          onChange={handleChange}
          treeData={dataPicker}
          onPopupScroll={onPopupScroll}
          filterTreeNode={filterTreeNode}
        />
      ) : (
        ""
      )}
    </>
  );
}
