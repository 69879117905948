import { apiNl, apiController, restartApiCotroller } from "./apiNl";

const getReadingsFromDevice = async (parameterId, source, dateFrom, dateTo, valueInterval=5, typeInterval="minutes") => {
  try {
    restartApiCotroller();
    if (!parameterId) return;
    const response = await apiNl.get(`historical/${parameterId}/readings`, {
      params: {
        dateFrom,
        dateTo,
        source,
        typeInterval,
        valueInterval
      },
      signal: apiController.signal,
    });
    return {
      ...response.data,
      originalParameterId: parameterId,
      originalSourceSerialNumber: source,
      sourceId: response.data.measurementToolId,
    };
  } catch (error) {
    console.error(error);
  }
};

export { getReadingsFromDevice };
