import axios from "axios";
import { msalInstance, store } from "../index";
// import { useDispatch } from "react-redux";
import { setShowLoader } from "../redux/loaderDuck";
import { showErrorModal, showExpiredSessionModal } from "../redux/modalAlertDuck";

let apiController = new AbortController();
const apiNl = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

apiNl.defaults.headers.common = {
  "Content-Encoding": "gzip",
};

apiNl.interceptors.request.use((config) => {
  const account = msalInstance.getAllAccounts()[0];
  console.log("Refresh token...");
  return msalInstance
    .acquireTokenSilent({
      scopes: ["7d611a8f-71d3-4bb5-8254-6559b1769661"],
      account: account,
    })
    .then((response) => {
      //TODO: Remove references to localstorage, instead use token from msal directly
      localStorage.setItem("accessToken", response.accessToken); 
      const accessToken = response.accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    })
    .catch((error) => {
      store.dispatch(showExpiredSessionModal(true));
      console.error("Error acquiring token:", error);
      return Promise.reject(error);
    });
});

apiNl.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      store.dispatch(showExpiredSessionModal(true));
    }
    return response;
  },
  (error) => {
    if (error.code !== "ERR_CANCELED") {
      store.dispatch(
        showErrorModal(error.message, error.response?.data?.error)
      );
    }
    //Error managment
    return Promise.reject(error);
  }
);

const restartApiCotroller = () => {
  apiController = new AbortController();
};

export { apiNl, apiController, restartApiCotroller };
