import React, { FC, useState, useEffect, useRef } from "react";
import {
  Switch,
  Badge,
  Input,
  Select,
  Table,
  Tag,
  Pagination,
  Tooltip,
} from "antd";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getParameters } from "../../services/parameters";
import { apiController } from "../../services/api";
import { useParams, useNavigate } from "react-router-dom";
import LocationService from "../../domain/Location/LocationService";
import Locations from "../../components/Locations/Locations/Locations";
import ParameterService from "../../domain/Parameter/ParameterService";
import "./index.scss";
import SelectLocation from "../../basicComponents/Selects/SelectLocation";
import { useSearchParams } from "react-router-dom";
import SelectTypeInfrastructure from "../../basicComponents/Selects/SelectTypeInfrastructure";

const locationService = new LocationService();

const parameterService = new ParameterService();

interface DataType {
  infraId: number;
  infraName: string;
  infraType: string;
  infraTypeId: number;
  location: string;
  activeParams: number;
  inactiveParams: number;
  inUse: boolean;
}

/**
 * @author
 * @function @ParametersView
 **/

const InfrastructuresView: FC = () => {
  const userId = localStorage.getItem("userId") || "";
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState<any>(null);
  const [inUse, setInUse] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [locations, setLocations] = useState<any>(null);
  const [parameters, setParameters] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
  });
  const selectLocationRef = useRef<any>(null);
  const selectTypeInfrastructureRef = useRef<any>(null);
  const [selectedInfraType, setselectedInfraType] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    handleUpdateParameters();
    return () => {
      apiController.abort();
    };
  }, [pagination.page]);

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
    handleUpdateParameters();
    return () => {
      apiController.abort();
    };
  }, [name, inUse, searchParams.get("locationId"), selectedInfraType]);

  useEffect(() => {
    if (searchParams.get("locationId") && selectLocationRef.current) {
      const locationParam = searchParams.get("locationId");
      selectLocationRef.current.setValue(locationParam);
    }
    if (
      searchParams.get("typeInfrastructure") &&
      selectTypeInfrastructureRef.current
    ) {
      const typeInfrastructureParam: string =
        searchParams.get("typeInfrastructure") || "";
      selectTypeInfrastructureRef.current.setValue(
        parseInt(typeInfrastructureParam)
      );
    }
  }, [searchParams]);

  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }, []);

  const handleUpdateParameters = async () => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getParameters(organizationId, {
      page: pagination.page,
      size: pagination.pageSize,
      name,
      inUse,
      location: searchParams.get("locationId"),
      infraTypeId: selectedInfraType,
    })
      .then((response) => {
        if (response) {
          setPagination({
            ...pagination,
            totalPages: response.totalRecords,
          });
          setParameters(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Infrastructure Name",
      dataIndex: "infraName",
      key: "infraName",
      width: 200,
    },
    {
      title: "Type",
      dataIndex: "infraType",
      key: "infraType",
      width: 200,
      render: (_, { infraType }) => {
        const fontColor = "#232B36";
        let color = "#FFEECB";
        // if (infraType === "IN PROGRESS") {
        //   color = "#FFEECB";
        // }
        // if (infraType === "FAILED") {
        //   color = "#FFD3D3";
        // }
        return (
          infraType && (
            <Tag color={color} style={{ color: fontColor }}>
              {infraType}
            </Tag>
          )
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 200,
    },
    {
      title: "Active Parameters",
      key: "active",
      width: 200,
      render: (_, { activeParams }) => (
        <>
          <span className="table-data-gis">
            <Badge status="success" className="mr-1" />
            {activeParams} active
          </span>
        </>
      ),
    },
    {
      title: "Inactive Parameters",
      key: "inactive",
      width: 200,
      render: (_, { inactiveParams }) => (
        <>
          <span className="table-data-gis" style={{ color: "#C4CCD5" }}>
            <Badge status="default" className="mr-1" />
            {inactiveParams} inactive
          </span>
        </>
      ),
    },
    {
      title: (
        <div>
          <Tooltip title="For an active infrastructure, it must have an active source and parameter">
            <span>In Use</span>
          </Tooltip>
        </div>
      ),
      key: "inUse",
      width: 60,
      render: (record) => (
        <>
          <Switch
            checked={record.inUse}
            onClick={async (checked) => {
              try {
                if (checked) {
                  await parameterService.enableInUseParameter(record);
                } else {
                  await parameterService.disableInuseParameter(record);
                }
                const newData: any = [...parameters];
                const index = newData.findIndex(
                  (item: any) => record.infraId === item.infraId
                );
                newData[index].inUse = checked;
                setParameters(newData);
              } catch (error) {
                console.error(error);
              }
            }}
          />
        </>
      ),
    },
    {
      title: "",
      width: 200,
      key: "options",
      render: (_, { activeParams, infraId }) => (
        <>
          <span
            onClick={() => {
              navigate(`parameter/${infraId}`);
            }}
            className="table-data-gis add-parameter-view-info"
          >
            {activeParams > 0 ? "View info" : "Add parameter"}
          </span>
        </>
      ),
    },
  ];

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    // console.log(page);
    setPagination({ ...pagination, page: page });
  };

  const handleClickSelect = (value: any) => {
    // record.location = value;
    // form.setFieldsValue({ location: value });
    // console.log("value", value);
    setLocation(value);
  };

  return (
    <div>
      <div className="text-title-page">Infrastructure</div>
      <div className="text-subtitle-page">
      Your network infrastructure (from GIS) is listed below and ready for action. Click on ‘View info’ to add and edit parameters like depth and flow to visualize whats going on now and in the future! Then add 'sources'—physical or virtual sensors data—ensuring you're always in the know. Dive in and Turn On the Lights!</div>
      <div className="mt-5 flex">
        <Input
          style={{ width: 200 }}
          placeholder="Search"
          prefix={
            <ClockCircleOutlined style={{ color: "#078CB3" }} rev={undefined} />
          }
          onPressEnter={(e) => {
            if (e.target) {
              const element = e.currentTarget as HTMLInputElement;
              setName(element.value);
            }
          }}
          onChange={(e) => {
            if (e.target.value == "") {
              setName(null);
            }
          }}
          allowClear
        />
        <div style={{ marginLeft: 30 }}>
          {/* <Locations
            locations={locations}
            handleClickSelect={handleClickSelect}
            defaultValue={false}
          /> */}
          <SelectLocation
            ref={selectLocationRef}
            userId={userId}
            onSelect={(value: any) => {
              setLocation(value);
              if (value) {
                setSearchParams({ locationId: value });
              } else {
                setSearchParams({});
              }
            }}
          />
        </div>
        <div style={{ marginLeft: 30 }}>
          <SelectTypeInfrastructure
            ref={selectTypeInfrastructureRef}
            onSelect={(value: any) => {
              if (value) {
                setselectedInfraType(value);
              } else {
                setselectedInfraType(null);
              }
            }}
          />
        </div>

        <Select
          style={{ marginLeft: 30, width: 140 }}
          placeholder="In use"
          optionFilterProp="children"
          options={[
            {
              value: "true",
              label: "True",
            },
            {
              value: "false",
              label: "False",
            },
          ]}
          onChange={(value) => {
            setInUse(value);
          }}
          allowClear
        />
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={parameters}
          pagination={false}
          style={{
            maxHeight: "calc(100vh - 320px)",
            overflowX: "auto",
          }}
          // scroll={{ x: 1000, y: 400 }}
          rowKey="infraId"
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalPages}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default InfrastructuresView;
