import { Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useInfrastructure from "../../hooks/useInfrastructure";

const SelectInfrastructure = forwardRef(
  (
    {
      location = null,
      organizationId = null,
      name = null,
      onSelect,
      labelInValue = false,
      mode,
    }: {
      location?: number | null;
      organizationId?: number | null;
      name?: string | null;
      onSelect: Function;
      labelInValue?: boolean;
      mode: "ByLocation" | "ByOrganization";
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const { infrastructures, setParams } = useInfrastructure({
      name,
      mode,
      location,
      organizationId,
      size: 50,
    });
    const selectRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
      cleanValues: () => {
        setSelectedValue(null);
        setParams((params: any) => {
          return { ...params, name: null };
        });
      },
    }));

    useEffect(() => {
      setParams((old: any) => {
        return { ...old, location };
      });
    }, [location]);

    return (
      <Select
        ref={selectRef}
        value={selectedValue}
        placeholder="Select Infrastructure"
        showSearch
        labelInValue
        defaultActiveFirstOption={false}
        onSearch={(newValue: string) => {
          setParams((params: any) => {
            return { ...params, name: newValue };
          });
        }}
        id="parameter"
        onChange={(val: object) => {
          setSelectedValue(val);
          onSelect(val);
        }}
        allowClear
        onClear={() => {
          setSelectedValue(null);
          onSelect(null);
          setParams((params: any) => {
            return { ...params, name: null };
          });
          selectRef?.current?.focus();
        }}
        // onClick={() => {
        //   if (selectedValue) {
        //     setParams((params: any) => {
        //       return { ...params, name: null };
        //     });
        //   }
        // }}
        onFocus={() => {
          if (selectedValue) {
            setParams((params: any) => {
              return { ...params, name: null };
            });
          }
        }}
        filterOption={(input, option: any) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "100%" }}
        options={(infrastructures || []).map((i) => ({
          value: i.id,
          label: i.name,
        }))}
      />
    );
  }
);

export default SelectInfrastructure;
