import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Pagination,
  Table,
  Input,
  Select,
  Tooltip,
  notification,
} from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import UserService from "../../domain/Users/UserService";
import PencilSvg from "../../assets/svg/PencilSvg";
import TrashSvg from "../../assets/svg/TrashSvg";
import Icon, {
  PlusCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import ModalUser from "./ModalUser";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import dayjs from "dayjs";

const userService = new UserService();

interface DataType {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  roleId: string;
  inUse: boolean;
  timeZone: string;
  organizationId: string;
  organization: string;
  createdAt: string;
  createdBy: string;
  lastAccess: string;
}

/**
 * @author
 * @function @UserView
 **/

const UserView: FC = () => {
  const [name, setName] = useState<any>(null);
  const [roles, setRoles] = useState<any>(null);
  const [modules, setModules] = useState<any>(null);
  const [organizations, setOrganizations] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const [organization, setOrganization] = useState<any>(null);
  const [users, setUsers] = useState<any | null>([]);
  const [addModalUser, setAddModalUser] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AllUser | null>(null);
  const [isNewUser, setIsNewUser] = useState<boolean>(true);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
  });
  const userRole = JSON.parse(localStorage.getItem("security") || "{}").role;

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 250,
      render: (_, record) => <>{`${record.firstName} ${record.lastName}`}</>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "Time Zone",
      dataIndex: "timeZone",
      key: "timeZone",
      width: 200,
      render: (_, { timeZone }) =>
        `UTC${timeZone.indexOf("-") == 0 ? timeZone : "+" + timeZone}:00`,
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      width: 200,
    },
    {
      title: "Enroll",
      key: "enroll",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span>
            {" "}
            {`${dayjs(record.createdAt).format("YYYY-MM-DD")}`} at{" "}
            {`${dayjs(record.createdAt).format("hh:mm a")}`}{" "}
          </span>
          <span style={{ color: "#74788D", fontSize: "10px" }}>
            by {`${record.createdBy}`}
          </span>
        </div>
      ),
    },
    // {
    //   title: "Enroll by",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   width: 200,
    // },
    {
      title: "Last access",
      dataIndex: "lastAccess",
      key: "lastAccess",
      render: (_, record) => (
        <div className="flex column">
          <span> {`${dayjs(record.lastAccess).format("YYYY-MM-DD")}`} </span>
          <span style={{ color: "#74788D", fontSize: "10px" }}>
            {`${dayjs(record.lastAccess).format("hh:mm a")}`}
          </span>
        </div>
      ),
      width: 200,
    },
    {
      title: "Actions",
      dataIndex: "s",
      key: "id",
      width: 150,
      fixed: "right",
      render: (_, record) => (
        <>
          <div>
            <Tooltip title="Edit">
              <Button
                className="mr-1"
                icon={<PencilSvg />}
                onClick={() => {
                  setSelectedUser(record);
                  setAddModalUser(true);
                  setIsNewUser(false);
                }}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                icon={<TrashSvg />}
                onClick={() => {
                  setSelectedUser(record);
                  setShowConfirmDelete(true);
                }}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </Tooltip>

            {/* <Button
              icon={<EnvironmentOutlined rev={undefined} />}
              onClick={() => {
                // setShowConfirmDelete(true);
              }}
              style={{
                border: "none",
                cursor: "pointer",
              }}
            ></Button> */}
          </div>
        </>
      ),
    },
  ];

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    setPagination({ ...pagination, page: page });
  };

  const handleGetUsers = () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const res = userService.getAllUsers(
      oui,
      pagination.page,
      pagination.pageSize,
      name,
      role
    );

    res
      .then((response) => {
        setPagination({
          ...pagination,
          totalPages: response.totalRecords,
        });
        setUsers(response.responseContentDTO);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleGetUsers();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({ ...pagination, page: 1 });
    if (prevPage == 1) {
      handleGetUsers();
    }
  }, [name, role, organization]);

  useEffect(() => {
    if (!addModalUser) {
      handleGetUsers();
      setSelectedUser(null);
    }
  }, [addModalUser]);

  useEffect(() => {
    const resRole = userService.getRole();
    resRole
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        console.error(error);
      });

    const resModules = userService.getModules();
    resModules
      .then((response) => {
        setModules(response);
      })
      .catch((error) => {
        console.error(error);
      });

    if (userRole == "SuperAdmin") {
      const resOrganizations = userService.getOrganizations();
      resOrganizations
        .then((response) => {
          setOrganizations(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleOk = () => {
    setAddModalUser(false);
  };

  const handleCancel = () => {
    setAddModalUser(false);
  };

  return (
    <div>
      <div className="text-title-page">User Manager</div>
      <div className="flex justify-between" style={{ marginTop: "2rem" }}>
        <div>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <ClockCircleOutlined
                style={{ color: "#078CB3" }}
                rev={undefined}
              />
            }
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                setName(element.value);
              }
            }}
            onChange={(e) => {
              if (!e.target.value || e.target.value == "") {
                setName(null);
              }
            }}
            allowClear
          />
          <Select
            placeholder={"Role"}
            style={{ marginLeft: 30, width: 140 }}
            optionFilterProp="children"
            options={roles}
            onChange={(value) => {
              setRole(value);
            }}
            allowClear
          />
          {userRole == "SuperAdmin" ? (
            <Select
              placeholder={"Organization"}
              style={{ marginLeft: 30, width: 140 }}
              optionFilterProp="children"
              options={organizations}
              onChange={(value) => {
                setOrganization(value);
              }}
              allowClear
            />
          ) : (
            ""
          )}
        </div>

        <div>
          <Button
            type="primary"
            shape="round"
            className="flex items-center self-center"
            style={{
              backgroundColor: "#023E65",
              color: "white",
              width: "150px",
              height: "40px",
            }}
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              setAddModalUser(true);
              setIsNewUser(true);
            }}
          >
            New user
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={users}
          pagination={false}
          style={{
            maxHeight: "calc(100vh - 300px)",
            overflowX: "auto",
          }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalPages}
          showSizeChanger={false}
        />
      </div>
      <ModalUser
        addModalUser={addModalUser}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setAddModalUser={setAddModalUser}
        roles={roles}
        modules={modules}
        organizations={organizations}
        handleGetUsers={handleGetUsers}
        isNewUser={isNewUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <ConfirmationModal
        title="Delete User?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={() => {
          if (selectedUser) {
            userService.deleteUser(selectedUser).then(() => {
              notification.success({
                message: "Successful",
                description: "User deleted successfully",
              });
            });
            handleGetUsers();
            setShowConfirmDelete(false);
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
};

export default UserView;
