let INCREMENT = "INCREMENT";
let DECREMENT = "DECREMENT";
let CHANGE_TEXT = "CHANGE_TEXT";
let RESTART = "RESTART";

export default function reducer(
  state = { currentStack: 0, text: "Loading..." },
  action
) {
  switch (action.type) {
    case INCREMENT:
      return { ...state, currentStack: state.currentStack + 1 };
    case DECREMENT:
      return { ...state, currentStack: state.currentStack - 1 };
    case CHANGE_TEXT:
      return { ...state, text: action.payload };
    case RESTART:
      return { ...state, currentStack: 0 };
    default:
      return state;
  }
}

export let setShowLoader =
  (isLoading = true) =>
    async (dispatch, getState) => {
      if (isLoading) {
        dispatch({
          type: INCREMENT,
        });
      } else {
        dispatch({
          type: DECREMENT,
        });
      }
    };

export let setLoaderText =
  (text = "Loading...") =>
    async (dispatch, getState) => {
      dispatch({
        type: CHANGE_TEXT,
        payload: text,
      });
    };

export let setRestartLoader =
  () =>
    async (dispatch, getState) => {
      dispatch({
        type: RESTART,
      });
    };
