import { api } from "../../services/api";

export default class MmsRepository {
    async getModelVersions() {
      try {
        const response = await api.get(`mms/model/versions`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  }
  