import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Input,
  Pagination,
  Select,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import Icon, {
  PlusCircleOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import KpiService from "../../domain/Kpi/KpiService";
import { ColumnsType } from "antd/es/table";
import CreateKpiModal from "../../components/Kpi/CreateKpiModal";
import { useLocation } from "react-router-dom";
import Locations from "../../components/Locations/Locations/Locations";
import LocationService from "../../domain/Location/LocationService";
import UserService from "../../domain/Users/UserService";
import dayjs from "dayjs";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const kpiService = new KpiService();
const locationService = new LocationService();
const userService = new UserService();
type Props = {};

export default function KpiView() {
  const query = useQuery();
  const [name, setName] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRecords: 0,
  });
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [selectKpi, setSelectKpi] = useState<any>(null);
  const [kpiList, setKpiList] = useState<Kpi[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectLocation, setSelectLocation] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [users, setUsers] = useState<any[]>([]);
  const [inUseQuery, setinUseQuery] = useState(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);

  const columns: ColumnsType<Kpi> = [
    {
      title: "KPI Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span>{`${record.location}`}</span>
          <span
            style={{ color: "#74788D", fontSize: "10px" }}
          >{`${record.country}`}</span>
        </div>
      ),
    },
    {
      title: "Infrastructure",
      dataIndex: "infrastructure",
      key: "infrastructure",
      width: 200,
    },
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span>{`${record.createdBy}`}</span>
          <span style={{ color: "#74788D", fontSize: "10px" }}>{`${dayjs(
            record.createdAt
          ).format("YYYY/MM/DD [at] hh:mm a")}`}</span>
        </div>
      ),
    },
    {
      title: "In Use",
      width: 50,
      render: (_, record) => (
        <div className="flex">
          <Switch
            defaultChecked={record.enabled}
            onChange={(checked: boolean) => {
              const copy = { ...record };
              copy.enabled = checked;
              handleChangeEnabledKpi(record.id, checked);
            }}
            className="mr-3"
          />
        </div>
      ),
    },
    {
      title: "Actions",
      width: 100,
      render: (_, record) => (
        <div className="flex">
          <Tooltip title="Edit">
            <EditOutlined
              className="mr-1"
              style={{ fontSize: "1.5rem" }}
              onClick={() => {
                setSelectKpi(record.id);
                setShowModalCreation(true);
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              className="mr-1"
              style={{ fontSize: "1.5rem" }}
              onClick={() => {
                setSelectKpi(record);
                setshowConfirmDelete(true);
              }}
              rev={undefined}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function handleDeleteKpi(kpiId: number) {
    kpiService.deleteKpi(kpiId).then(() => {
      notification.success({
        message: "Successful",
        description: "Kpi deleted successfully",
      });
      setshowConfirmDelete(false);
      handleGetKpi();
    });
  }

  const handleGetKpi = useCallback(async () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const response = await kpiService.getKpiByOrganization(
      oui,
      pagination.page,
      pagination.pageSize,
      name,
      inUseQuery,
      selectedUserId,
      selectLocation
    );
    if (response) {
      console.log("🚀 ~ handleGetKpi ~ response:", response);
      setKpiList(response.data);
      setPagination({ ...pagination, totalRecords: response.totalRecords });
    }
  }, [
    localStorage,
    pagination,
    name,
    inUseQuery,
    selectedUserId,
    selectLocation,
  ]);

  async function handleChangeEnabledKpi(
    kpiId: string | number,
    enabled: boolean
  ) {
    const reponse = await kpiService.changeEnableKpi(kpiId, enabled);
  }

  const handleGetUsers = () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const res = userService.getAllUsers(oui, 1, 1000, null, null);

    res
      .then((response) => {
        setUsers(response.responseContentDTO);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangePagination = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  const handleClickSelectLocation = (value: any) => {
    console.log(
      "🚀 ~ file: index.tsx:156 ~ handleClickSelectLocation ~ value:",
      value
    );
    setSelectLocation(value);
  };

  useEffect(() => {
    handleGetUsers();
    if (query.get("kpiId")) {
      setSelectKpi(query.get("kpiId"));
      setShowModalCreation(true);
    }
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }, []);

  useEffect(() => {
    handleGetKpi();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({ ...pagination, page: 1 });
    if (prevPage == 1) {
      handleGetKpi();
    }
  }, [selectLocation, selectedUserId, inUseQuery]);

  useEffect(() => {
    if (name == null) {
      const prevPage = pagination.page;
      setPagination({ ...pagination, page: 1 });
      if (prevPage == 1) {
        handleGetKpi();
      }
    }
  }, [name]);

  return (
    <>
      {kpiList.length > 0 ||
      (name && name?.length > 0) ||
      selectedUserId ||
      selectLocation ||
      inUseQuery != null ? (
        <div className="px-4">
          <div className="text-title-page mb-4">KPIs</div>
          <div className="flex justify-between mb-3">
            <div className="flex">
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                prefix={
                  <SearchOutlined
                    style={{ color: "#078CB3" }}
                    rev={undefined}
                  />
                }
                onChange={(e) => {
                  if (e.target.value == "") {
                    setName(null);
                  } else {
                    setName(e.target.value);
                  }
                }}
                onPressEnter={(e) => {
                  const prevPage = pagination.page;
                  setPagination({ ...pagination, page: 1 });
                  if (prevPage == 1) {
                    handleGetKpi();
                  }
                }}
                allowClear
                className="mr-2"
              />
              <div style={{ width: "200px" }} className="mr-2">
                <Locations
                  locations={locations}
                  handleClickSelect={handleClickSelectLocation}
                  cleanable={true}
                />
              </div>
              <div style={{ width: "200px" }} className="mr-2">
                <Select
                  id="userId"
                  value={selectedUserId}
                  notFoundContent={null}
                  onChange={(val) => {
                    setSelectedUserId(val);
                  }}
                  style={{ width: "200px" }}
                  showSearch
                  options={(users || []).map((u) => ({
                    value: u.userId,
                    label: `${u.firstName} ${u.lastName}`,
                  }))}
                  placeholder="User"
                  allowClear
                />
              </div>
              <div style={{ width: "200px" }}>
                <Select
                  id="inUse"
                  value={inUseQuery}
                  notFoundContent={null}
                  onChange={(val) => {
                    setinUseQuery(val);
                  }}
                  style={{ minWidth: "200px" }}
                  showSearch
                  options={[
                    { value: true, label: "Active" },
                    { value: false, label: "Inactive" },
                  ]}
                  placeholder="In Use"
                  allowClear
                />
              </div>
            </div>
            <Button
              icon={<PlusCircleOutlined rev={undefined} />}
              shape="round"
              className="flex items-center self-center"
              style={{
                color: "#023E65",
                borderColor: "#023E65",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setShowModalCreation(true);
              }}
            >
              Add Kpi
            </Button>
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={kpiList}
              rowKey={(record) => record.id}
              pagination={false}
              bordered
              style={{
                maxHeight: "calc(100vh - 290px)",
                overflowX: "auto",
              }}
            />
            <Pagination
              style={{ marginTop: "2rem" }}
              onChange={onChangePagination}
              current={pagination.page}
              total={pagination.totalRecords}
              showSizeChanger={false}
              pageSize={pagination.pageSize}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex flex-column self-center justify-center text-center"
          style={{ width: "100%", marginTop: "25%" }}
        >
          <span className="text-title-page" style={{ marginBottom: "10px" }}>
            No KPIs yet!
          </span>
          <span className="text-subtitle-page" style={{ marginBottom: "10px" }}>
            Looks that you haven’t create any KPI, no worries. Click to start
          </span>
          <div className="self-center">
            <Button
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined rev={undefined} />}
              className="flex items-center ml-auto"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "120px",
                height: "40px",
              }}
              onClick={() => {
                setShowModalCreation(true);
              }}
            >
              Create KPI
            </Button>
          </div>
        </div>
      )}
      {showModalCreation && (
        <CreateKpiModal
          isModalOpen={showModalCreation}
          onSuccess={() => {
            setShowModalCreation(false);
            handleGetKpi();
          }}
          onCancel={() => {
            setSelectKpi(null);
            setShowModalCreation(false);
          }}
          selectedKpi={selectKpi}
        />
      )}
      <ConfirmationModal
        title="Delete KPI?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={() => {
          if (selectKpi) {
            handleDeleteKpi(selectKpi.id);
          }
        }}
        oncancel={() => {
          setshowConfirmDelete(false);
        }}
      />
    </>
  );
}
