
import { api } from "../../services/api";
export interface Notification {
  id: string;
  title: string;
  description: string;
  date: string;
}

export default class NotificationRepository {
  async getRecentNotifications(): Promise<Notification[]> {
    const response = await api.get(`/notifications/issues/recent`);
    return response.data;
  }

}
