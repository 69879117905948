import React, { useContext, useEffect } from "react";
import { Select, Tag } from "antd";
import Sider from "antd/es/layout/Sider";
import Icon, { CloseOutlined } from "@ant-design/icons";
import OverloadSvg from "../../assets/svg/OverloadSvg";
import DashboardContext from "./context";
import { connect, useDispatch } from "react-redux";
import { getActivitiesAction } from "../../domain/Notifications/NotificationService";
import { AnyAction } from "redux";
import dayjs from "dayjs";

type Props = {
  activities: any[];
};

function Notifications({ activities }: Props) {
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActivitiesAction() as unknown as AnyAction);
  }, [dispatch]);

  function getTimeAgo(time: string) {
    const hours = dayjs().diff(dayjs(time), "hour");
    if (hours > 24) {
      return `${dayjs().diff(dayjs(time), "day")} days ago`;
    } else {
      return `${hours} hours ago`;
    }
  }

  return (
    <Sider
      style={{
        backgroundColor: "white",
      }}
      width={270}
    >
      <div className="flex column" style={{ padding: "20px" }}>
        <CloseOutlined
          className="mb-auto"
          style={{
            fontSize: "16px",
            cursor: "pointer",
            position: "absolute",
            right: "20px",
            top: "23px",
            fontWeight: "bolder",
          }}
          onClick={() => {
            setContext({
              ...context,
              showNotifications: false,
            });
          }}
          rev={undefined}
        />
        <span className="mb-2" style={{ color: "#023E65", fontSize: "16px" }}>
          Notifications
        </span>
        <Select options={[]} placeholder={"Status"} className="mb-2" />
        <span className="mb-1">Today</span>
        <div
          className="flex column items-center"
          style={{
            width: "230px",
            padding: "0px 5px",
            height: " calc(100vh - 170px)",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <div
            className="flex items-center mb-2"
            style={{
              border: "1px solid #74788D",
              borderRadius: "8px",
              width: "100%",
              padding: "10px",
            }}
            key={"-1"}
          >
            <div
              style={{
                width: "48px",
                height: "48px",
                backgroundColor: "#F7F9FC",
                borderRadius: "50%",
              }}
              className="flex mr-2 items-center justify-center"
            >
              <Icon
                component={OverloadSvg}
                style={{
                  width: "40px",
                  height: "40px",
                }}
                rev={undefined}
              />
            </div>
            <div className="flex column">
              <Tag color="red" style={{borderRadius: "10px"}}>Recommended Strategy</Tag>
              <span style={{ color: "#023E65" }}>LARGE STORM STRATEGY</span>
              <span
                style={{
                  fontSize: "10px",
                  color: "#232B36",
                }}
              >
                A-Leg
              </span>
              {/* <span
                style={{
                  fontSize: "10px",
                  color: "#232B36",
                }}
              >
                {activity.infrastructureName}
              </span> */}
              {/* <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "150px",
                  display: "inline-block",
                  fontSize: "10px",
                  color: "#232B36",
                  fontWeight: "bold",
                }}
              >
                {activity.parameterName}
              </span> */}
              <span
                style={{
                  fontSize: "9px",
                  color: "#74788D",
                }}
              >
                4 days ago
              </span>
            </div>
          </div>
          {activities
            .map((activity, index) => (
              <div
                className="flex items-center mb-2"
                style={{
                  border: "1px solid #74788D",
                  borderRadius: "8px",
                  width: "100%",
                  padding: "10px",
                }}
                key={index}
              >
                <div
                  style={{
                    width: "48px",
                    height: "48px",
                    backgroundColor: "#F7F9FC",
                    borderRadius: "50%",
                  }}
                  className="flex mr-2 items-center justify-center"
                >
                  <Icon
                    component={OverloadSvg}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    rev={undefined}
                  />
                </div>
                <div className="flex column">
                  {/* <Tag
                    color="red"
                    style={{
                      width: "fit-content",
                      borderRadius: "10px",
                      fontSize: "8px",
                    }}
                  >
                    {activity.status}
                  </Tag> */}
                  <span style={{ color: "#023E65" }}>{activity.issueType}</span>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#232B36",
                    }}
                  >
                    {activity.locationName}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#232B36",
                    }}
                  >
                    {activity.infrastructureName}
                  </span>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "150px",
                      display: "inline-block",
                      fontSize: "10px",
                      color: "#232B36",
                      fontWeight: "bold",
                    }}
                  >
                    {activity.parameterName}
                  </span>
                  <span
                    style={{
                      fontSize: "9px",
                      color: "#74788D",
                    }}
                  >
                    {activity.datetime}
                  </span>
                </div>
              </div>
            ))
            .slice(0, 4)}
          {/* {activities.length > 4 && (
            <span
              style={{
                color: "#023E65",
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              View all notifications
            </span>
          )} */}
        </div>
      </div>
    </Sider>
  );
}

const mapStateToProps = (state: any) => ({
  activities: state.activities.array,
});

export default connect(mapStateToProps)(Notifications);
