import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import NrwService from "../../domain/Nrw/NrwService";
import DashboardContext from "../../layout/DashboardLayout/context";

const nrwService = new NrwService();

export default function DashobardNrwView() {
  const { value: context } = useContext(DashboardContext);
  const [dashboardData, setdashboardData] = useState<any>(null);

  async function handleGetNrwData() {
    if (!context.selectedLocation) return;

    try {
      const response = await nrwService.getMROReport(context.selectedLocation);

      if (response && response.data) {
        const data = JSON.parse(response.data) as { [key: string]: any };

        // Map null or undefined values to '-'
        const processedData = Object.keys(data).reduce((acc, key) => {
          acc[key] = data[key] ?? "-"; // If value is null or undefined, set to "-"
          return acc;
        }, {} as { [key: string]: any });

        setdashboardData(processedData);
      } else {
        setdashboardData({});
      }
    } catch (error) {
      console.error("Error fetching NRW data:", error);
      setdashboardData({});
    }
  }

  useEffect(() => {
    handleGetNrwData();
  }, [context.selectedLocation]);

  return (
    <div className="dashboard" style={{ marginTop: "80px", height: "calc(100vh - 80px)" }}>
      <div className="column-dashboard column-other">
        <div className="section-dashboard full-height t-b">
          <span className="title">System Input Volume</span>
          <span className="value">
            {dashboardData?.waterSupplied ? `${dashboardData.waterSupplied.toLocaleString('en-US')} m3` : '-'}
          </span>

        </div>
      </div>

      <div className="column-dashboard column-other">
        <div className="section-dashboard half-height t-b">
          <span className="title">Authorized Consumption</span>
          <span className="value">
          {dashboardData?.authorizedConsumption ? `${dashboardData.authorizedConsumption.toLocaleString('en-US')} m3` : '-'}
          </span>
        </div>

        <div className="section-dashboard half-height t-l">
          <span className="title">Water Losses</span>
          <span className="value">
          {dashboardData?.waterLosses ? `${dashboardData.waterLosses.toLocaleString('en-US')} m3` : '-'}
          </span>
        </div>
      </div>
      <div className="column-dashboard column-real-losses">
        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left t-b">
            <span className="title">Billed Authorized Consumption</span>
            <span className="value low">
            {dashboardData?.billedAuthorizedConsumption ? `${dashboardData.billedAuthorizedConsumption.toLocaleString('en-US')} m3` : '-'}
            </span>
          </div>

          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top t-b">
              <div className="column-body">
                <span className="title">Billed Metered Consumption</span>
                <span className="value low">
                  {dashboardData?.billedMeteredConsumption ? `${dashboardData.billedMeteredConsumption.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>

            <div className="section-dashboard-child half-height bottom t-b">
              <div className="column-body">
                <span className="title">Billed Unmetered Consumption</span>
                <span className="value low">
                  {dashboardData?.billedUnmeteredConsumption ? `${dashboardData.billedUnmeteredConsumption.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left t-l">
            <span className="title">Unbilled Authorized Consumption</span>
            <span className="value low">
            {dashboardData?.unbilledAuthorizedConsumption ? `${dashboardData.unbilledAuthorizedConsumption.toLocaleString('en-US')} m3` : '-'}
            </span>
          </div>

          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top t-l">
              <div className="column-body">
                <span className="title">Unbilled Metered Consumption</span>
                <span className="value low">
                {dashboardData?.unbilledMeteredConsumption ? `${dashboardData.unbilledMeteredConsumption.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>

            <div className="section-dashboard-child half-height bottom t-l">
              <div className="column-body">
                <span className="title">Unbilled Unmetered Consumption</span>
                <span className="value low">
                {dashboardData?.unbilledUnmeteredConsumption ? `${dashboardData.unbilledUnmeteredConsumption.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left t-l">
            <span className="title">Apparent Losses</span>
            <span className="value low">
            {dashboardData?.apparentLosses ? `${dashboardData.apparentLosses.toLocaleString('en-US')} m3` : '-'}
            </span>
          </div>

          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top t-l">
              <div className="column-body">
                <span className="title">Unauthorized Consumption</span>
                <span className="value low">
                  {dashboardData?.unauthorizedConsumption ? `${dashboardData.unauthorizedConsumption.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>

            <div className="section-dashboard-child half-height bottom t-l">
              <div className="column-body">
                <span className="title">Customer Meter Inaccuracies</span>
                <span className="value low">
                {dashboardData?.meterInaccuracies ? `${dashboardData.meterInaccuracies.toLocaleString('en-US')} m3` : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="section-dashboard quarter-height t-l">
          <span className="title">Real Losses</span>
          <span className="value">
          {dashboardData?.realLosses ? `${dashboardData.realLosses.toLocaleString('en-US')} m3` : '-'}
          </span>
        </div>
      </div>

      {/* Revenue Water and Non-Revenue Water */}
      <div className="column-dashboard column-other">
        <div className="section-dashboard quarter-height t-b">
          <span className="title">Revenue Water</span>
          <span className="value">
          {dashboardData?.revenueWater ? `${dashboardData.revenueWater.toLocaleString('en-US')} ` : '-'}
          </span>
          <span className="value">
          {dashboardData?.percentageRevenueWater ? `${dashboardData.percentageRevenueWater.toLocaleString('en-US')} %` : '-'}
          </span>
        </div>

        <div className="section-dashboard three-quarters-height t-l">
          <span className="title">Non-revenue Water</span>
          <span className="value">
          {dashboardData?.nonRevenueWater ? `${dashboardData.nonRevenueWater.toLocaleString('en-US')} ` : '-'}
          </span>
          <span className="value">
          {dashboardData?.percentageNonRevenueWater ? `${dashboardData.percentageNonRevenueWater.toLocaleString('en-US')} %` : '-'}
          </span>
        </div>
      </div>
    </div>
  );
}
