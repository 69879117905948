import React, { useContext, useEffect, useState } from "react";
import Map from "./Map";
import { Tabs, Layout, MenuProps, Card, Tooltip } from "antd";
import type { TabsProps } from "antd";
import KpiSvg from "../../assets/svg/KpiSvg";
import InfrastructureDashboardView from "./Infrastructure";
import KpiDashBoardView from "./Kpi";
import Icon, { CloseOutlined } from "@ant-design/icons";
import "./index.scss";
import DashboardContext from "../../layout/DashboardLayout/context";
import WaveSvg from "../../assets/svg/WaveSvg";
import { Col, Row } from "antd";
import KpiService from "../../domain/Kpi/KpiService";
import dayjs from "dayjs";
import { Toggle } from "rsuite";
import { dashboardMapEventsService } from "../../events/dashboardMap.subject";
import { lateralMenuEventsService } from "../../events/lateralMenu.subject";
import KpiCard from "../../components/Dashboard/KpiCard";

const kpiService = new KpiService();
const { Sider } = Layout;

export default function Dashboard() {
  const subscriptionLM$ = lateralMenuEventsService.getSubject();
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const [tabActive, setTabActive] = useState<string | null>(null);
  const [futureRainfall, setFutureRainfall] = useState<any>(false);
  const [showRainfall, setShowRainfall] = useState(false);
  const userId = localStorage.getItem("userId") || "";
  const [kpis, setKpis] = useState<any>();
  const oui = JSON.parse(
    localStorage.getItem("security") || "{}"
  ).organizationId;

  const onChange = (key: string) => {
    setTabActive(key);
    console.log(key);
  };

  const setActive = (tab: string) => {
    setContext({ ...context, expandLeftMenu: true });
    setTabActive(tab);
  };

  const items: TabsProps["items"] = [
    {
      key: "INFRASTRUCTURE",
      label: <div className="tab">INFRASTRUCTURE</div>,
      children: <InfrastructureDashboardView />,
    },
    {
      key: "KPI",
      label: <div className="tab">KPI'S</div>,
      children: <KpiDashBoardView />,
    },
  ];

  const getOptions = (values: any) => ({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      width: 88,
      height: 89,
      backgroundColor: "#F7F9FC",
    },
    xAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    legend: { enabled: false },
    series: [
      {
        data: Object.values(values),
      },
    ],
  });

  const handleGetKpis = async () => {
    const resp = await kpiService.getKpi(oui, userId);
    setKpis(resp.filter((card: any) => card?.show === true));
  };

  useEffect(() => {
    handleGetKpis();
  }, [context.kpi]);

  const handlePinKpi = async (value: any) => {
    const resPinKpi = await kpiService.postPinKpiInDashboard(value.id, false);
    if (resPinKpi?.success) {
      handleGetKpis();
    }
  };

  const orderData = (data: Record<string, any>) => {
    const entries = Object.entries(data);
    entries.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return Object.fromEntries(entries);
  };

  const getLastKeyValue = (data: any) => {
    const keys = Object.keys(data).sort();
    const lastKey = keys[keys.length - 1];
    const date = new Date(parseInt(lastKey)).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const value = data[lastKey];
    return { date, value };
  };

  const objectToArray = (values: any, first?: boolean, last?: boolean) => {
    // console.log("---------");
    // console.log("objectToArray", values);
    let value: any;

    if (Object.entries(values).length !== 0) {
      const arregloOrdenado: any = Object.entries(values).sort(
        (a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()
      );

      if (first) {
        // console.log("primer valor", arregloOrdenado[0][0]);

        if (arregloOrdenado[0][0] !== "") {
          value = dayjs(arregloOrdenado[0][0]).format("h:mm a");
        } else {
          value = "no data available";
        }
      }
      if (last) {
        // console.log("ultimoValor", arregloOrdenado[0][1]);

        if (arregloOrdenado[0][1] > 0) {
          value = arregloOrdenado[0][1];
        } else {
          value = "0";
        }
      }

      // console.log("ultimoValor", value);
      // console.log("------------");
    } else {
      if (first) {
        value = "no data available";
      }
      if (last) {
        value = "0";
      }
    }

    return value;
  };

  useEffect(() => {
    subscriptionLM$.subscribe((event) => {
      if (event.type == "setActive") {
        setActive(event.data);
      }
    });
  }, []);

  return (
    <>
      <Map />
      <div
        style={{
          position: "absolute",
          right: "2px",
          bottom: "21px",
          width: "260px",
          height: "90px",
        }}
      >
        <Toggle
          onClick={(val: any) => {
            dashboardMapEventsService.setSubject({
              type: "changeAnimation",
              data: val,
            });
          }}
          style={{
            position: "absolute",
            right: "20px",
            bottom: "32px",
          }}
          checkedChildren="Hide Flows"
          unCheckedChildren="Show Flows"
        />
        <Toggle
          onChange={(checked) => {
            setShowRainfall(checked);
            if (!checked) {
              dashboardMapEventsService.setSubject({
                type: "cleanRainfallLayer",
              });
            } else {
              dashboardMapEventsService.setSubject({
                type: "drawRainfallLayer",
                data: { futureRainfall },
              });
            }
          }}
          style={{
            position: "absolute",
            right: "150px",
            bottom: "32px",
            width: "104px",
          }}
          checkedChildren="Hide Radar"
          unCheckedChildren="Show Radar"
        />
        {showRainfall && (
          <>
            <Toggle
              checked={futureRainfall}
              onChange={(checked) => {
                setFutureRainfall(checked);
                dashboardMapEventsService.setSubject({
                  type: "futureRainfallToggleChanged",
                  data: { futureRainfall: checked },
                });
              }}
              style={{
                position: "absolute",
                right: "150px",
                bottom: "5px",
                width: "104px",
              }}
              checkedChildren="Future"
              unCheckedChildren="Past"
            />
          </>
        )}
      </div>
      {/* <WeatherMap/> */}
      <Layout
        className="site-layout"
        style={{ background: "white", overflowY: "auto" }}
      >
        <Sider
          style={{
            width: 56,
            background: "white",
            position: "absolute",
            zIndex: 9,
            top: 75,
            left: 10,
            borderRadius: 5,
            display: !context.expandLeftMenu ? "block" : "none",
            overflow: "auto",
          }}
          width={56}
        >
          <div style={{ width: 56 }}>
            <div
              onClick={() => setActive("INFRASTRUCTURE")}
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "0px 0px 0px 17px",
                height: "44px",
              }}
              className="flex items-center"
            >
              <Tooltip placement="right" title="Infrastructure">
                <Icon
                  component={WaveSvg}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  rev={undefined}
                />
              </Tooltip>
            </div>
            <div
              onClick={() => setActive("KPI")}
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "0px 0px 0px 17px",
                height: "44px",
              }}
              className="flex items-center"
            >
              <Tooltip placement="right" title="KPI'S">
                <Icon
                  component={KpiSvg}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  rev={undefined}
                />
              </Tooltip>
            </div>
          </div>
        </Sider>
        <Sider
          style={{
            background: "white",
            position: "absolute",
            zIndex: 9,
            top: 75,
            left: 10,
            borderRadius: 5,
            height: "calc(100% - 76px)",
            display: context.expandLeftMenu ? "block" : "none",
            overflow: "auto"
          }}
          width={270}
        >
          <div>
            <Tabs
              activeKey={tabActive || undefined}
              items={items}
              onChange={onChange}
            />
            <div
              className="close"
              onClick={() => setContext({ ...context, expandLeftMenu: false })}
            >
              <CloseOutlined rev={undefined} />
            </div>
          </div>
        </Sider>
      </Layout>
      <div
        style={{
          // display: "block",
          position: "absolute",
          left: 0,
          bottom: 10,
        }}
      >
        <Row
          className=""
          gutter={[0, 100]}
          style={{
            height: 100,
            columnGap: 10,
            marginLeft: 10,
            marginBottom: 15,
          }}
        >
          {kpis?.map((card: any) => (
            <KpiCard
              kpi={card}
              handleGetKpis={handleGetKpis}
              onPinKpi={() => {}}
            />
          ))}
        </Row>
      </div>
    </>
  );
}
