import React, { useState } from "react";

interface StateMachineValueInterface {
  inputsList: any[];
  statement: any[],
  outputsList: any[];
  constantsList: any[];
  strategyList: any[];
  constantsStratList: any[];
}

interface StateMachineContextInterface {
  value: StateMachineValueInterface;
  setValue: React.Dispatch<React.SetStateAction<StateMachineValueInterface>>;
}

const initialContext: StateMachineContextInterface = {
  value: {
    inputsList: [],
    statement: [],
    outputsList: [],
    constantsList: [],
    strategyList: [],
    constantsStratList: []
  },
  setValue: () => { },
};

const StateMachineContext = React.createContext(initialContext);

export default StateMachineContext;
