import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Row,
  Form,
  Input,
  Modal,
  Divider,
  Radio,
  Select,
  InputNumber,
  notification,
  Alert,
} from "antd";
import type { FormInstance } from "antd/es/form";

import "./modal.scss";
import { connect } from "react-redux";
import { getUnitsAction } from "../../redux/unitsDuck";
import { getTypesAction } from "../../redux/measurementDuck";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import { message } from "antd";

interface IProps {
  types: any;
  units: any;
  loadUnits: any;
  isModalOpen: any;
  handleOk: any;
  handleCancel: any;
  handleGetInfrastructure: any;
  isNewParameter: any;
  editParameter: any;
  setIsModalOpen: any;
  infraId: any;
  parameters: any;
}

/**
 * @author
 * @function @AddInfrastructureModal
 **/

type LayoutType = Parameters<typeof Form>[0]["layout"];

const infrastructureService = new InfrastructureService();
// Definimos el mapeo de parámetros a sus unidades
const parameterUnitMapping: any = {
  1: { IS: ["m", "cm", "mm"], US: ["ft", "in"] }, // Depth
  2: { IS: ["MLD", "CMS", "LPS"], US: ["MGD", "CFS", "GPM"] }, // Flow
  3: { IS: ["Ha-m", "ML", "m3", "lt"], US: ["MG", "CF", "gal"] }, // Flooding
  4: { IS: ["MLD", "CMS", "LPS"], US: ["MGD", "CFS", "GPM"] }, // Total Inflow
  5: { IS: ["m"], US: ["ft"] }, // Head
  6: { IS: ["mca", "bar"], US: ["psi"] }, // Pressure
  7: { IS: ["m/s", "km/h", "m/h"], US: ["fps", "mph"] }, // Velocity
  8: { IS: ["Ha-m", "ML", "m3", "lt"], US: ["MG", "CF", "gal"] }, // Volume
  9: { IS: ["MLD", "CMS", "LPS"], US: ["MGD", "CFS", "GPM"] }, // Lateral Inflow
  10: { IS: ["MLD", "CMS", "LPS"], US: ["MGD", "CFS", "GPM"] }, // Total Outflow
  11: { IS: ["mm/h"], US: ["in/h"] }, // Intensity
  13: { IS: ["mca", "bar"], US: ["psi"] }, // Losses
  14: { IS: ["cm", "mm"], US: ["in"] }, // Precipitation
  15: { IS: ["%"], US: [] }, //Capacity
};
function AddInfrastructureModal({
  types,
  units,
  loadUnits,
  isModalOpen,
  handleOk,
  handleCancel,
  handleGetInfrastructure,
  isNewParameter,
  editParameter,
  setIsModalOpen,
  infraId,
  parameters,
}: IProps) {
  const [formLayout, setFormLayout] = useState<LayoutType>("horizontal");
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [unitsByParam, setUnitsByParam] = useState<any[]>([]);

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 15 }, wrapperCol: { span: 4 } }
      : null;

  // const [initialValues, setInitialValues] = useState(editParameter);

  const handleAddInfrastructureParemeters = async (values: Parameter) => {
    if (infraId) {
      return await infrastructureService.postAddParameter(infraId, values);
    }
  };

  function validateValues(values: any) {
    const { criticalHigh, criticalLow, criticalHhigh, criticalLlow } = values;

    if (criticalLlow > criticalLow) {
      return `Error: Critical Low Low (${criticalLlow}) must be less than or equal to Critical Low (${criticalLow}).`;
    }

    if (criticalLow >= criticalHigh) {
      return `Error: Critical Low (${criticalLow}) must be less than Critical High (${criticalHigh}).`;
    }

    if (criticalHigh > criticalHhigh) {
      return `Error: Critical High (${criticalHigh}) must be less than or equal to Critical High High (${criticalHhigh}).`;
    }
    return false;
  }

  const onFinish = async (values: Parameter) => {
    setErrorMessage(null);
    const error = validateValues(values);
    if (error) {
      setErrorMessage(error);
      return;
    }

    const response = await handleAddInfrastructureParemeters(values);
    if (response.success === 1) {
      form.resetFields();
      setIsModalOpen(false);
      await handleGetInfrastructure();
      notification.success({
        message: isNewParameter ? "Parameter created" : "Parameter edited",
        description: isNewParameter
          ? "Parameter created successfully"
          : "Parameter edited successfully",
        placement: "topRight",
      });
    }
    handleOk();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    loadUnits && loadUnits();
  }, []);

  // useEffect(() => {
  //   const parameter = parameterUnitMapping[form.getFieldValue("paramTypeId")];
  //   console.log("🚀 ~ getUnitsByParameter ~ parameter:", parameter);
  //   if (parameter) {
  //     const units = [...parameter.IS, ...parameter.US]; // Unidades de IS seguidas de US
  //     const response = units
  //       .map((unitName) => {
  //         const unit = units.find((u) => u.name === unitName);
  //         return unit ? unit.name : null;
  //       })
  //       .filter(Boolean); // Filtrar nulls si no encuentra unidades
  //     setUnitsByParam(response);
  //   } else {
  //     setUnitsByParam([]);
  //   }
  // }, [form.getFieldValue("paramTypeId")]);

  useEffect(() => {
    if (!isNewParameter) {
      // editParameter
      form.setFieldsValue({
        id: editParameter?.id,
        name: editParameter?.name,
        criticalHigh: editParameter?.highCritical,
        samplerate: editParameter?.sampleRate,
        retentionPolicy: editParameter?.retentionPolicy,
        criticalHhigh: editParameter?.hHighCritical,
        txrate: editParameter?.transferRate,
        historicalRetentionTime: editParameter?.historicalPolicy,
        criticalLow: editParameter?.lowCritical,
        expirationTime: editParameter?.expirationTime,
        forecastedRetentionTime: editParameter?.forecastedRetentionTime,
        criticalLlow: editParameter?.lLowCritical,
        principal: editParameter?.principal,
        paramUnitId: editParameter?.paramUnitId,
        paramTypeId: editParameter?.paramTypeId,
      });
      const parameter = parameterUnitMapping[editParameter?.paramTypeId];
      if (parameter) {
        const unitsParam = [...parameter.IS, ...parameter.US]; // Unidades de IS seguidas de US
        const response = unitsParam
          .map((unitName: any) => {
            const unit = units.find(
              (u: any) => u.name.toUpperCase() === unitName.toUpperCase()
            );
            return unit || null;
          })
          .filter(Boolean); // Filtrar nulls si no encuentra unidades
        setUnitsByParam(response);
      } else {
        setUnitsByParam([]);
      }
    } else {
      form.setFieldsValue({
        paramUnitId: null,
        paramTypeId: null,
      });
    }
  }, [editParameter, isNewParameter]);

  return (
    <Modal
      title={
        <div className="title-modal">
          {!isNewParameter ? "EDIT PARAMETER" : "ADD PARAMETER"}
        </div>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={[]}
      destroyOnClose={true}
      afterClose={() => form.resetFields()}
      style={{ minWidth: "700px" }}
    >
      <Divider
        style={{
          marginLeft: "0px",
          width: "100%",
          border: "1px solid #E6E6E6",
        }}
      />
      {errorMessage && <Alert message={errorMessage} type="error" closable />}
      <Form
        form={form}
        {...formItemLayout}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="small"
        style={{ maxWidth: 700 }}
      >
        <Form.Item name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Row>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ display: "inline-block" }}>Required</span>
                ),
              },
            ]}
            label={<div className="item-label">Parameter name</div>}
          >
            <Input
              tabIndex={1}
              // placeholder="input placeholder"
              style={{ width: 250 }}
            />
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <div className="item-label">
              <span>
                Critical<br></br> High
              </span>
              <div style={{ marginLeft: 30 }}>
                <Form.Item
                  name="criticalHigh"
                  rules={[
                    {
                      type: "number",
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only numbers
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={2}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="item-label">
              <span>
                Sample<br></br> rate
              </span>
              <div className="ml-3">
                <Form.Item
                  name="samplerate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={6}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="item-label">
              <span>
                Retention<br></br> policy
              </span>
              <div style={{ marginLeft: 12 }}>
                <Form.Item
                  name="retentionPolicy"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={9}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="item-label ml-2">
              <span>
                Critical<br></br> High High
              </span>
              <div className="ml-5">
                <Form.Item
                  name="criticalHhigh"
                  rules={[
                    {
                      type: "number",
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only numbers
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={3}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="item-label ml-2">
              <span>
                Transfer<br></br> rate
              </span>
              <div className="ml-4">
                <Form.Item
                  name="txrate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={7}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="item-label ml-2">
              <span>
                Historical<br></br> policy
              </span>
              <div className="ml-3">
                <Form.Item
                  name="historicalRetentionTime"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={10}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="item-label ml-3">
              <span>
                Critical<br></br> Low
              </span>
              <div className="ml-4">
                <Form.Item
                  name="criticalLow"
                  rules={[
                    {
                      type: "number",
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only numbers
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={4}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="item-label ml-3">
              <span>
                Expiration <br></br> time
              </span>
              <div style={{ marginLeft: 15 }}>
                <Form.Item
                  name="expirationTime"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={8}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="item-label ml-3">
              <span>
                Forecasted <br></br> retention <br></br>time
              </span>
              <div style={{ marginLeft: 10 }}>
                <Form.Item
                  name="forecastedRetentionTime"
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value == undefined || value > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Only greater than 0 ")),
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only greater than 0
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={11}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="item-label ml-4">
              <span>
                Critical<br></br> Low Low
              </span>
              <div className="ml-3">
                <Form.Item
                  name="criticalLlow"
                  rules={[
                    {
                      type: "number",
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Only numbers
                        </span>
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    tabIndex={5}
                    style={{ width: 36, height: 32 }}
                    controls={false}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <div className="item-label">
              <span>Type</span>
              <div className="ml-5">
                <Form.Item
                  wrapperCol={{
                    xs: {
                      span: 24,
                      offset: 0,
                    },
                    sm: {
                      span: 24,
                      offset: 0,
                    },
                  }}
                  name={"paramTypeId"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value !== 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Should select an option")
                            ),
                    },
                    {
                      validator: (_, value) =>
                        parameters.findIndex(
                          (p: any) =>
                            p.paramTypeId === value &&
                            (isNewParameter || p.id !== editParameter.id)
                        ) >= 0
                          ? Promise.reject(new Error("Type is already in use"))
                          : Promise.resolve(),
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    tabIndex={12}
                    placeholder="Select an option"
                    style={{ minWidth: 140, maxWidth: 150 }}
                    options={types?.map((type: { id: any; name: any }) => {
                      return { value: type.id, label: type.name };
                    })}
                    onChange={() => {
                      const parameter =
                        parameterUnitMapping[form.getFieldValue("paramTypeId")];
                      if (parameter) {
                        const unitsParam = [...parameter.IS, ...parameter.US]; // Unidades de IS seguidas de US
                        const response = unitsParam
                          .map((unitName: any) => {
                            const unit = units.find(
                              (u: any) =>
                                u.name.toUpperCase() === unitName.toUpperCase()
                            );
                            return unit || null;
                          })
                          .filter(Boolean); // Filtrar nulls si no encuentra unidades
                        setUnitsByParam(response);
                      } else {
                        setUnitsByParam([]);
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div className="item-label ml-3">
              <span>Unit</span>
              <div className="ml-6">
                <Form.Item
                  name={"paramUnitId"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          Required
                        </span>
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value !== 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Should select an option")
                            ),
                    },
                  ]}
                  wrapperCol={{
                    xs: {
                      span: 24,
                      offset: 0,
                    },
                    sm: {
                      span: 24,
                      offset: 0,
                    },
                  }}
                >
                  <Select
                    tabIndex={13}
                    placeholder="Select an option"
                    style={{ minWidth: 120, maxWidth: 150 }}
                    options={unitsByParam.map(
                      (unit: { id: any; name: any }) => {
                        return { value: unit.id, label: unit.name };
                      }
                    )}
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <div className="item-label mt-3">
            <span>Select as main parameter?</span>
            <div style={{ marginTop: -10, marginLeft: 10 }}>
              <Form.Item
                name={"principal"}
                rules={[
                  {
                    required: true,
                    message: (
                      <span
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Required
                      </span>
                    ),
                  },
                ]}
              >
                <Radio.Group className="item-label">
                  <Radio value={true} tabIndex={14}>
                    <div className="item-label">Yes</div>
                  </Radio>
                  <Radio value={false} tabIndex={15}>
                    <div className="item-label">No</div>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <Divider
            style={{
              marginLeft: "-15px",
              width: "700px",
              border: "1px solid #E6E6E6",
            }}
          />
        </Row>
        <div className="flex justify-between">
          <Button
            tabIndex={16}
            key="submit"
            type="primary"
            className="cancel-button"
            // loading={loading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            tabIndex={17}
            htmlType="submit"
            type="primary"
            className="accept-button"
            onKeyDown={(event) => {
              if (event.key === "Tab") {
                event.preventDefault();
                const firstElement: any =
                  document.querySelector('[tabindex="1"]');
                if (firstElement) {
                  firstElement.focus();
                }
              }
            }}
            // onClick={handleOk}
          >
            Accept
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

function mapState(state: { types: { array: any }; units: { array: any } }) {
  return {
    types: state.types.array,
    units: state.units.array,
  };
}

const mapDispatch = (dispatch: any) => {
  return {
    loadUnits: () => {
      dispatch(getUnitsAction());
      dispatch(getTypesAction());
    },
  };
};

export default connect(mapState, mapDispatch)(AddInfrastructureModal);
