/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { PageLayout } from "./ui.jsx";
import { Spinner } from "react-bootstrap";
import { BrowserRouter as RouterPrincipal } from "react-router-dom";
import Router from "./routes/Router";
import { api } from "./services/api";
import Loader from "./components/Loader";
import ModalAlert from "./components/General/ModalAlert";
import { ConfigProvider, notification } from "antd";

import { connect, useDispatch, useSelector } from "react-redux";
import { getSecurityAction } from "./redux/securityDuck";
import { hideModal } from "./redux/modalAlertDuck";

import DevicesService from "./domain/Devices/DevicesService";
import { iotEventsService } from "./events/iot-events.subject";
import { b2cPolicies } from "./authConfig";
import "rsuite/dist/rsuite.css";
import ParameterFileService from "./domain/ParameterFiles/ParameterFilesService";
import { apiNl } from "./services/apiNl.js";
import ExpiredSessionModal from "./components/Modal/ExpiredSessionModal.tsx";

const devicesService = new DevicesService();
const parameterFileService = new ParameterFileService();

const MainContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch((e) => {
                return;
              });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */

          localStorage.setItem("accessToken", event?.payload.accessToken);
          localStorage.setItem("userId", event?.payload.idTokenClaims.oid);

          api.defaults.headers.common = {
            Authorization: `Bearer ${event?.payload.accessToken}`,
          };
          apiNl.defaults.headers.common = {
            Authorization: `Bearer ${event?.payload.accessToken}`,
          };

          dispatch(getSecurityAction(event?.payload.idTokenClaims.oid));

          // getOrganizationsAction(
          //     event?.payload.idTokenClaims.oid,
          //     event?.payload.accessToken
          // );

          console.log("event", event);

          if (
            event.payload.idTokenClaims["acr"] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password"
            );
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RouterPrincipal>
          <Router />
        </RouterPrincipal>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className="spinner">
          <span className="spinner-text"></span>
          <Spinner animation="border" variant="info" size="lg"></Spinner>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

function App({
  msalInstance,
  isLoading,
  modalAlertOptions,
  showExpiredSessionModal,
}) {
  const dispatch = useDispatch();
  // const { instance } = useMsal();
  return (
    <MsalProvider instance={msalInstance}>
      <Loader show={isLoading} />
      <ModalAlert
        {...modalAlertOptions}
        onClose={() => {
          dispatch(hideModal());
        }}
      />
      <ExpiredSessionModal
        showModal={showExpiredSessionModal}
        closeModal={() => {
          localStorage.clear();
          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }}
      />
      <PageLayout>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#078CB3",
            },
          }}
        >
          <MainContent />
        </ConfigProvider>
      </PageLayout>
    </MsalProvider>
  );
}

function mapState(state) {
  return {
    isLoading: state.loader.currentStack > 0,
    modalAlertOptions: state.modalAlert.options,
    showExpiredSessionModal: state.modalAlert.showExpiredSessionModal,
  };
}

export default connect(mapState)(App);
