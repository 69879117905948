import { Select } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import useParameter from "../../hooks/useParameter";

const SelectParameter = forwardRef(
  (
    {
      infrastructureId,
      onSelect,
      emitObject = false,
      filteredOptions = [],
    }: {
      infrastructureId: number | null;
      onSelect: Function;
      emitObject?: boolean;
      filteredOptions?: any;
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const { parameters } = useParameter({
      infrastructureId,
    });

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
      cleanValue: () => {
        setSelectedValue(null);
      },
    }));

    return (
      <Select
        value={selectedValue}
        placeholder="Select Parameter"
        onChange={(val) => {
          setSelectedValue(val);
          onSelect(emitObject ? JSON.parse(val || "{}") : val);
        }}
        style={{ width: "100%" }}
        options={(parameters || [])
          .filter((p) => {
            return (
              filteredOptions.findIndex((opt: any) => {
                return opt == p.id;
              }) < 0
            );
          })
          .map((p) => ({
            value: emitObject ? JSON.stringify(p) : p.id,
            label: p.name,
          }))}
      />
    );
  }
);

export default SelectParameter;
