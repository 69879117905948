import MmsRepository from "./MmsRepository";

export default class MmsService {
  private mmsRepository: MmsRepository = new MmsRepository();

  async getModelVersions() {
    try {
      return await this.mmsRepository.getModelVersions();
    } catch (error) {
      console.error(error);
    }
  }
}
