import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Upload, message, notification } from "antd";
import type { UploadProps } from "antd";
import {
  ArrowLeftOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import HistoricalService from "../../../domain/Historical/HistoricalService";

const historicalService = new HistoricalService();
const { Dragger } = Upload;

function UploadHistoricalFiles() {
  const navigate = useNavigate();
  const [messageApi] = message.useMessage();
  const [files, setFiles] = useState<any>(null);

  const initialUploadProps: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".xlsx",
    showUploadList: false,
    beforeUpload: (file: any) => {
      const nameSplit = file.name.split(".");
      if (file.name.length > 100) {
        messageApi.open({
          type: "error",
          content: `File name ${file.name} exceeds 100 characters`,
        });
        notification.error({
          message: "Error",
          description: `File name ${file.name} exceeds 100 characters`,
        });
        return false;
      }
      const extension = nameSplit[nameSplit.length - 1];
      if (extension === "xlsx") {
        setFiles(file);
      } else {
        showErrorFile();
      }
      return false;
    },
  };

  const showErrorFile = () => {
    messageApi.open({
      type: "error",
      content: "One of the files selected is not valid",
    });
  };

  const deleteFile = (fileId: any) => {
    setFiles(null);
  };

  const blobToBase64 = async (blob: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result as string;
          resolve(result.split(",")[1]);
        } else {
          resolve("");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFiles = async () => {
    if (!files) {
      openNotificationNoData();
      return;
    }
    const uploadedBy = localStorage.getItem("userId") || "";
    const dataFiles = {
      filename: files.name,
      file: await blobToBase64(files),
      uploadedBy
    };

    await historicalService.postHistoricalFiles(dataFiles);
    notification.success({
      message: "Successful",
      description: "Files uploaded successfully",
    });
    navigate(`/upload?selectedTab=HISTORICAL`);
  };

  const openNotificationNoData = () => {
    notification.open({
      message: "Error",
      description: "At least you need to upload one CSV file.",
      type: "error",
    });
  };

  const fileSize = (size: any) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${(size / Math.pow(k, i)).toFixed(3)} ${sizes[i]}`;
  };

  return (
    <div style={{ padding: "0px 3rem" }}>
      <div>
        <Button
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(`/upload?selectedTab=HISTORICAL`);
          }}
        >
          <ArrowLeftOutlined rev={undefined} /> Back to Historical Files
        </Button>
      </div>
      <div className="flex column pt-2">
        <span className="text-title-page">Upload Historical Files</span>
        <div
          className="mt-2 flex items-center"
          style={{
            background: "rgba(255, 238, 203, 0.5)",
            height: "40px",
            borderLeft: "solid 8px #FBC261",
          }}
        >
          <span className="ml-2" style={{ color: "#023E65" }}>
            Only .xlsx files accepted
          </span>
        </div>
        <div className="tableContainer mt-3 flex column w-full">
          <table
            style={{
              border: "2px solid #E6E6E6",
              height: "calc(100vh - 340px)",
              borderRadius: "10px",
              borderCollapse: "separate",
            }}
          >
            <tr style={{ height: "50px" }}>
              <th
                style={{
                  paddingLeft: "20px",
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                File Name
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                Size
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              ></th>
            </tr>
            {!files ? (
              <tr>
                <td colSpan={4}>
                  <Dragger
                    {...initialUploadProps}
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="flex column">
                      <span className="mb-2" style={{ color: "#74788D" }}>
                        Drag and drop your files here or click in
                      </span>
                      <Button
                        icon={<PlusCircleOutlined rev={undefined} />}
                        shape="round"
                        className="flex items-center self-center"
                        style={{
                          color: "#023E65",
                          borderColor: "#023E65",
                          backgroundColor: "transparent",
                        }}
                      >
                        Add Files
                      </Button>
                    </div>
                  </Dragger>
                </td>
              </tr>
            ) : (
              <>
                <tr
                  style={{
                    height: "40px",
                    backgroundColor: "#F7F9FC",
                  }}
                  key={files.uid}
                >
                  <td style={{ paddingLeft: "50px" }}>{files.name}</td>
                  <td>{fileSize(files.size)}</td>
                  <td>
                    <DeleteOutlined
                      style={{
                        cursor: "pointer",
                        fontSize: "1.3rem",
                      }}
                      onClick={() => {
                        deleteFile(files.uid);
                      }}
                      rev={undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <Dragger
                      {...initialUploadProps}
                      style={{ backgroundColor: "white" }}
                    >
                      <Button
                        icon={
                          <PlusCircleOutlined
                            style={{
                              fontSize: "1.4rem",
                            }}
                            rev={undefined}
                          />
                        }
                        shape="round"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#023E65",
                          border: "2px solid #023E65",
                          bottom: "10px",
                          right: "10px",
                        }}
                        className="flex items-center justify-center absolute"
                      />
                    </Dragger>
                  </td>
                </tr>
              </>
            )}
          </table>
        </div>
        <div className="flex pt-3 justify-between">
          <Button
            style={{ width: "85px", height: "40px" }}
            onClick={() => {
              navigate(`/upload?selectedTab=HISTORICAL`);
            }}
          >
            Cancel
          </Button>
          <div>
            <Button
              style={{
                width: "120px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={() => {
                uploadFiles();
              }}
            >
              Upload Files
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapState(state: { locations: { array: any } }) {
  return {
    locations: state.locations.array,
  };
}

export default connect(mapState)(UploadHistoricalFiles);
