import React, { FC } from "react";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import { Switch, Button, Input, Table, Pagination, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import InpService from "../../domain/ModelFile/InpService";

const inpService = new InpService();
interface DataType {
  enabled: boolean | undefined;
  key: string;
  inpId: string;
  inpName: number;
  mmsId: any;
  locationName: string;
  createdBy: string;
  createdAt: string;
  modelType: string;
  datResponseDTOList: {
    datId: number;
    datName: string;
    pluviometers: string;
    modifiedBy: string;
    createdAt: string;
    modifiedAt: string;
  }[];
  uploadDate: string;
  children?: DataType[];
  hidden: string;
  status: string;
  statusMessage: string;
}

interface DataPluviometer {
  datId: number;
  datName: string;
  pluviometers: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: string;
  modifiedAt: string;
}

const ModelTable: FC<{
  handleUpdateModelFiles: any;
  files: Array<any>;
  onChangePagination: any;
  pagination: any;
  setInputSearch: Function;
  setType: Function;
  setModelFiles: Function;
}> = ({
  files,
  onChangePagination,
  pagination,
  setInputSearch,
  setModelFiles,
}) => {
  const navigate = useNavigate();

  const expandedRowRender = (record: any) => {
    if (record.datResponseDTOList) {
    }
    return (
      <Table
        columns={columnsPluviometers}
        dataSource={record.datResponseDTOList}
        pagination={false}
        size="small"
      />
    );
  };

  const columnsPluviometers: ColumnsType<DataPluviometer> = [
    {
      title: "",
      dataIndex: "datName",
      key: "datName",
      width: 550,
      render: (_, { datName }) => (
        <>
          <span className="table-data-gis">{datName}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "locationName",
      key: "locationName",
      width: 250,
      render: (_) => (
        <>
          <span className="table-data-gis"></span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "pluviometers",
      key: "pluviometers",
      width: 280,
      render: (_, { pluviometers }) => (
        <>
          <span className="table-data-gis">{pluviometers}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: ["createdBy", "createdAt"],
      key: "gisFileUploadedDTOS",
      render: (_, { createdBy, createdAt }) => (
        <>
          <div>
            <span className="table-data-gis">{createdBy}</span>
            <br></br>
            <span className="date-gis">{createdAt}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "datId",
      key: "datId",
      width: 130,
      render: (_, { datId }) => (
        <>
          <div>
            {/* <DeleteOutlined
              className="mr-1"
              style={{ fontSize: "1.5rem" }}
              rev={undefined}
              onClick={() => {
                setLocationSelect(item);
                setShowDeleteModal(true);
              }}
            /> */}
          </div>
        </>
      ),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "File Name",
      dataIndex: "inpName",
      key: "inpName",
      width: 550,
      render: (_, { inpName, mmsId }) => (
        <div className="flex column">
          <span className="table-data-gis">{inpName}</span>
          {mmsId && (
            <span
              style={{
                color: "#2a7d97",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/mms?mmsId=${mmsId}&fileName=${inpName}`);
              }}
            >
              Provided by MMS
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "locationName",
      key: "locationName",
      width: 250,
      render: (_, { locationName }) => (
        <>
          <span className="table-data-gis">{locationName}</span>
        </>
      ),
    },
    {
      title: "Rain Gauge",
      dataIndex: "hidden",
      key: "hidden",
      width: 280,
      render: (_, { hidden }) => (
        <>
          <span className="table-data-gis">{hidden}</span>
        </>
      ),
    },
    {
      title: "Uploaded by",
      dataIndex: ["createdBy", "createdAt"],
      key: "gisFileUploadedDTOS",
      render: (_, { createdBy, createdAt }) => (
        <>
          <div>
            <span className="table-data-gis">{createdBy}</span>
            <br></br>
            <span className="date-gis">{createdAt}</span>
          </div>
        </>
      ),
    },
    {
      title: "Upload Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        const fontColor = "#232B36";
        let color = "#FFEECB";
        if (status === "COMPLETED") {
          color = "#C0EFDE";
        }
        if (status === "IN PROGRESS") {
          color = "#FFEECB";
        }
        if (status === "FAILED") {
          color = "#FFD3D3";
        }
        return (
          <Tag color={color} style={{ color: fontColor }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Upload Status Message",
      dataIndex: "statusMessage",
      key: "statusMessage",
      render: (_, { statusMessage }) => (
        <span className="table-data-gis">{statusMessage}</span>
      ),
    },
    {
      title: "Model Type",
      dataIndex: "modelType",
      key: "modelType",
      render: (_, { modelType }) => (
        <>
          <div>
            <span className="table-data-gis">{modelType}</span>
          </div>
        </>
      ),
    },
    {
      title: "Enable",
      dataIndex: "datResponseDTOList",
      key: "datResponseDTOList",
      render: (_, record) => (
        <>
          <Switch
            defaultChecked={record.enabled}
            onChange={(checked) => {
              const newData: any = [...files];
              const index = newData.findIndex(
                (item: any) => record.inpId === item.id
              );
              // newData[index].enabled = checked;
              setModelFiles(newData);
              if (checked) {
                inpService.enableInp(record.inpId, checked);
              } else {
                inpService.disableInp(record.inpId, checked);
              }
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <ClockCircleOutlined
                style={{ color: "#078CB3" }}
                rev={undefined}
              />
            }
            onChange={(e) => {
              if (e.target.value == "") {
                setInputSearch(null);
              }
            }}
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                setInputSearch(element.value);
              }
            }}
            allowClear
          />
          {/* <Select
            style={{ marginLeft: 30, width: 140 }}
            placeholder="Pluviometer"
            optionFilterProp="children"
            options={[
              {
                value: "1",
                label: "INFRAESTRUCTURE",
              },
              {
                value: "2",
                label: "CONDUIT",
              },
              {
                value: "3",
                label: "STORAGE",
              },
            ]}
            onChange={(value) => {
              setType(value);
            }}
            allowClear
          /> */}
        </div>

        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="mr-5 boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`modelfiles`);
            }}
          >
            {"Upload Model File"}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={files}
          pagination={false}
          expandedRowRender={expandedRowRender}
          rowKey={(record) => {
            return record.inpId;
          }}
          size="small"
          // scroll={{ x: 1100, y: 370 }}
          style={{
            maxHeight: "calc(100vh - 330px)",
            overflowX: "auto",
          }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalRows}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default ModelTable;
