import { api } from "../../services/api";

export default class NonRevenueWaterRepository {
  
    async createOrUpdateConfiguration({
      nrwId,
      locationId,
      infrastructure,
      parameterId,
      measurementTypeId,
    }: {
      nrwId: number| null;
      locationId: number;
      infrastructure: number;
      parameterId: number;
      measurementTypeId: number;
    }): Promise<any> {
      const response = await api.post(`/nrw`, {
        nrwId,
        locationId,
        infrastructure,
        parameterId,
        measurementTypeId,
      });
      return response.data;
    }
  
    async addInvoiceToConfiguration({
      nrwId,
      startDate,
      endDate,
      invoice,
    }: {
      nrwId: number;
      startDate: string;
      endDate: string;
      invoice: number;
    }): Promise<any> {
      const response = await api.post(`/nrw/${nrwId}/invoice`, {
        startDate,
        endDate,
        invoice,
      });
      return response.data;
    }
  
    async deleteInvoiceFromConfiguration(nrwId: number | string): Promise<any> {
      const response = await api.delete(`/nrw/${nrwId}/invoice`);
      return response.data;
    }
  
    async getConfiguration(nrwId: number): Promise<any> {
      const response = await api.get(`/nrw/${nrwId}`);
      return response.data;
    }

    async calculateInvoice({
      invoiceValue,
    }: {
      invoiceValue: number;
    }): Promise<any> {
      const response = await api.post(`/nrw/calculate`, {
        invoiceValue
      });
      return response.data;
    }
  }
  