import { api } from "../../services/api";

export default class AlertRepository {
  async postAlert(alert: Alert): Promise<boolean> {
    console.log("🚀 ~ AlertRepository ~ postAlert ~ alert:", alert);
    const result = await api.post(`issues/alerts`, alert);
    return result.data;
  }

  async postUpadateAlert(alertId: number, alert: Alert): Promise<boolean> {
    const result = await api.post(`issues/alerts/${alertId}`, alert);
    return result.data;
  }

  async getAlerts({
    name = null,
    enable = null,
    page,
    size,
  }: {
    name: null | string;
    enable: null | boolean;
    page: number;
    size: number;
  }) {
    const response = await api.get(`issues/alerts`, {
      params: { name, enable, page, size },
    });
    return response.data;
  }

  async getAlertById(alertId: number) {
    const response = await api.get(`issues/alerts/${alertId}`);
    return response.data;
  }
}
