import { Modal } from "antd";
import * as React from "react";

function ExpiredSessionModal({ showModal, closeModal }: any) {
  const [secondsToLoggout, setSecondsToLoggout] = React.useState<number>(10);
  const styles: {
    modal: React.CSSProperties;
    title: React.CSSProperties;
    image: React.CSSProperties;
    text: React.CSSProperties;
    button: React.CSSProperties;
  } = {
    modal: {
      padding: "20px",
      textAlign: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333333",
      marginBottom: "20px",
    },
    image: {
      width: "100px",
      height: "100px",
      marginBottom: "20px",
    },
    text: {
      fontSize: "16px",
      color: "#555555",
      marginBottom: "20px",
    },
    button: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#FFFFFF",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "8px",
      padding: "10px 20px",
      cursor: "pointer",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
  };

  React.useEffect(() => {
    if (showModal) {
      const timer = setInterval(() => {
        setSecondsToLoggout((prev) => {
          return prev - 1;
        });
      }, 1000);

      setTimeout(() => {
        clearInterval(timer);
        closeModal();
      }, 10000);
    }
  }, [showModal]);

  return (
    <Modal
      title=""
      open={showModal}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      footer=""
      zIndex={99999}
    >
      <div style={styles.modal}>
        <h2 style={styles.title}>Session Expired</h2>
        <img
          src="/img/expired.svg" // Reemplaza con la URL de tu imagen
          alt="Ícono de tiempo"
          style={styles.image}
        />
        <p style={styles.text}>
          For security reasons, your session has expired. Please log in again to
          continue.
        </p>
        <button style={styles.button} onClick={closeModal}>
          Close Session ({`${secondsToLoggout} seconds`})
        </button>
      </div>
    </Modal>
  );
}

export default ExpiredSessionModal;
