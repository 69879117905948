import { Card, Col, Row, Tooltip } from "antd";
import HighchartsReact from "highcharts-react-official";
import Icon, { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import RefreshIconSvg from "./../../assets/svg/RefreshIconSvg";
import Highcharts from "highcharts/highstock";
import { PinOffSvg } from "./../../assets/svg/PinOffSvg";
import KpiService from "./../../domain/Kpi/KpiService";
import PinSvg from "../../assets/svg/PinSvg";
import dayjs from "dayjs";

const kpiService = new KpiService();
var y = 300;
const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

export default function KpiCard({ kpi, handleGetKpis, onPinKpi }) {
  //   const userId = localStorage.getItem("userId") || "";
  //   const oui = JSON.parse(
  //     localStorage.getItem("security") || "{}"
  //   ).organizationId;
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);

  const getLastKeyValue = (data) => {
    const keys = Object.keys(data).sort();
    if(keys.length === 0) return null;
    const lastKey = keys[keys.length - 1];
    const date = new Date(parseInt(lastKey)).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const value = `${data[lastKey]}`;
    return lastKey ? { date, value } : null;
  };
  const getOptions = (kpi) => ({
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      width: 100,
      height: 70,
    },
    xAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    legend: { enabled: false },
    series: [
      {
        data: Object.values(kpi.values),
      },
    ],
    plotOptions: { line: { marker: { enabled: false } } },
    tooltip: {
      formatter: function () {
        const vm = this;
        const keys = Object.keys(kpi.values);

        let text = `<span style="font-size: 10px;">${dayjs(
          parseInt(keys[vm.points[0].x]) + localOffset
        ).format("YYYY-MM-DD hh:mm:ss A")}</span><br/>`;
        vm.points.forEach((point) => {
          text += `<b style="font-size: 8px;">${point.y} ${kpi.unit}</b>`;
        });
        return [text];
      },
      //   positioner: function (labelWidth, labelHeight, point) {
      //     const vm = this;
      //     var chart = vm.chart,
      //       position;

      //     // Mueve el tooltip a la derecha del gráfico
      //     position = {
      //       x: chart.plotLeft + chart.plotWidth + 140, // 10 píxeles a la derecha del gráfico
      //       y: y - 40, // Centra verticalmente con el punto
      //     };

      //     return position;
      //   },
      style: {
        zIndex: 1000,
      },
      outside: true,
      valueDecimals: 2,
      shared: true,
      xDateFormat: "%Y-%m-%d %H:%M:%S",
    },
  });
  const orderData = (data) => {
    const entries = Object.entries(data);
    entries.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return Object.fromEntries(entries);
  };
  const handlePinKpi = async (value) => {
    const resPinKpi = await kpiService.postPinKpiInDashboard(
      value.id,
      !value.show
    );
    if (resPinKpi?.success) {
      handleGetKpis();
      onPinKpi(value);
    }
  };

  const cardStyle = (index) => ({
    background: selectedCardIndex === index ? "#f0f0f0" : "#fff",
    // color: selectedCardIndex === index ? "#fff" : "#000",
    // borderColor: selectedCardIndex === index ? "fff" : "00203C",
  });

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
  };

  const pinStyle = (value) => {
    if (value) {
      return PinOffSvg;
    } else {
      return PinSvg;
    }
  };

  return (
    <Card
      className="mb-1"
      key={kpi.id}
      style={cardStyle(kpi.id)}
      onClick={() => handleCardClick(kpi.id)}
    >
      <Row>
        <Col span={12}>
          <div className="title-kpi">{kpi.name}</div>
          <div className="body-kpi">
            <Row>
              <span className="body-number-kpi">
                {getLastKeyValue(kpi.values)?.value || "No Data"}
              </span>
              {getLastKeyValue(kpi.values) && (
                <span className="body-unit-kpi ml-1">
                  {kpi.unit !== null && kpi.unit ? kpi.unit : "N/A"}
                </span>
              )}
            </Row>
          </div>
          {getLastKeyValue(kpi.values) && (
            <div className="footer-kpi mt-1">
              <Icon component={RefreshIconSvg} rev={undefined} />{" "}
              {getLastKeyValue(kpi.values)?.date}
            </div>
          )}
        </Col>
        <Col span={12} style={{ background: "#F7F9FC" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions(orderData(kpi))}
          />
        </Col>
        <Tooltip title="Pin KPI">
          <Icon
            onClick={() => {
              handlePinKpi(kpi);
            }}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            component={pinStyle(kpi.show)}
            rev={undefined}
          />
        </Tooltip>
      </Row>
    </Card>
  );
}
