import { useEffect, useState } from "react";
import UserService from "../domain/Users/UserService";

const userService = new UserService();

export default function useUser({
  organizationId,
  userName = null,
  roleId = null,
  page = 1,
  size = 40,
}: {
  organizationId: string;
  page?: number;
  size?: number;
  userName?: string | null;
  roleId?: number | null;
}) {
  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState({ page, size, totalRows: 0 });
  const [params, setParams] = useState<any>({
    userName,
    roleId,
    organizationId,
  });

  const handleGetUsers = async () => {
    const response = await userService.getAllUsers(
      params.organizationId,
      page,
      size,
      params.userName,
      params.roleId
    );
    if (response) {
      setUsers(response.responseContentDTO);
      setPagination({
        ...pagination,
        totalRows: response.totalRecords,
      });
    }
  };

  useEffect(() => {
    handleGetUsers();
  }, [pagination.page, pagination.size, , params]);

  return { users, params, setParams };
}
