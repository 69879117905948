import React from "react";
import { Routes, Route } from "react-router-dom";
import ConfigurationLayout from "../layout/ConfigurationLayout";
import DashboardLayout from "../layout/DashboardLayout";
import Simulations from "../views/Simulations/SimulationsView";
import LocationsView from "../views/Locations";
import UploadsView from "../views/Uploads";
import DashboardV2 from "../views/Dashboard";
import GisFiles from "../views/Uploads/GisFiles";
import LocationsDetailView from "../views/Locations/locationDetail";
import InfrastructuresView from "../views/Infrastructures/index";
import ParametersView from "../views/Infrastructures/Parameters";
import UploadModelFiles from "../views/Uploads/ModelFiles/UploadModelFiles";
import BulkView from "../views/Bulk";
import BlockDiagramView from "../views/BlockDiagram";
import DiagramBlockView from "../views/DiagramBlock";
import KpiView from "../views/Kpi";
import UserView from "../views/Users";
import BulkDevicesView from "../views/BulkDevices";
import UploadBulk from "../views/BulkDevices/uploadBulk";
import InteractiveSimulationView from "../views/Simulations/InteractiveSimulation.view";
import InteractiveSimulationReplay from "../views/Simulations/InteractiveSimulationReplay.view";
import DevicesView from "../views/Devices/DevicesView";
import ParamSelfCalibrationView from "../views/Infrastructures/SelfCalibration/index";
import OrganizationView from "../views/Organizations";
import ReportsMroView from "../views/ReportsMro";
import DashobardNrwView from "../views/DashboardNrw";
import AlertView from "../views/Alerts";
import AlertForm from "../views/Alerts/AlertForm";
import EditModelFile from "../views/Uploads/ModelFiles/EditModelFile";
import StateMachineView from "../views/StateMachine";
import NonRevenueWaterView from "../views/NonRevenueWater";
import EditStateMachineView from "../views/StateMachine/EditStateMachine";
import HistoricalFilesView from "../views/Uploads/Historical";
import UploadParametersFilesView from "../views/Uploads/Parameters/UploadParameters/";
import MMStest from "../views/MMStest";
import MmsView from "../views/Mms";
import ErrorView from "../views/Error";

function Router() {
  return (
    <Routes>
      <Route path="/error" element={<ErrorView />}></Route>
      <Route path="/" element={<DashboardLayout />}>
        <Route path="" element={<DashboardV2 />} />
        <Route path="/nrw" element={<DashobardNrwView />}></Route>
        <Route path="/mmstest" element={<MMStest/>}/>
        <Route
          path="/diagram-block/:locationId/:blockId"
          element={<DiagramBlockView />}
        />
        <Route
          path="/interactive-simulation-edit/:locationId/:simulationId"
          element={<InteractiveSimulationView />}
        />
        <Route
          path="/interactive-simulation-view/:locationId/:simulationId"
          element={<InteractiveSimulationReplay />}
        />
        <Route path="/mro-reports" element={<ReportsMroView />} />
        <Route path="stateMachines/edit/:stateMachineId" element={<EditStateMachineView />} />
        <Route path="/mms" element={<MmsView/>}/>
        
      </Route>
      {/* <Route path="/manage/simulations" element={<Simulation />} /> */}
      <Route path="/" element={<ConfigurationLayout />}>
        <Route path="simulations" element={<Simulations />} />
        <Route path="locations" element={<LocationsView />} />
        <Route path="locations/:locationId" element={<LocationsDetailView />} />
        <Route path="upload" element={<UploadsView />} />
        <Route path="upload/gisfiles" element={<GisFiles />} />
        <Route path="infrastructures" element={<InfrastructuresView />} />
        <Route path="upload/historicalfiles" element={<HistoricalFilesView />} />
        <Route path="upload/parameters" element={<UploadParametersFilesView />} />
        {/* <Route path="infrastructure" element={<InfrastructureView />} /> */}
        <Route
          path="infrastructures/parameter/:infraId"
          element={<ParametersView />}
        />
        <Route
          path="infrastructures/parameter/hydroTwin"
          element={<ParamSelfCalibrationView />}
        />
        <Route path="kpi" element={<KpiView />} />
        <Route path="upload/modelfiles" element={<UploadModelFiles />} />
        <Route path="upload/modelfiles/editFile" element={<EditModelFile />} />
        <Route path="bulk" element={<BulkDevicesView />} />
        <Route path="devices" element={<DevicesView />} />
        <Route path="bulk/upload" element={<UploadBulk />} />
        <Route path="block/diagram" element={<BlockDiagramView />} />
        <Route path="users" element={<UserView />} />
        <Route path="organizations" element={<OrganizationView />} />
        <Route path="alerts" element={<AlertView />} />
        <Route path="alerts/form" element={<AlertForm />} />
        <Route path="alerts/form/:alertId" element={<AlertForm />} />
        <Route path="stateMachines" element={<StateMachineView />} />
        <Route path="nonRevenueWater" element={<NonRevenueWaterView />} />
      </Route>
    </Routes>
  );
}

export default Router;
