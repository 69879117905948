import React from "react";
import { useState, useEffect, useContext } from "react";
import { apiController } from "../../services/api";
import { Table } from "antd";
import Icon from "@ant-design/icons";
import SelectSearch from "../../components/Library/SelectSearch";
import GridDisplaySvg from "../../assets/svg/GridDisplaySvg";
import ListDisplaySvg from "../../assets/svg/ListDisplaySvg";
import CountriesByOrganization from "../../components/Locations/CountriesByOrganization";
import "./index.scss";
import CountryService from "../../domain/Country/CountryService";
import LocationsContext from "./context";

const countryService = new CountryService();

const LocationsView: React.FC = () => {
  // const locationsCtx = useContext(LocationsContext)
  const [displayMode, setDisplayMode] = useState("table");
  const [fetching, setFetching] = useState(false);
  const [countriesSearchList, setCountriesSearchList] = useState([]);
  const [countriesToList, setCountriesToList] = useState<CountryOrganization[]>(
    []
  );

  function searchCountries(search: string) {}
  async function fetchCountriesToList() {
    const security = localStorage.getItem("security");
    if (!security) return;
    const organizationId = JSON.parse(security).organizationId;
    const res = await countryService.getCountriesByOrganization(organizationId);
    setCountriesToList(res);
  }
  useEffect(() => {
    fetchCountriesToList();
    return () => {};
  }, []);

  return (
    <LocationsContext.Provider
      value={{ countriesByOrganization: countriesToList }}
    >
      <div className="flex column">
        <span className="text-title-page mb-4">Regions</span>
        <div className="flex justify-between">
          <span>
            Locations are geographical areas within a Region that can be
            individually selected in the dashboard. You have locations in the
            following Regions:
          </span>
          {countriesToList.length > 0 && (
            <div>
              <Icon
                onClick={() => {
                  setDisplayMode("table");
                } }
                component={ListDisplaySvg}
                className={`cursor-pointer ${displayMode === "table" ? "filter-to-blue" : ""}`}
                style={{ marginRight: "20px" }} rev={undefined}              />
              <Icon
                onClick={() => {
                  setDisplayMode("grid");
                } }
                component={GridDisplaySvg}
                className={`cursor-pointer ${displayMode === "grid" && "filter-to-blue"}`} rev={undefined}              />
            </div>
          )}
        </div>
        <div className="container-content flex w-full justify-center">
          <CountriesByOrganization displayMode={displayMode} />
        </div>
      </div>
    </LocationsContext.Provider>
  );
};

export default LocationsView;
