import React, { useState, useEffect, useRef, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { renderToString } from "react-dom/server";
import DeckGL, { IconLayer, FlyToInterpolator } from "deck.gl";
import { TripsLayer, TileLayer } from "@deck.gl/geo-layers";
import { PathLayer, BitmapLayer } from "@deck.gl/layers";
import { _MapContext as MapContext, StaticMap, Popup } from "react-map-gl";
import PopupComponent from "./Popup";
import {
  getInfraestructuresByLocation,
  getInfreaestructureById,
} from "../../services/measurement";
import { getLinksByLocation, getLinksMinify } from "../../services/links";
import { getMapsAction } from "../../redux/mapDuck";
import ValveSvg from "../../assets/svg/ValveSvg";
import Icon from "@ant-design/icons";
import "../../css/content.css";
import DashboardContext from "../../layout/DashboardLayout/context";
import { dashboardMapEventsService } from "../../events/dashboardMap.subject";
import dayjs from "dayjs";
import { infraTypesByIdForIcons } from "../../statics/constants/infraTypes";

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";

const initialStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

// Viewport settings
const initialViewState = {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 11.5,
  pitch: 0,
  bearing: 0,
};

const BLUE = [30, 150, 190];
const FLOW_COLOR = [72, 43, 189];

const multiply = 100;
const maxTime = 10;
const step = 2;
// variables

const Content = ({ selectedMap }) => {
  const subscriptionDM$ = dashboardMapEventsService.getSubject();
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const [viewState, setViewState] = useState(initialViewState);
  const [time, setTime] = useState(1);
  const [time2, setTime2] = useState(1);
  const [time3, setTime3] = useState(1);
  const [linksLayer, setLinkLayer] = useState([]);
  const [infrasLayer, setInfrasLayer] = useState([]);
  const [rainfallLayer, setRainfallLayer] = useState([]);
  const [animateLayer, setAnimateLayer] = useState([]);
  const [infras, setInfras] = useState(null);
  const [links, setLinks] = useState(null);
  const [linksMinify, setLinksMinify] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [infraInfo, setInfraInfo] = useState(null);
  const [popupCoordinates, setPopupCoordinates] = useState([]);
  const [animation] = useState({});
  const [animation2] = useState({});
  const [animation3] = useState({});
  const [animationRainfall, setAnimationRainfall] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [futureRainfall, setFutureRainfall] = useState(false);
  const layerInterval = useRef(null);
  const [rainfallCurrentTime, setRainfallCurrentTime] = useState(null);
  const [rainfallTimeList, setRainfallTimeList] = useState(null);
  const dispatch = useDispatch();

  let dataToGraph = null;
  const maxTimestamp = useRef(null);
  const deckRef = useRef(null);

  const loadMapInfo = async () => {
    await dispatch(getMapsAction(context.selectedLocation));
    if (selectedMap.latitude && selectedMap.longitude) {
      setViewState({
        longitude: selectedMap.longitude,
        latitude: selectedMap.latitude,
        zoom: selectedMap.zoomLevel,
        pitch: 0,
        bearing: 0,
        // transitionDuration: 2000,
        // transitionInterpolator: new FlyToInterpolator(),
      });
    }
  };

  const animate = async () => {
    setTime((t) => (t + step) % (maxTime * multiply));
    animation.id = window.requestAnimationFrame(animate); // draw next frame
  };
  const animate2 = async () => {
    setTime2((t) => (t + step) % (maxTime * multiply));
    animation2.id = window.requestAnimationFrame(animate2); // draw next frame
  };
  const animate3 = async () => {
    setTime3((t) => (t + step) % (maxTime * multiply));
    animation3.id = window.requestAnimationFrame(animate3); // draw next frame
  };

  const fetchData = async () => {
    const infrasResult = await getInfraestructuresByLocation(
      context.selectedLocation
    );
    if (infrasResult) setInfras(infrasResult);
    const linksResultMinify = await getLinksMinify(context.selectedLocation);

    if (linksResultMinify) {
      setLinks(linksResultMinify);
    }
    if (linksResultMinify) {
      dataToGraph = linksResultMinify.map((line) => {
        const numberOfSegments = line.coordinates.length;
        const timeOfSegment = Math.floor(
          (maxTime * multiply) / numberOfSegments
        );
        let timestamps = [];

        for (let index = 1; index <= numberOfSegments; index++) {
          timestamps.push(index * timeOfSegment);
          // timestamps.push(index * 100);
        }
        return {
          ...line,
          timestamps,
        };
      });
      const timestamps = dataToGraph.reduce(
        (ts, item) => ts.concat(item.timestamps),
        []
      );
      maxTimestamp.current = Math.max(...timestamps);
      setLinksMinify(dataToGraph);
    }
  };
  const drawInfras = (size = null) => {
    if (!infras) return;
    const data = infras.map((infra) => {
      return {
        ...infra,
        position: [parseFloat(infra.longitude), parseFloat(infra.latitude)],
        icon: getImageUsageValue(
          infra.mainMeasurementTarget.usage,
          infra.mainMeasurementTarget.overflowUmbral,
          infra.measurementeInfraestructureId,
          infra
        ),
      };
    });
    const newSize = Math.max(10, 25 * (viewState.zoom / 10));
    setInfrasLayer([
      new IconLayer({
        id: "icon-layer",
        data,
        pickable: true,
        getPosition: (d) => d.position,
        getIcon: (d) => ({
          url: d.icon,
          width: 200,
          height: 200,
        }),
        // getIcon: (d) => d.icon,
        sizeScale: 1,
        getSize: size || newSize,
        onHover: ({ object }) => {},
      }),
    ]);
  };
  const drawLinks = () => {
    if (!links) return;
    const ConduitsS = links.filter((item) => item.size === "S");
    const ConduitsM = links.filter((item) => item.size === "M");
    const ConduitsB = links.filter((item) => {
      return item.size === "B";
    });
    setLinkLayer([
      new PathLayer({
        id: "conduitsS",
        data: ConduitsS,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 1,
        rounded: true,
        currentTime: time,
      }),
      new PathLayer({
        id: "conduitsM",
        data: ConduitsM,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 3,
        rounded: true,
        currentTime: time,
      }),
      new PathLayer({
        id: "conduitsB",
        data: ConduitsB,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 5,
        rounded: true,
        currentTime: time,
      }),
    ]);
  };
  const drawTripsLinks = () => {
    if (!linksMinify) return;
    const sm = linksMinify.filter((l) => l.size == "S");
    const md = linksMinify.filter((l) => l.size == "M");
    const bg = linksMinify.filter((l) => l.size == "B");
    setAnimateLayer([
      new TripsLayer({
        id: "tripsS",
        data: sm,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getTimestamps: (d) => d.timestamps,
        getColor: (d) => FLOW_COLOR,
        fadeTrail: true,
        opacity: 1,
        widthMinPixels: 1,
        widthMaxPixels: 1,
        widthScale: 1,
        rounded: true,
        trailLength: maxTime * multiply,
        currentTime: time,
      }),
      new TripsLayer({
        id: "tripsM",
        data: md,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getTimestamps: (d) => d.timestamps,
        getColor: (d) => FLOW_COLOR,
        fadeTrail: true,
        opacity: 1,
        widthMinPixels: 3,
        widthMaxPixels: 3,
        widthScale: 1,
        rounded: true,
        trailLength: maxTime * multiply,
        currentTime: time2,
      }),
      new TripsLayer({
        id: "tripsB",
        data: bg,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getTimestamps: (d) => d.timestamps,
        getColor: (d) => FLOW_COLOR,
        fadeTrail: true,
        opacity: 1,
        widthMinPixels: 5,
        widthMaxPixels: 5,
        widthScale: 1,
        rounded: true,
        trailLength: maxTime * multiply,
        currentTime: time3,
      }),
    ]);
  };

  const drawRainfallLayer = async (future) => {
    fetch("https://api.rainviewer.com/public/weather-maps.json")
      .then((res) => res.json())
      .then(async (apiData) => {
        let arrayData = [];
        let timeList = [];
        const data = future ? apiData.radar.nowcast : apiData.radar.past;
        data.forEach((frame, index) => {
          arrayData = [
            ...arrayData,
            new TileLayer({
              id: `tile_rainfall_layer_${index}`,
              data: `${apiData.host}${frame.path}/256/{z}/{x}/{y}/2/1_1.png`,
              minZoom: 0,
              maxZoom: 20,
              tileSize: 256,
              opacity: 0,
              time: frame.time,
              renderSubLayers: (props) => {
                const {
                  bbox: { west, south, east, north },
                } = props.tile;

                return new BitmapLayer(props, {
                  data: null,
                  image: props.data,
                  bounds: [west, south, east, north],
                });
              },
            }),
          ];
          timeList = [
            ...timeList,
            { id: `tile_rainfall_layer_${index}`, time: frame.time },
          ];
        });
        setRainfallLayer(arrayData);
        setRainfallTimeList(timeList);
      })
      .catch(console.error);
  };

  function handleClickMap(event) {
    console.log(event);

    if (event.picked) {
      if (event.object?.measurementeInfraestructureId) {
        showPopUpDevice(event.object);
      } else {
        setShowPopup(false);
      }
    } else {
      if (event.index === -1) {
        console.log("Index is -1, no object picked.");
      } else {
        if (setShowPopup && event.index === -1) {
        } else {
          setShowPopup(false); // Close modal for other cases
        }
      }
    }
  }

  function tooltipToshow(infra) {
    const element = (
      <>
        <div style={{ borderBottom: "solid 4px rgb(7,140,179)" }}>
          <span style={{ textAlign: "center" }}>
            {infra.measurementInfraestructureName} -
          </span>
          <span
            style={{ color: "#74788D", fontSize: "10px", paddingLeft: "5px" }}
          >
            {infra.infrastructureTypeName}
          </span>
        </div>
        <div>
          {infra.mainMeasurementTarget.usage >= 0
            ? `(${infra.mainMeasurementTarget?.usage} % Usage)`
            : "No data Available"}
        </div>
      </>
    );
    return renderToString(element);
  }

  function showPopUpDevice(infra) {
    const coordinates = infra.position.slice();
    console.log("🚀 ~ showPopUpDevice ~ coordinates:", coordinates);
    setPopupCoordinates(coordinates);

    getInfreaestructureById(infra.measurementeInfraestructureId)
      .then((response) => {
        if (!response) return;
        setInfraInfo(response);
        setShowPopup(true);
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  function getImageUsageValue(
    usage,
    overflowUmbral,
    measurementeInfraestructureId,
    infra
  ) {
    // Logica para saber si el sensor estaba seleccionado
    if (
      context.selectedSensors.find(
        (sensor) => sensor.infrastructureId == measurementeInfraestructureId
      )
    ) {
      const url = `/img/InfraIcons/png/${
        infraTypesByIdForIcons[infra.infrastructureTypeId]
      }2.png`;
      return url;
    } else {
      let usageText = "";
      if (usage <= 0) {
        usageText = "";
      } else if (usage > 0 && usage <= 10) {
        usageText = `_0-10`;
      } else if (usage > 10 && usage <= 20) {
        usageText = `_10-20`;
      } else if (usage > 20 && usage <= 30) {
        usageText = `_20-30`;
      } else if (usage > 30 && usage <= 40) {
        usageText = `_30-40`;
      } else if (usage > 40 && usage <= 50) {
        usageText = `_40-50`;
      } else if (usage > 50 && usage <= 60) {
        usageText = `_50-60`;
      } else if (usage > 60 && usage <= 70) {
        usageText = `_60-70`;
      } else if (usage > 70 && usage <= 80) {
        usageText = `_70-80`;
      } else if (usage > 80 && usage <= 90) {
        usageText = `_80-90`;
      } else if (usage > 90 && usage <= 100) {
        usageText = `_90-100`;
      } else if (usage > 100) {
        usageText = `_100`;
      } else {
        usageText = "";
      }

      const url = `/img/InfraIcons/png/${
        infraTypesByIdForIcons[infra.infrastructureTypeId]
      }${usageText}.png`;

      return url;
    }
  }

  function svgToDataURL(svg) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
  }

  function changeAnimate(val) {
    if (val.target.checked) {
      drawTripsLinks();
      animation.id = window.requestAnimationFrame(animate);
      setTimeout(() => {
        animation2.id = window.requestAnimationFrame(animate2);
      }, 1000);
      setTimeout(() => {
        animation3.id = window.requestAnimationFrame(animate3);
      }, 2000);
    } else {
      setAnimateLayer([]);
      window.cancelAnimationFrame(animation.id);
      animation.id = 0;
      window.cancelAnimationFrame(animation2.id);
      animation2.id = 0;
      window.cancelAnimationFrame(animation3.id);
      animation3.id = 0;
    }
  }

  useEffect(() => {
    subscriptionDM$.subscribe((event) => {
      if (event.type == "changeAnimation") {
        changeAnimate(event.data);
      }
      if (event.type == "cleanRainfallLayer") {
        setRainfallLayer([]);
        setRainfallTimeList(null);
        setRainfallCurrentTime(null);
      }
      if (event.type == "drawRainfallLayer") {
        drawRainfallLayer(event.data.futureRainfall);
      }
      if (event.type == "futureRainfallToggleChanged") {
        clearInterval(animationRainfall);
        clearInterval(layerInterval.current);
        setRainfallLayer((rfl) => {
          if (rfl.length > 0) {
            drawRainfallLayer(event.data.futureRainfall);
          }
          return [];
        });
      }
    });
    return () => {
      window.cancelAnimationFrame(animation.id);
      clearInterval(animationRainfall);
    };
  }, []);

  useEffect(() => {
    if (rainfallLayer.length > 0) {
      let i = 0;
      layerInterval.current = setInterval(() => {
        setRainfallLayer((current) => {
          const newMap = current.map((layer) => {
            if (layer.id === `tile_rainfall_layer_${i}`) {
              setRainfallTimeList((currentValue) => {
                console.log(
                  "🚀 ~ setRainfallTimeList ~ currentValue:",
                  currentValue
                );
                if (currentValue) {
                  const finded = currentValue.find(
                    (time) => time.id == layer.id
                  );
                  console.log("🚀 ~ setRainfallTimeList ~ finded:", finded);
                  setRainfallCurrentTime(finded.time);
                }
                return currentValue;
              });
              return layer.clone({ opacity: 0.1 });
            } else {
              return layer.clone({ opacity: 0 });
            }
          });
          return newMap;
        });
        i = i + 1;
        if (i >= rainfallLayer.length) {
          i = 0;
        }
      }, 700);
      // updateLayerOpacity(`tile_rainfall_layer_${1}`);
      // animateRainfall2();
    } else {
      clearInterval(animationRainfall);
      clearInterval(layerInterval.current);
    }
  }, [rainfallLayer.length]);

  useEffect(() => {
    if (context.selectedLocation) {
      fetchData();
      loadMapInfo();
    }
  }, [context.selectedLocation]);

  useEffect(() => {
    if (selectedMap.latitude && selectedMap.longitude) {
      setViewState({
        longitude: selectedMap.longitude,
        latitude: selectedMap.latitude,
        zoom: selectedMap.zoomLevel,
        pitch: 0,
        bearing: 0,
      });
    }
  }, [selectedMap]);

  useEffect(() => {
    drawInfras();
  }, [infras, context.selectedSensors]);

  useEffect(() => {
    drawLinks();
  }, [links]);

  useEffect(() => {
    drawTripsLinks();
  }, [time]);

  useEffect(() => {
    if (viewState.zoom < 8) {
    }
  }, [viewState.zoom]);

  return (
    <div
      className="MapContainer"
      style={{ height: "100%", width: "100%", position: "absolute" }}
    >
      <DeckGL
        ref={deckRef}
        controller
        ContextProvider={MapContext.Provider}
        viewState={viewState}
        layers={[
          ...linksLayer,
          ...animateLayer,
          ...infrasLayer,
          ...rainfallLayer,
        ]}
        style={initialStyle}
        onViewStateChange={(nextViewState) => {
          const zoomFactor = nextViewState.viewState.zoom;
          const newSize = Math.max(10, 25 * (zoomFactor / 10));
          drawInfras(newSize);
          setViewState(nextViewState.viewState);
        }}
        onClick={handleClickMap}
        getTooltip={({ object }) =>
          object && {
            html: tooltipToshow(object),
            style: {
              backgroundColor: "#fff",
              opacity: 1,
            },
          }
        }
      >
        <>
          <StaticMap
            mapboxApiAccessToken={mapboxApiAccessToken}
            mapStyle={mapStyle}
          ></StaticMap>
          {showPopup && (
            <Popup
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              anchor="bottom"
              onClose={() => setShowPopup(false)}
              closeButton={true}
              closeOnClick={false}
            >
              <PopupComponent
                key={infraInfo.measurementeInfraestructureId}
                data={infraInfo}
                id={infraInfo.measurementeInfraestructureId}
                onClose={() => {
                  setShowPopup(false);
                }}
              />
            </Popup>
          )}
          {rainfallCurrentTime && (
            <div
              style={{
                position: "absolute",
                right: "23px",
                bottom: "25px",
              }}
            >
              <span>{`${dayjs
                .unix(rainfallCurrentTime)
                .format("YYYY/MM/DD - hh:mm a")}`}</span>
            </div>
          )}
          {/* <div
            style={{
              position: "absolute",
              right: "20px",
              bottom: "27px",
              backgroundColor: "white",
            }}
          >
            <Toggle
              onClick={changeAnimate}
              style={{
                position: "absolute",
                right: "20px",
                bottom: "27px",
              }}
              checkedChildren="Hide animation"
              unCheckedChildren="Add animation"
            />
            <Toggle
              onChange={(checked) => {
                if (!checked) {
                  setRainfallLayer([]);
                } else {
                  drawRainfallLayer(futureRainfall);
                }
              }}
              style={{
                position: "absolute",
                right: "150px",
                bottom: "27px",
                width: "104px",
              }}
              checkedChildren="Hide Rainfall"
              unCheckedChildren="Add Rainfall"
            />
            <Toggle
              checked={futureRainfall}
              onChange={(checked) => {
                setFutureRainfall(checked);
                if (rainfallLayer.length > 0) {
                  setRainfallLayer([]);
                  drawRainfallLayer(checked);
                }
              }}
              style={{
                position: "absolute",
                right: "150px",
                bottom: "57px",
                width: "104px",
              }}
              checkedChildren="Future Values"
              unCheckedChildren="Past Values"
            />
          </div> */}
        </>
      </DeckGL>
    </div>
  );
};

function mapState(state) {
  return {
    selectedMap: state.maps.array,
  };
}

export default connect(mapState)(Content);
